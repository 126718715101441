import { Autocomplete, Avatar, CircularProgress, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { IGetUsersListRequest, IUser } from '../modules/rest';
import { API } from '../modules/api';
import cache from '../modules/cache';
import { UserView } from './user';
import { thumb } from '../modules/utils';

type Props = {
  value: IUser | number | null | undefined;
  onChange(value: IUser | null): void;
  filter?: IGetUsersListRequest;
};

const InputUser = ({ value, onChange, filter }: Props) => {
  const [val, setVal] = useState<IUser | null>();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(
    (query: string) => {
      setLoading(true);
      API.Users.getUsersList({ ...filter, query })
        .then((res) => setOptions(res.data))
        .finally(() => setLoading(false));
    },
    [filter, setLoading, setOptions]
  );

  useEffect(() => {
    if (Number.isInteger(value)) {
      const user = cache.get<IUser>(`user:${value}`);
      if (user) {
        setVal(user);
      } else {
        API.Users.getUserById(value as number).then((user) => {
          cache.set(`user:${value}`, user);
          setVal(user);
        });
      }
    } else {
      setVal((value as IUser) || null);
      value && setOptions([value as IUser]);
    }
  }, [value]);

  if (val === undefined) return null;

  return (
    <Autocomplete
      sx={{ minWidth: 300 }}
      open={open}
      onOpen={() => {
        if (!options.length) fetch('');
        setOpen(true);
      }}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option: IUser) => option.name || option.email}
      filterOptions={(f) => f}
      renderOption={(props, user) => (
        <li {...props}>
          <UserView key={user.id} user={user} />
        </li>
      )}
      options={options}
      loading={loading}
      value={val}
      onChange={(_, value) => {
        if (value) cache.set(`user:${value.id}`, value);
        onChange(value as IUser);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Manager"
          size="small"
          onChange={(e) => fetch(e.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: val ? (
              <Avatar
                src={thumb(val.avatar?.id ?? '', 28 * 2)}
                sx={{ width: 28, height: 28, fontSize: 9 }}
                className={`user__avatar-${val?.role}`}
              >
                {val.id}
              </Avatar>
            ) : null,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default InputUser;
