import { useCallback, useEffect, useState } from 'react';
import { EFieldGroup, ETrackReviewStatus, ITrack } from '../../../modules/rest';
import { API } from '../../../modules/api';
import { toast } from 'react-toastify';
import { Box, Button, CircularProgress, colors, Stack, TextField } from '@mui/material';
import Track from '../../../components/track';
import Loadable from '../../../components/loadable';
import Empty from '../../../components/empty';
import { useNavigate } from 'react-router-dom';
import session from '../../../modules/session';

const scope = [EFieldGroup.TrackAlbum, EFieldGroup.AlbumPerformer];
type TComments = Record<number, string | null>;

const ContentIdResult = () => {
  const [tracks, setTracks] = useState<ITrack[]>();
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState<TComments>({});
  const navigate = useNavigate();

  const fetch = useCallback(() => {
    setLoading(true);
    API.Tracks.getTracksList({ reviewStatus: ETrackReviewStatus.ContentID, limit: 1000 }, scope)
      .then((res) => {
        const comments: TComments = {};
        res.data.forEach((t) => (comments[t.id] = null));
        setTracks(res.data);
        setComments(comments);
      })
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setTracks, setLoading]);

  const submit = useCallback(() => {
    setLoading(true);
    API.ReviewTracks.finishContentId({ comments })
      .then(() => navigate('../spotify', { replace: true }))
      .catch(toast.error)
      .finally(() => {
        setLoading(false);
        return session.fetch();
      });
  }, [setLoading, navigate, comments]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Loadable loading={loading} minHeight={0}>
      {tracks ? (
        <Stack spacing={2}>
          {tracks.map((track) => (
            <Stack
              key={track.id}
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ backgroundColor: comments[track.id] ? colors.red[100] : colors.green[100], p: 1, borderRadius: 1 }}
            >
              <small className="text-muted">#{track.id}</small>

              <Track track={track} />
              <Box flexGrow={1} />
              <TextField
                name="reason"
                value={comments[track.id] ?? ''}
                onChange={(e) => setComments({ ...comments, [track.id]: e.target.value || null })}
                size="small"
                sx={{ width: '50%' }}
              />
            </Stack>
          ))}
          <Empty show={!tracks.length} />
          <Button disabled={!tracks.length} variant="contained" sx={{ alignSelf: 'start' }} onClick={submit}>
            Submit result
          </Button>
        </Stack>
      ) : (
        <CircularProgress />
      )}
    </Loadable>
  );
};

export default ContentIdResult;
