import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { EFieldGroup, EReviewStatus, IGetPerformersListRequest, IPerformer } from '../../modules/rest';
import { observer, useLocalObservable } from 'mobx-react';
import cache from '../../modules/cache';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { runInAction } from 'mobx';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Check, Close, Download, Edit, FilterAlt } from '@mui/icons-material';
import { download, PagerState } from '../../modules/utils';
import Loadable from '../../components/loadable';
import { confirmDialog, openDialog } from '../../components/dialogs';
import session from '../../modules/session';
import Pager from '../../components/pager';
import Empty from '../../components/empty';
import Layout from '../../components/layout';
import ReviewNavigation from './nav';
import { openEditPerformerProfileDialog } from '../accounts/performers/edit-profile';
import User from '../../components/user';

const ReviewPerformersProfiles = observer(() => {
  const state = useLocalObservable<PagerState<IPerformer, IGetPerformersListRequest>>(() => ({
    loading: true,
    pager: cache.get('review.profiles.pager'),
    request: cache.get('review.profiles.request') ?? { page: 1, limit: 10, status: EReviewStatus.Review },
  }));

  const fetchList = useCallback(() => {
    runInAction(() => (state.loading = true));
    API.Performers.getList(state.request, [EFieldGroup.PerformerEdit, EFieldGroup.PerformerUser])
      .then((pager) =>
        runInAction(() => {
          state.pager = pager;
          state.request.page = pager.page;
          state.request.limit = pager.limit;
          cache.set('review.profiles.pager', state.pager);
          cache.set('review.profiles.request', state.request);
        })
      )
      .catch(toast.error)
      .finally(() => runInAction(() => (state.loading = false)));
  }, [state]);

  const draft = useCallback(
    (p: IPerformer) => {
      confirmDialog('Are you sure to draft profile?').then((agree) => {
        if (!agree) return;
        runInAction(() => (state.loading = true));
        API.Performers.draftPerformer(p.id)
          .then(() => {
            toast.success('Performed moved to drafts');
            fetchList();
          })
          .catch(toast.error)
          .finally(() => runInAction(() => (state.loading = false)));
      });
    },
    [fetchList, state]
  );

  const review = useCallback(
    (p: IPerformer) => {
      confirmDialog('Are you sure to send to review profile?').then((agree) => {
        if (!agree) return;
        runInAction(() => (state.loading = true));
        API.Performers.reviewPerformer(p.id)
          .then(() => {
            toast.success('Performed sent to review');
            fetchList();
          })
          .catch(toast.error)
          .finally(() => runInAction(() => (state.loading = false)));
      });
    },
    [fetchList, state]
  );

  const approve = useCallback(
    (p: IPerformer) => {
      openDialog((resolve) => <ApproveDialog performer={p} resolve={resolve} />).then(() => {
        return Promise.all([session.fetch(), fetchList()]);
      });
    },
    [fetchList]
  );

  const reject = useCallback(
    (p: IPerformer) => {
      openDialog((resolve) => <RejectDialog performer={p} resolve={resolve} />).then(() => {
        return Promise.all([session.fetch(), fetchList()]);
      });
    },
    [fetchList]
  );

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <Layout
      title="Review profiles"
      loading={state.loading}
      footer={<Pager pager={state.pager} request={state.request} onChange={fetchList} />}
      actions={
        <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
          <TextField
            placeholder="Search"
            size="small"
            value={state.request.query || ''}
            sx={{ width: 300 }}
            onChange={(e) => runInAction(() => (state.request.query = e.target.value || undefined))}
          />

          <TextField
            select
            size="small"
            value={state.request.status}
            sx={{ width: 120 }}
            label="Status"
            onChange={(e) => runInAction(() => (state.request.status = e.target.value as EReviewStatus))}
          >
            {Object.entries(EReviewStatus).map(([name, value]) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </TextField>

          <Button
            size="small"
            variant="contained"
            onClick={() =>
              runInAction(() => {
                state.request.page = 1;
                fetchList();
              })
            }
            startIcon={<FilterAlt />}
          >
            Apply
          </Button>
          <Button
            size="small"
            onClick={() =>
              runInAction(() => {
                state.request = { status: EReviewStatus.Review };
                fetchList();
              })
            }
            startIcon={<Close />}
          >
            Reset
          </Button>
        </Stack>
      }
      breadcrumbs={<ReviewNavigation />}
    >
      {state.pager ? (
        <>
          {state.pager.data.map((p) => (
            <Box key={p.id} mb={1}>
              <Card>
                <CardHeader
                  title={p.stageName}
                  action={
                    <Stack direction="row" spacing={1}>
                      <Button
                        startIcon={<Edit />}
                        onClick={() => openEditPerformerProfileDialog(p).then((res) => res && fetchList())}
                      >
                        Edit
                      </Button>
                    </Stack>
                  }
                />
                <CardContent>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography variant="caption">Name</Typography>
                      <div>{p.lastName}</div>
                      <div>{p.firstName}</div>
                      <div>{p.middleName}</div>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="caption">Contacts</Typography>
                      <div>Phone: {p.phoneNumber || 'N/A'}</div>
                      <div>Address: {p.address || 'N/A'}</div>
                      <div>Bio: {p.bio || 'N/A'}</div>
                      <div>
                        <Button
                          disabled={!p.addressScan}
                          size="small"
                          startIcon={<Download />}
                          onClick={() => download(p.addressScan!)}
                        >
                          Proof of address
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="caption">Passport</Typography>
                      <div>Passport: {p.nationality?.toUpperCase() || 'N/A'}</div>
                      <div>
                        Series: {p.passport?.series || 'N/A'}, Number: {p.passport?.number || 'N/A'}
                      </div>
                      <div>
                        Issued: {moment(p.passport?.issuedAt).format('DD.MM.YYYY')} by {p.passport?.issuedBy || 'N/A'}
                      </div>
                      <div>
                        <Button
                          disabled={!p.passportScan}
                          size="small"
                          startIcon={<Download />}
                          onClick={() => download(p.passportScan!)}
                        >
                          Download
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="caption">Bank details</Typography>
                      <div>Account holder: {p.bankDetails?.accountHolder || 'N/A'}</div>
                      <div>BIC: {p.bankDetails?.bic || 'N/A'}</div>
                      <div>
                        IBAN ({p.bankDetails?.currency}): {p.bankDetails?.iban || 'N/A'}
                      </div>
                      <div>Bank: {p.bankDetails?.bankName || 'N/A'}</div>
                      <div>Address: {p.bankDetails?.bankAddress || 'N/A'}</div>
                      <div>Payoneer: {p.bankDetails?.payoneerAccount || 'N/A'}</div>
                      <div>
                        <Button
                          disabled={!p.bankDetailsProof}
                          size="small"
                          startIcon={<Download />}
                          onClick={() => download(p.bankDetailsProof!)}
                        >
                          Proof of bank details
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                  <User user={p.user!} />
                </CardContent>

                <CardActions>
                  {p.status !== EReviewStatus.Draft && <Button onClick={() => draft(p)}>Draft</Button>}
                  {p.status !== EReviewStatus.Review && <Button onClick={() => review(p)}>Review</Button>}
                  {p.status !== EReviewStatus.Approve && <Button onClick={() => approve(p)}>Approve</Button>}
                  {p.status !== EReviewStatus.Reject && (
                    <Button onClick={() => reject(p)} color="error">
                      Reject
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Box>
          ))}
          <Empty show={!state.pager.data.length} />
        </>
      ) : (
        <CircularProgress />
      )}
    </Layout>
  );
});

const ApproveDialog = ({ performer, resolve }: { performer: IPerformer; resolve(a: IPerformer | null): void }) => {
  const [loading, setLoading] = useState(false);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Performers.approvePerformer(performer.id)
        .then((res) => {
          toast.success('Performer profile approved!');
          resolve(res);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [performer, setLoading, resolve]
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit}>
        <DialogTitle>Performer profile approval</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure to approve this performer?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type="submit" startIcon={<Check />}>
            Approve
          </Button>
          <Button type="button" onClick={() => resolve(null)}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Loadable>
  );
};

const RejectDialog = ({ performer, resolve }: { performer: IPerformer; resolve(p: IPerformer | null): void }) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Performers.rejectPerformer(performer.id, { reason })
        .then((res) => {
          toast.success('Performer profile rejected!');
          resolve(res);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [performer, setLoading, reason, resolve]
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit}>
        <DialogTitle>Performer profile rejection</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to reject this performer profile? Specify the reason for rejecting
          </DialogContentText>

          <Stack mt={2}>
            <TextField
              margin="dense"
              label="Reject reason"
              type="text"
              required
              autoFocus
              fullWidth
              variant="outlined"
              value={reason || ''}
              onChange={(e) => setReason(e.target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="error">
            Reject
          </Button>
          <Button type="button" onClick={() => resolve(null)}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Loadable>
  );
};

export default ReviewPerformersProfiles;
