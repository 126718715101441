import { IAlbum } from '../modules/rest';
import { Avatar, Chip, Stack, Typography } from '@mui/material';
import { thumb } from '../modules/utils';
import { purple } from '@mui/material/colors';
import { MusicNote } from '@mui/icons-material';
import React from 'react';

const Album = ({ album, onClick, showAvatar = true }: { album: IAlbum; onClick?(): void; showAvatar?: boolean }) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="start"
      onClick={onClick}
      className={onClick ? 'cursor-pointer' : ''}
      display="inline-flex"
    >
      <Stack alignItems="center">
        {showAvatar && (
          <Avatar
            src={album?.cover ? thumb(album?.cover.id, 80) : undefined}
            sx={{ background: purple[300], fontSize: 12 }}
            variant="rounded"
          >
            {album.id ?? <MusicNote />}
          </Avatar>
        )}
      </Stack>

      <Stack>
        <Stack direction="row" spacing={1}>
          <Typography variant="body2">{album.title}</Typography>
          <Chip variant="outlined" size="small" label={album.isSingle ? 'Single' : `${album.tracksCount} tracks`} />
        </Stack>
        <Typography variant="caption" color="gray">
          {album.performer?.stageName}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Album;
