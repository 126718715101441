import Layout from '../../../components/layout';
import NavTabs from './tabs';
import React, { useCallback, useEffect, useState } from 'react';
import { EFieldGroup, ETrackReviewStatus, IGetTracksRequest, ITrack } from '../../../modules/rest';
import { API } from '../../../modules/api';
import { toast } from 'react-toastify';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Empty from '../../../components/empty';
import Track from '../../../components/track';
import { confirmDialog, openDialog, rejectDialog } from '../../../components/dialogs';
import Loadable from '../../../components/loadable';
import { download, formatTime } from '../../../modules/utils';
import { Analytics, Download, Edit, Search, TextFields } from '@mui/icons-material';
import Player from '../../../components/player';
import { openEditTrackDialog } from '../../tracks/edit-track';
import { waitForTask } from '../../../components/dialogs/tasks';
import session from '../../../modules/session';
import User from '../../../components/user';
import langs from '../../../assets/json/langs.en.json';
import { runInAction } from 'mobx';

const scope = [
  EFieldGroup.TrackAlbum,
  EFieldGroup.TrackEdit,
  EFieldGroup.TrackDuration,
  EFieldGroup.TrackComment,
  EFieldGroup.PerformerUser,
];

const Meta = () => {
  const [tracks, setTracks] = useState<ITrack[]>();
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState<IGetTracksRequest>({ reviewStatus: ETrackReviewStatus.Meta, limit: 1000 });

  const fetch = useCallback(() => {
    setLoading(true);
    API.Tracks.getTracksList(request, scope)
      .then((res) => setTracks(res.data))
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setTracks, setLoading, request]);

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Layout
      loading={loading}
      title="Meta"
      actions={
        <Stack direction="row" spacing={4}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              name="search"
              placeholder="Search by title, artist, album, ISRC..."
              value={request.query || ''}
              onChange={(e) => setRequest({ ...request, query: e.target.value || undefined })}
              size="small"
              onKeyDown={(e) => {
                if (e.key === 'Enter') fetch();
              }}
              sx={{ flexGrow: 1 }}
            />

            <IconButton
              onClick={() =>
                runInAction(() => {
                  return fetch();
                })
              }
            >
              <Search />
            </IconButton>
          </Stack>

          <NavTabs value={2} />
        </Stack>
      }
    >
      {tracks ? (
        <Stack spacing={2} sx={{ mb: 2 }}>
          {tracks.map((track) => (
            <MetaItem key={track.id} track={track} fetch={fetch} />
          ))}
          <Empty show={!tracks.length} />
        </Stack>
      ) : (
        <CircularProgress />
      )}
    </Layout>
  );
};

const MetaItem = ({ track, fetch }: { track: ITrack; fetch(): void }) => {
  const [loading, setLoading] = useState(false);
  const approve = useCallback(() => {
    confirmDialog('Are you sure to approve meta-info for this track?', { confirmText: 'Approve' }).then((res) => {
      if (!res) return;
      setLoading(true);
      API.Tracks.approveTrack(track.id)
        .then(fetch)
        .catch(toast.error)
        .finally(() => {
          setLoading(false);
          return session.fetch();
        });
    });
  }, [setLoading, fetch, track.id]);

  const reject = useCallback(() => {
    rejectDialog('Are you sure to reject this track?', { rejectText: 'Reject' }).then((res) => {
      if (res) {
        setLoading(true);
        API.Tracks.rejectTrack(track.id, { reason: res })
          .then(fetch)
          .catch(toast.error)
          .finally(() => {
            setLoading(false);
            return session.fetch();
          });
      }
    });
  }, [setLoading, fetch, track.id]);

  return (
    <Loadable loading={loading}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="caption">ID: #{track.id}</Typography>
              <br />
              <Track track={track} />
            </Grid>

            <Grid item xs={2}>
              <Typography variant="caption">Account</Typography>
              <User user={track.performer!.user!} />
            </Grid>

            <Grid item xs={2}>
              <Typography variant="caption">Duration</Typography>
              <div>{formatTime(track.duration!)}</div>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="caption">{track?.album?.isSingle ? 'Single' : 'Album'}</Typography>
              <div>{track.album?.title || 'N/A'}</div>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="caption">Version</Typography>
              <div>{track.version || 'N/A'}</div>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="caption">Feat</Typography>
              <div>{track.feat || 'N/A'}</div>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="caption">Language</Typography>
              <div>
                {track.language ? (
                  <>
                    {langs[track.language]} ({track.language})
                  </>
                ) : (
                  'N/A'
                )}
              </div>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="caption">Mood</Typography>
              <div>{track.mood || 'N/A'}</div>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="caption">Genre</Typography>
              <div>
                {track.mainGenre?.name || 'N/A'} ({track.subGenre?.name || 'N/A'})
              </div>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="caption">Lyrics author</Typography>
              <div>{track.authorLyrics || 'N/A'}</div>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="caption">Music author</Typography>
              <div>{track.authorMusic || 'N/A'}</div>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="caption">Lyrics</Typography>
              <div>
                <Button
                  startIcon={<TextFields />}
                  disabled={!track.lyrics}
                  onClick={() => showLyrics(track)}
                  size="small"
                >
                  View
                </Button>
              </div>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="caption">Comment</Typography>
              <div>{track.comment || 'N/A'}</div>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Player track={track} />
          <Button onClick={() => download(track.file!)} startIcon={<Download />}>
            WAV
          </Button>
          <Button disabled={!track.sample} onClick={() => download(track.sample!)} startIcon={<Download />}>
            MP3
          </Button>

          <Button onClick={() => openEditTrackDialog(track).then((res) => res && fetch())} startIcon={<Edit />}>
            Edit
          </Button>

          <Box flexGrow={1} />

          <Button onClick={approve}>Approve</Button>
          <Button color="error" onClick={reject}>
            Reject
          </Button>
        </CardActions>
      </Card>
    </Loadable>
  );
};

const showLyrics = (track: ITrack) =>
  openDialog(
    (resolve) => {
      const [report, setReport] = useState<string>();

      const analysis = useCallback(() => {
        API.Tasks.makeLyricsAnalysis(track.id)
          .then((task) => waitForTask(task, track.title))
          .then((status) => status && setReport(status.result!))
          .catch(toast.error);
      }, []);

      return (
        <div>
          <DialogTitle>{track.title}</DialogTitle>
          <DialogContent>
            {report && (
              <Alert color="info" sx={{ whiteSpace: 'pre-wrap', maxHeight: 350, overflow: 'scroll-y' }}>
                {report}
              </Alert>
            )}
            <pre style={{ maxHeight: 350, overflow: 'scroll-y' }}>{track.lyrics}</pre>
          </DialogContent>
          <DialogActions>
            <Button onClick={analysis} startIcon={<Analytics />}>
              Analysis
            </Button>
            <Button onClick={() => resolve(null)}>Close</Button>
          </DialogActions>
        </div>
      );
    },
    { maxWidth: 'md', fullWidth: true }
  );

export default Meta;
