import {
  EDistributor,
  EFieldGroup,
  EMusicMood,
  EScheme,
  IGenre,
  ITrack,
  IUpdateTrackRequest,
} from '../../modules/rest';
import { openDialog } from '../../components/dialogs';
import { API } from '../../modules/api';
import Loadable from '../../components/loadable';
import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import React, { FormEvent, useCallback } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import { toast } from 'react-toastify';
import InputLanguage from '../../components/input-language';
import InputFile from '../../components/input-file';
import { openEditAlbumDialog } from './edit-album';
import { Edit } from '@mui/icons-material';
import { thumb } from '../../modules/utils';
import SelectGenre from '../../components/select-genre';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment/moment';

const Scope = [
  EFieldGroup.TrackEdit,
  EFieldGroup.TrackDuration,
  EFieldGroup.TrackAlbum,
  EFieldGroup.TrackDistributor,
  EFieldGroup.TrackScheme,
  EFieldGroup.TrackComment,
];

type State = {
  loading: boolean;
  request: IUpdateTrackRequest;
  mainGenre?: IGenre | null;
  subGenre?: IGenre | null;
};

const EditTrackDialog = observer(({ track, resolve }: { track: ITrack; resolve(track: ITrack | null): void }) => {
  const state = useLocalObservable<State>(() => ({
    loading: false,
    request: {
      ...track,
      fileId: track.file?.id,
      coverId: track.album?.cover?.id,
      mainGenreId: track.mainGenre?.id,
      subGenreId: track.subGenre?.id,
      releasedAt: track.album?.releasedAt,
    },
    mainGenre: track.mainGenre,
    subGenre: track.subGenre,
  }));

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      runInAction(() => (state.loading = true));
      API.Tracks.updateTrack(track.id, state.request, Scope)
        .then((res) => {
          toast.success('Track updated');
          resolve(res);
        })
        .catch(toast.error)
        .finally(() => runInAction(() => (state.loading = false)));
    },
    [state, resolve, track]
  );

  return (
    <form onSubmit={submit}>
      <Loadable loading={state.loading}>
        <DialogTitle>
          <span>{track.title}</span>
          {track.album?.isSingle && <Chip label="Single" sx={{ ml: 1 }} variant="outlined" size="small" />}
        </DialogTitle>
        <DialogContent>
          <Stack mt={1} spacing={2}>
            <TextField
              required
              label="Track title"
              value={state.request.title || ''}
              onChange={(e) => runInAction(() => (state.request.title = e.target.value))}
              fullWidth
            />
            <Stack direction="row" spacing={2}>
              <TextField
                required
                label="Music author"
                value={state.request.authorMusic || ''}
                onChange={(e) => runInAction(() => (state.request.authorMusic = e.target.value))}
                fullWidth
              />
              <TextField
                required={!!state.request.lyrics || !!state.request.language}
                label="Lyrics author"
                value={state.request.authorLyrics || ''}
                onChange={(e) => runInAction(() => (state.request.authorLyrics = e.target.value || null))}
                fullWidth
              />
            </Stack>

            <Stack direction="row" spacing={2}>
              <SelectGenre
                genre={state.mainGenre || null}
                subgenre={state.subGenre || null}
                onChange={(genre, subgenre) =>
                  runInAction(() => {
                    console.log(genre, subgenre);
                    state.request.mainGenreId = genre?.id;
                    state.request.subGenreId = subgenre?.id;
                    state.mainGenre = genre;
                    state.subGenre = subgenre;
                  })
                }
              />

              <TextField
                required
                label="Mood"
                value={state.request.mood}
                select
                onChange={(e) => runInAction(() => (state.request.mood = e.target.value as EMusicMood))}
                fullWidth
              >
                {Object.entries(EMusicMood).map(([name, id]) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
              <InputLanguage
                required={!!state.request.lyrics || !!state.request.authorLyrics}
                value={state.request.language}
                onChange={(value) => runInAction(() => (state.request.language = value || null))}
                fullWidth
              />
            </Stack>

            <TextField
              required={!!state.request.language || !!state.request.authorLyrics}
              label="Lyrics"
              multiline
              rows={5}
              value={state.request.lyrics || ''}
              onChange={(e) => runInAction(() => (state.request.lyrics = e.target.value || null))}
              fullWidth
            />
            <Stack direction="row" spacing={2}>
              <TextField
                label="Version"
                value={state.request.version || ''}
                onChange={(e) => runInAction(() => (state.request.version = e.target.value || null))}
                fullWidth
              />

              <TextField
                label="Feat"
                value={state.request.feat || ''}
                onChange={(e) => runInAction(() => (state.request.feat = e.target.value || null))}
                fullWidth
              />
            </Stack>

            <TextField
              label="Comment"
              value={state.request.comment || ''}
              onChange={(e) => runInAction(() => (state.request.comment = e.target.value || null))}
              fullWidth
            />

            <Stack spacing={1} alignItems="start">
              <small className="text-muted">Track (WAV)*</small>
              <InputFile
                accept=".wav"
                value={state.request.fileId || null}
                onChange={(file) => runInAction(() => (state.request.fileId = file?.id))}
              />
            </Stack>

            {track.album?.isSingle && (
              <>
                <Stack spacing={1} alignItems="start">
                  <small className="text-muted">Cover (JPEG 3000x3000)*</small>
                  <InputFile
                    accept="image/jpeg"
                    value={state.request.coverId || null}
                    onChange={(cover) => runInAction(() => (state.request.coverId = cover?.id))}
                  />
                  {state.request.coverId && (
                    <img src={thumb(state.request.coverId, 150)} alt={state.request.title} height={75} width={75} />
                  )}
                </Stack>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    sx={{ maxWidth: 200 }}
                    format="DD.MM.YYYY"
                    label="Release date"
                    value={state.request.releasedAt ? moment(state.request.releasedAt) : null}
                    onChange={(value) =>
                      runInAction(() => (state.request.releasedAt = value?.format('YYYY-MM-DD') || null))
                    }
                  />
                </LocalizationProvider>
              </>
            )}

            <Stack direction="row" spacing={2} mt={2}>
              <TextField
                select
                label="Scheme"
                value={state.request.scheme || 0}
                onChange={(e) => runInAction(() => (state.request.scheme = (e.target.value as EScheme) || null))}
              >
                <MenuItem value={0}>Not selected</MenuItem>
                <MenuItem value={EScheme.White}>YouTube and/or distributor</MenuItem>
                <MenuItem value={EScheme.Gray}>Distributor only</MenuItem>
              </TextField>

              <TextField
                select
                label="Distributor"
                required={state.request.scheme === EScheme.Gray}
                value={state.request.distributor || 0}
                onChange={(e) =>
                  runInAction(() => (state.request.distributor = (e.target.value as EDistributor) || null))
                }
              >
                <MenuItem value={0}>Not selected</MenuItem>
                {Object.entries(EDistributor).map(([name, id]) => (
                  <MenuItem value={id} key={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          {!track.album?.isSingle && (
            <Button onClick={() => openEditAlbumDialog(track.album!)} startIcon={<Edit />}>
              Edit album
            </Button>
          )}
          <Box flexGrow={1} />
          <Button type="submit">Update track</Button>
          <Button onClick={() => resolve(null)}>Cancel</Button>
        </DialogActions>
      </Loadable>
    </form>
  );
});

const openEditTrackDialog = (track: ITrack) =>
  API.Tracks.getTrack(track.id, Scope).then((track) =>
    openDialog<ITrack | null>((resolve) => <EditTrackDialog track={track} resolve={resolve} />, {
      fullWidth: true,
      maxWidth: 'lg',
    })
  );

export { openEditTrackDialog };
