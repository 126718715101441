import { Badge, Stack } from '@mui/material';
import session from '../../modules/session';
import { NavLink } from 'react-router-dom';

const ReviewNavigation = () => (
  <Stack className="nav" direction="row" my={1}>
    <Badge badgeContent={session.badges?.reviewPerformerApplications} color="error" sx={{ marginRight: 3 }}>
      <NavLink to="/review/performers/">Performers</NavLink>
    </Badge>
    <Badge badgeContent={session.badges?.reviewYoutubersApplications} color="error" sx={{ marginRight: 3 }}>
      <NavLink to="/review/youtubers/">Youtubers</NavLink>
    </Badge>
    <Badge badgeContent={session.badges?.reviewPerformerProfiles} color="error" sx={{ marginRight: 3 }}>
      <NavLink to="/review/profiles/">Profiles</NavLink>
    </Badge>
    <Badge badgeContent={session.badges?.reviewVideoEmbedRequests} color="error">
      <NavLink to="/review/video-embeds/">Video embeds</NavLink>
    </Badge>
  </Stack>
);

export default ReviewNavigation;
