import { API, backendUrl } from './api';
import { EPermission, EService, EUserRole, EUserStatus, IAlbum, IAsset, IPagedData, ITrack, IUser } from './rest';
import moment from 'moment';
import { toast } from 'react-toastify';
import session from './session';

const thumb = (id: string, size: number) => {
  return `${backendUrl}/thumb/${size}/${id}`;
};

const formatTime = (s: number, trim: boolean = true): string => {
  return moment
    .utc(s * 1000)
    .format('HH:mm:ss' + (trim ? '' : '.SS'))
    .replace(/^00:/, '');
};

const formatNumber = (value: number, maximumFractionDigits: number = 0): string => {
  return value?.toLocaleString(undefined, { maximumFractionDigits });
};

const downloadTrackWav = (track: ITrack): void => {
  download(track.file!, `${track.performer?.stageName} - ${track.title}.wav`);
};

const downloadTrackMp3 = (track: ITrack): void => {
  download(track.sample!, `${track.performer?.stageName} - ${track.title}.mp3`);
};

const downloadAlbumCover = (album: IAlbum): void => {
  download(album.cover!, `${album.performer?.stageName} - ${album.title}.jpg`);
};

const download = (file: IAsset, name?: string): void => {
  window.location.href = `${backendUrl}/assets/download/${file.id}${name ? `/${name}` : ''}`;
};

const range = (from: number, to: number): number[] => {
  const result: number[] = [];
  for (let i = from; i <= to; i++) result.push(i);
  return result;
};

const monthRange = (): string[] => {
  return range(1, 12).map((v) => (v < 10 ? '0' : '') + v);
};

const yearRange = (): string[] => {
  const start = Number(moment().format('YYYY'));
  const end = start + 10;
  return range(start, end).map((v) => v.toString());
};

export type PagerState<TEntity, TRequest> = {
  pager?: IPagedData<TEntity>;
  request: TRequest;
  loading: boolean;
};

const canLogin = (user: IUser) => {
  return user.status === EUserStatus.Active && ![EUserRole.Admin, EUserRole.Service].includes(user.role);
};

const loginAs = (user: IUser) => {
  if (!canLogin(user)) {
    toast.error("You can't sign in as admin or service");
    return;
  }
  const t = toast.loading('Creating session...');
  API.Users.loginAs(user.id).then((res) => {
    let url;
    if (user.service === EService.Axtune) url = `https://console.axtune.com/as/${res.token}`;
    else if (user.service === EService.Zoundo) url = `https://console.zoundo.com/as/${res.token}`;
    else if (user.service === EService.TubePays) url = `https://member.tubepays.com/as/${res.token}`;
    else if (user.service === EService.Tubyx) url = `https://app.tubyx.com/as/${res.token}`;
    else if (user.service === EService.Reactrino) url = `https://console.reactrino.com/as/${res.token}`;
    else if (user.service === EService.Echotune) url = `https://console.echotunemusic.com/as/${res.token}`;
    else if (user.service === EService.Arpeggy) url = `https://console.arpeggy.com/as/${res.token}`;
    if (url) {
      toast.update(t, { isLoading: false, type: 'success', autoClose: 3000, render: 'Session created' });
      window.open(url);
    } else toast.update(t, { isLoading: false, type: 'error', autoClose: 3000, render: 'Failed to login' });
  });
};

const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

const isGranted = (role: EUserRole | EUserRole[]): boolean => {
  const roles = Array.isArray(role) ? role : [role];
  if (!session.user) return false;
  if (session.user.role === EUserRole.Admin) return true;
  return roles.includes(session.user.role);
};

const IsGranted = ({ role, children }: { role: EUserRole | EUserRole[]; children: any }) => {
  return isGranted(role) ? children : null;
};

const hasPermission = (permission: EPermission): boolean => {
  if (!session.user) return false;
  if (session.user.role !== EUserRole.Admin) return false;
  return session.user.permissions!.includes(permission) || session.user.permissions!.includes(EPermission.Full);
};

const HasPermission = ({ permission, children }: { permission: EPermission; children: any }) => {
  return hasPermission(permission) ? children : null;
};

const formatSize = (size: number): string => {
  if (size < 1024) return `${size} bytes`;
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} Kb`;
  if (size < 1024 * 1024 * 1024) return `${(size / 1024 / 1024).toFixed(2)} Mb`;
  return `${(size / 1024 / 1024 / 1024).toFixed(2)} Gb`;
};

export {
  thumb,
  formatTime,
  formatNumber,
  download,
  range,
  monthRange,
  yearRange,
  downloadTrackWav,
  downloadTrackMp3,
  downloadAlbumCover,
  canLogin,
  loginAs,
  scrollTop,
  isGranted,
  IsGranted,
  hasPermission,
  HasPermission,
  formatSize,
};
