import { openDialog } from '../../components/dialogs';
import { EDistributor, EScheme, IAlbum, IBatch, ICompany, ICreateBatchRequest, IPerformer } from '../../modules/rest';
import { useCallback, useEffect, useState } from 'react';
import Loadable from '../../components/loadable';
import {
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material';
import { API } from '../../modules/api';
import Empty from '../../components/empty';
import { toast } from 'react-toastify';

const openCreateBatchDialog = () =>
  openDialog<IBatch | false>(
    (resolve) => {
      const [performers, setPerformers] = useState<IPerformer[]>();
      const [albums, setAlbums] = useState<IAlbum[]>();
      const [companies, setCompanies] = useState<ICompany[]>();
      const [step, setStep] = useState(0);
      const [loading, setLoading] = useState(false);

      const [request, setRequest] = useState<ICreateBatchRequest>({
        albums: [],
        companyId: null!,
        distributor: null,
        performerId: null!,
        scheme: null!,
        trackCost: 15,
        contractPeriod: 5,
      });

      const fetchAlbums = useCallback(() => {
        API.Batches.getApprovedAlbums(request.performerId).then(setAlbums).catch(toast.error);
        API.Companies.getList().then(setCompanies).catch(toast.error);
      }, [request, setAlbums]);

      const create = useCallback(() => {
        setLoading(true);
        API.Batches.createBatch(request)
          .then((batch) => {
            toast.success('New batch created');
            resolve(batch);
          })
          .catch(toast.error)
          .finally(() => setLoading(false));
      }, [request, setLoading, resolve]);

      useEffect(() => {
        API.Batches.getReadyPerformers().then(setPerformers);
      }, [setPerformers]);

      return (
        <Loadable loading={loading}>
          <DialogTitle>New batch</DialogTitle>
          <DialogContent>
            <Stepper activeStep={step} orientation="vertical">
              <Step>
                <StepLabel>Choose performer</StepLabel>
                <StepContent>
                  <Stack alignItems="start" spacing={1}>
                    {performers ? (
                      <FormControl>
                        {performers.map((p) => (
                          <FormControlLabel
                            key={p.id}
                            checked={p.id === request.performerId}
                            onChange={(_, checked) => checked && setRequest({ ...request, performerId: p.id })}
                            control={<Radio />}
                            label={p.stageName}
                          />
                        ))}
                        <Empty
                          show={!performers.length}
                          color="error"
                          text="There are no approved performers with ready albums"
                        />
                      </FormControl>
                    ) : (
                      <CircularProgress />
                    )}
                    <Button
                      disabled={!request.performerId}
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setStep(1);
                        fetchAlbums();
                      }}
                    >
                      Continue
                    </Button>
                  </Stack>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Choose albums</StepLabel>
                <StepContent>
                  <Stack alignItems="start" spacing={1}>
                    {albums ? (
                      <Stack alignItems="start" spacing={1}>
                        <FormControl>
                          {albums.map((a) => (
                            <FormControlLabel
                              key={a.id}
                              checked={request.albums.includes(a.id)}
                              onChange={(_, checked) =>
                                setRequest({
                                  ...request,
                                  albums: checked
                                    ? [...request.albums, a.id]
                                    : request.albums.filter((el) => el !== a.id),
                                })
                              }
                              control={<Checkbox />}
                              label={a.title}
                            />
                          ))}
                          <Empty
                            show={!albums.length}
                            color="error"
                            text="There are no approved performers with ready albums"
                          />
                        </FormControl>
                      </Stack>
                    ) : (
                      <CircularProgress />
                    )}
                    <Stack direction="row" spacing={1}>
                      <Button
                        disabled={!request.albums.length}
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setStep(2);
                        }}
                      >
                        Continue
                      </Button>
                      <Button
                        disabled={!request.performerId}
                        size="small"
                        onClick={() => {
                          setStep(0);
                          setRequest({ ...request, albums: [] });
                        }}
                      >
                        Back
                      </Button>
                    </Stack>
                  </Stack>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Contract settings</StepLabel>
                <StepContent>
                  <Stack direction="row" spacing={2} my={2}>
                    <TextField
                      select
                      required
                      label="Scheme"
                      value={request.scheme || 0}
                      onChange={(e) => setRequest({ ...request, scheme: e.target.value as EScheme })}
                    >
                      {!request.scheme && <MenuItem value={0}>Not selected</MenuItem>}
                      <MenuItem value={EScheme.White}>YouTube and/or distributor</MenuItem>
                      <MenuItem value={EScheme.Gray}>Distributor only</MenuItem>
                    </TextField>

                    <TextField
                      select
                      label="Distributor"
                      required={request.scheme === EScheme.Gray}
                      value={request.distributor || 0}
                      onChange={(e) =>
                        setRequest({ ...request, distributor: (e.target.value as EDistributor) || null })
                      }
                    >
                      <MenuItem value={0}>Not selected</MenuItem>
                      {Object.entries(EDistributor).map(([name, id]) => (
                        <MenuItem value={id} key={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      select
                      required
                      label="Company"
                      value={request.companyId || 0}
                      onChange={(e) => setRequest({ ...request, companyId: Number(e.target.value) })}
                    >
                      {!request.companyId && <MenuItem value={0}>Not selected</MenuItem>}
                      {companies?.map((c) => (
                        <MenuItem key={c.id} value={c.id}>
                          {c.name} ({c.tradingAs})
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      required
                      label="Track cost"
                      value={request.trackCost}
                      sx={{ min: 0, step: 0.01 }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      onChange={(e) => setRequest({ ...request, trackCost: Number(e.target.value) })}
                    />
                  </Stack>
                  <TextField
                    required
                    label="Contract period"
                    value={request.contractPeriod}
                    sx={{ min: 1, step: 1, max: 5, width: 200 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">year{request.contractPeriod !== 1 && 's'}</InputAdornment>
                      ),
                    }}
                    onChange={(e) => setRequest({ ...request, contractPeriod: Number(e.target.value) })}
                  />
                </StepContent>
              </Step>
            </Stepper>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={create}
              disabled={!request.performerId || !request.albums.length || !request.scheme || !request.companyId}
            >
              Create batch
            </Button>
            <Button onClick={() => resolve(false)}>Close</Button>
          </DialogActions>
        </Loadable>
      );
    },
    { fullWidth: true, maxWidth: 'md' }
  );

export { openCreateBatchDialog };
