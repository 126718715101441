import { Navigate, Route, Routes } from 'react-router-dom';
import ReviewPerformersProfiles from './profiles';
import ReviewPerformersApplications from './performers';
import ReviewYoutubersApplications from './youtubers';
import ReviewVideoEmbeds from './video-embeds';
import ReviewTracksRoutes from './tracks';

const ReviewRoutes = () => (
  <Routes>
    <Route path="/tracks/*" element={<ReviewTracksRoutes />} />
    <Route path="/performers/" element={<ReviewPerformersApplications />} />
    <Route path="/youtubers/" element={<ReviewYoutubersApplications />} />
    <Route path="/profiles/" element={<ReviewPerformersProfiles />} />
    <Route path="/video-embeds/" element={<ReviewVideoEmbeds />} />
    <Route path="*" element={<Navigate to="/review/performers/" replace />} />
  </Routes>
);

export default ReviewRoutes;
