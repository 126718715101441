import { openDialog } from '../../components/dialogs';
import { IMonitoringCategory, IMonitoringChannel } from '../../modules/rest';
import Loadable from '../../components/loadable';
import { FormEvent, useCallback, useState } from 'react';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import { Button, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import SelectCategories from './select-categories';

export const openCreateMonitoringChannelDialog = () =>
  openDialog<IMonitoringChannel | null>(
    (resolve) => {
      const [loading, setLoading] = useState(false);
      const [url, setUrl] = useState('');
      const [categories, setCategories] = useState<IMonitoringCategory[]>([]);

      const submit = useCallback(
        (e: FormEvent) => {
          e.preventDefault();
          setLoading(true);
          API.Monitoring.createChannel({ category: categories.map((c) => c.id), url })
            .then((res) => {
              resolve(res);
            })
            .catch(toast.error)
            .finally(() => setLoading(false));
        },
        [setLoading, categories, url, resolve]
      );

      return (
        <form onSubmit={submit}>
          <Loadable loading={loading}>
            <DialogTitle>Submit channel for monitoring</DialogTitle>
            <DialogContent>
              <Stack mt={1} spacing={2}>
                <TextField
                  placeholder="https://www.youtube.com/@AwesomeChannel"
                  autoFocus
                  required
                  name="url"
                  label="Channel URL"
                  value={url}
                  InputProps={{ type: 'url' }}
                  onChange={(e) => setUrl(e.target.value)}
                />
                <SelectCategories values={categories} onChange={setCategories} />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button type="submit" disabled={!url || !categories.length} variant="contained">
                Submit
              </Button>
              <Button onClick={() => resolve(null)}>Close</Button>
            </DialogActions>
          </Loadable>
        </form>
      );
    },
    { fullWidth: true, maxWidth: 'sm' }
  );

export const openCreateMonitoringCategoryDialog = () =>
  openDialog<IMonitoringCategory | null>(
    (resolve) => {
      const [loading, setLoading] = useState(false);
      const [name, setName] = useState('');

      const submit = useCallback(
        (e: FormEvent) => {
          e.preventDefault();
          setLoading(true);
          API.Monitoring.createCategory({ name })
            .then(resolve)
            .catch(toast.error)
            .finally(() => setLoading(false));
        },
        [setLoading, name, resolve]
      );

      return (
        <form onSubmit={submit}>
          <Loadable loading={loading}>
            <DialogTitle>Create new category</DialogTitle>
            <DialogContent>
              <Stack mt={1} spacing={2}>
                <TextField
                  autoFocus
                  required
                  name="name"
                  label="Category name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button type="submit" disabled={!name} variant="contained">
                Create
              </Button>
              <Button onClick={() => resolve(null)}>Close</Button>
            </DialogActions>
          </Loadable>
        </form>
      );
    },
    { fullWidth: true, maxWidth: 'sm' }
  );
