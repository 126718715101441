import { openDialog } from '../../components/dialogs';
import { IUpdateProfileRequest, IUser } from '../../modules/rest';
import Loadable from '../../components/loadable';
import { FormEvent, useCallback, useState } from 'react';
import { Avatar, Button, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material';
import session from '../../modules/session';
import InputFile from '../../components/input-file';
import { thumb } from '../../modules/utils';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PasswordStrength } from '../../components/password';

const openEditProfileDialog = () =>
  openDialog<IUser | null>(
    (resolve) => {
      const [showPassword, setShowPassword] = useState(false);
      const [loading, setLoading] = useState(false);
      const [request, setRequest] = useState<IUpdateProfileRequest>({
        name: session.user!.name ?? null,
        email: session.user!.email,
        avatarId: session.user!.avatar?.id ?? null,
      });

      const submit = useCallback(
        (e: FormEvent) => {
          e.preventDefault();
          setLoading(true);
          API.Users.updateProfile(request)
            .then(resolve)
            .catch(toast.error)
            .finally(() => setLoading(false));
        },
        [setLoading, resolve, request]
      );

      return (
        <form onSubmit={submit}>
          <Loadable loading={loading}>
            <DialogTitle>Your profile</DialogTitle>
            <DialogContent>
              <Stack mt={1} spacing={2}>
                <TextField
                  label="Name"
                  required
                  value={request.name}
                  onChange={(e) => setRequest({ ...request, name: e.target.value || null })}
                />
                <TextField
                  label="E-mail"
                  required
                  value={request.email}
                  InputProps={{ type: 'email' }}
                  onChange={(e) => setRequest({ ...request, email: e.target.value })}
                />
                <Stack spacing={1}>
                  <TextField
                    label="Change password"
                    value={request.password}
                    onChange={(e) => setRequest({ ...request, password: e.target.value || undefined })}
                    InputProps={{
                      endAdornment: (
                        <IconButton onMouseDown={() => setShowPassword(true)} onMouseUp={() => setShowPassword(false)}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                    inputProps={{ type: showPassword ? 'text' : 'password', autoComplete: 'new-password' }}
                  />
                  {request.password && <PasswordStrength password={request.password} />}
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  {request.avatarId && <Avatar src={thumb(request.avatarId, 100)} />}
                  <InputFile
                    text="Select avatar"
                    deletable={true}
                    value={request.avatarId!}
                    onChange={(avatar) => setRequest({ ...request, avatarId: avatar?.id ?? null })}
                  />
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button type="submit" disabled={!request.name || !request.email}>
                Update profile
              </Button>
              <Button onClick={() => resolve(null)}>Close</Button>
            </DialogActions>
          </Loadable>
        </form>
      );
    },
    { maxWidth: 'xs', fullWidth: true }
  );

export { openEditProfileDialog };
