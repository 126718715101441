import Layout from '../../../components/layout';
import NavTabs from './tabs';
import { useCallback, useEffect, useState } from 'react';
import { EFieldGroup, ETrackReviewStatus, ITrack } from '../../../modules/rest';
import { API } from '../../../modules/api';
import { toast } from 'react-toastify';
import { Box, Button, Card, CardContent, CircularProgress, colors, Stack, TextField, Link } from '@mui/material';
import Empty from '../../../components/empty';
import Track from '../../../components/track';
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import session from '../../../modules/session';

const scope = [EFieldGroup.TrackAlbum, EFieldGroup.AlbumPerformer];
type TComments = Record<number, string | null>;

const Spotify = () => {
  const [tracks, setTracks] = useState<ITrack[]>();
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState<TComments>({});
  const navigate = useNavigate();

  const fetch = useCallback(() => {
    setLoading(true);
    API.Tracks.getTracksList({ reviewStatus: ETrackReviewStatus.Spotify, limit: 1000 }, scope)
      .then((res) => setTracks(res.data))
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setTracks, setLoading]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const submit = useCallback(() => {
    setLoading(true);
    API.ReviewTracks.finishSpotify({ comments })
      .then(() => navigate('../meta', { replace: true }))
      .catch(toast.error)
      .finally(() => {
        setLoading(false);
        return session.fetch();
      });
  }, [setLoading, comments, navigate]);

  return (
    <Layout loading={loading} title="Spotify" actions={<NavTabs value={1} />}>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          {tracks ? (
            <Stack spacing={2}>
              {tracks.map((track) => (
                <Stack
                  key={track.id}
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{
                    backgroundColor: comments[track.id]
                      ? colors.red[100]
                      : comments[track.id] === null
                      ? colors.green[100]
                      : colors.grey[100],
                    p: 1,
                    borderRadius: 1,
                  }}
                >
                  <small className="text-muted">#{track.id}</small>

                  <Track track={track} />

                  <Box flexGrow={1} />

                  <Link
                    target="_blank"
                    rel="noreferrer noopener"
                    href={`https://open.spotify.com/search/${encodeURIComponent(
                      track.title + ' ' + track.performer.stageName
                    )}`}
                    onClick={() => setComments({ ...comments, [track.id]: null })}
                    sx={{ display: 'flex' }}
                  >
                    <Search />
                    <span>Search on Spotify</span>
                  </Link>

                  <TextField
                    disabled={comments[track.id] === undefined}
                    name="reason"
                    value={comments[track.id] ?? ''}
                    onChange={(e) => setComments({ ...comments, [track.id]: e.target.value || null })}
                    size="small"
                    sx={{ width: '50%' }}
                  />
                </Stack>
              ))}
              <Empty show={!tracks.length} />
              <Button disabled={!tracks.length} variant="contained" sx={{ alignSelf: 'start' }} onClick={submit}>
                Submit result
              </Button>
            </Stack>
          ) : (
            <CircularProgress />
          )}
        </CardContent>
      </Card>
    </Layout>
  );
};

export default Spotify;
