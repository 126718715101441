import { LinearProgress, Stack } from '@mui/material';
import { useMemo } from 'react';

const getPasswordStrength = (password: string): number => {
  let strength = 0;
  const checks = [/.{5,}/, /[a-z]/, /[0-9]/, /[A-Z]/, /\W/];
  checks.forEach((mask) => password.match(mask) && strength++);
  return strength;
};

const generatePassword = (length: number = 8) => {
  let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}[]:?';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

const status: Record<
  number,
  { color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'; text: string }
> = {
  1: { color: 'error', text: 'Very weak' },
  2: { color: 'error', text: 'Weak' },
  3: { color: 'warning', text: 'Medium' },
  4: { color: 'success', text: 'Strong' },
  5: { color: 'success', text: 'Very strong' },
};

const PasswordStrength = ({ password }: { password: string }) => {
  const strength = useMemo(() => getPasswordStrength(password), [password]);
  return (
    <Stack>
      <LinearProgress variant="determinate" color={status[strength].color} value={(strength / 5) * 100} />
      <small className="text-muted">{status[strength].text}</small>
    </Stack>
  );
};

export { getPasswordStrength, generatePassword, PasswordStrength };
