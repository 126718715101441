import { Badge, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import session from '../../../modules/session';
import { observer } from 'mobx-react';

const NavTabs = observer(({ value }: { value: number }) => {
  const navigate = useNavigate();
  return (
    <Tabs value={value}>
      <Tab
        label={
          <Badge
            badgeContent={session.badges?.reviewTracksContentId! + session.badges?.reviewTracksInit! || null}
            color="error"
            sx={{ px: 1 }}
          >
            Content ID
          </Badge>
        }
        onClick={() => navigate('../content-id', { replace: true })}
      />

      <Tab
        label={
          <Badge badgeContent={session.badges?.reviewTracksSpotify!} color="error" sx={{ px: 1 }}>
            Spotify
          </Badge>
        }
        onClick={() => navigate('../spotify', { replace: true })}
      />
      <Tab
        label={
          <Badge badgeContent={session.badges?.reviewTracksMeta!} color="error" sx={{ px: 1 }}>
            Meta
          </Badge>
        }
        onClick={() => navigate('../meta', { replace: true })}
      />
      <Tab
        label={
          <Badge badgeContent={session.badges?.reviewAlbums!} color="error" sx={{ px: 1 }}>
            Albums
          </Badge>
        }
        onClick={() => navigate('../albums', { replace: true })}
      />
      <Tab
        label={
          <Badge badgeContent={session.badges?.albumCovers!} color="error" sx={{ px: 1 }}>
            Covers
          </Badge>
        }
        onClick={() => navigate('../covers', { replace: true })}
      />
    </Tabs>
  );
});

export default NavTabs;
