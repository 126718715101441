import Layout from '../../components/layout';
import { Button, Paper, Stack, Tab, Tabs, TextField } from '@mui/material';
import { Save } from '@mui/icons-material';

import React, { useCallback, useEffect, useState } from 'react';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import { ISettings } from '../../modules/rest';

const defaultSettings: ISettings = {
  openAiCredentials: [],
  googleApiCredentials: [],
  httpProxies: [],
};

const Settings = () => {
  const [settings, setSettings] = useState<ISettings>(defaultSettings);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);

  const save = useCallback(() => {
    setLoading(true);
    API.Storage.setItem({ key: 'settings', value: settings, isGlobal: true })
      .then(() => {
        toast.success('Settings updated');
      })
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setLoading, settings]);

  useEffect(() => {
    API.Storage.getItem<ISettings>({ key: 'settings', isGlobal: true })
      .then((res) => setSettings(res ?? defaultSettings))
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setSettings, setLoading]);

  return (
    <Layout
      loading={loading}
      title="Settings"
      actions={
        <Stack direction="row" spacing={2} alignItems="center">
          <Tabs value={page} onChange={(_, idx) => setPage(idx)}>
            <Tab label="Open AI" />
            <Tab label="Google API" />
            <Tab label="HTTP Proxy" />
          </Tabs>
          <Button startIcon={<Save />} variant="contained" onClick={save} disabled={loading}>
            Save settings
          </Button>
        </Stack>
      }
    >
      <Paper>
        <Stack spacing={2} p={2}>
          {page === 0 && (
            <>
              {/*<TextField*/}
              {/*  multiline*/}
              {/*  label="OpenAI Credentials"*/}
              {/*  placeholder={`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:185.61.80.81:63976:1CtH6QPJ:zxyHGK7S\nsk-9PgUTzyaMRPuvVweIwUdT3BdbkFJTEfo3Fkn2IB1lJvRQB3E`}*/}
              {/*  value={settings?.openAiCredentials?.join('\n') || ''}*/}
              {/*  onChange={(e) => setSettings({ ...settings, openAiCredentials: e.target.value.split('\n') })}*/}
              {/*  rows={10}*/}
              {/*/>*/}

              <TextField
                multiline
                label="AI lyrics analysis prompt"
                placeholder={`You can use {title} and {text} placeholders`}
                value={settings?.aiLyricsAnalysisPrompt || ''}
                onChange={(e) => setSettings({ ...settings, aiLyricsAnalysisPrompt: e.target.value })}
                rows={10}
              />
            </>
          )}

          {page === 1 && (
            <>
              <TextField
                multiline
                label="Google API Credentials"
                placeholder={`AIzaSyBOrWMiLiDhIqRCEjrMBaBcoj5mKfoF1ng`}
                value={settings?.googleApiCredentials?.join('\n') || ''}
                onChange={(e) => setSettings({ ...settings, googleApiCredentials: e.target.value.split('\n') })}
                rows={10}
              />
            </>
          )}

          {page === 2 && (
            <>
              <TextField
                multiline
                label="HTTP Proxy"
                placeholder={`71.91.112.142:63138:1CtH6QPJ:PxxHGK7S`}
                value={settings?.httpProxies?.join('\n') || ''}
                onChange={(e) => setSettings({ ...settings, httpProxies: e.target.value.split('\n') })}
                rows={10}
              />
            </>
          )}
        </Stack>
      </Paper>
    </Layout>
  );
};

export default Settings;
