import { Button, Stack } from '@mui/material';
import { ReactNode, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const Expandable = ({ children, height = 20 }: { children: ReactNode; height?: number }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Stack>
      <div
        style={{
          maxHeight: expanded ? 'inherit' : height,
          overflow: expanded ? 'inherit' : 'hidden',
        }}
      >
        {children}
      </div>
      <Button size="small" variant="text" sx={{ height: 10 }} onClick={() => setExpanded(!expanded)}>
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </Button>
    </Stack>
  );
};

export default Expandable;
