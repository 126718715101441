import { Box, IconButton, Stack } from '@mui/material';
import { IAsset } from '../modules/rest';
import SelectRange, { TRange } from './select-range';
import { LegacyRef, useCallback, useEffect, useRef, useState } from 'react';
import { FastRewind, Pause, PlayArrow } from '@mui/icons-material';
import { formatTime } from '../modules/utils';

const PreviewMedia = ({
  type,
  media,
  range,
  onChangeRange,
  muted = false,
}: {
  type: 'audio' | 'video';
  media: IAsset;
  range: TRange;
  muted?: boolean;
  onChangeRange(range: TRange): void;
}) => {
  const ref = useRef<HTMLVideoElement | HTMLAudioElement | null>(null);
  const [time, setTime] = useState<number>(range[0]);
  const [isPlaying, setIsPlaying] = useState(false);

  const play = useCallback(() => {
    ref.current!.play().then(() => setIsPlaying(true));
  }, [ref, setIsPlaying]);

  const stop = useCallback(() => {
    setIsPlaying(false);
    ref.current!.pause();
  }, [ref, setIsPlaying]);

  const rewind = useCallback(() => {
    ref.current!.currentTime = range[0]!;
    stop();
  }, [ref, range, stop]);

  const check = useCallback(() => {
    if (!ref.current) return;
    setTime(ref.current.currentTime);
    if (ref.current.currentTime < range[0] || ref.current.currentTime >= range[1]) {
      rewind();
    }
  }, [ref, range, rewind]);

  const changeRange = useCallback(
    (range: TRange) => {
      onChangeRange(range);
      rewind();
    },
    [rewind, onChangeRange]
  );

  useEffect(() => {
    const interval = setInterval(check, 50);
    return () => clearInterval(interval);
  }, [check]);

  return (
    <Stack spacing={1}>
      {type === 'audio' && <audio ref={ref as LegacyRef<HTMLAudioElement>} src={media.url!} />}
      {type === 'video' && (
        <video ref={ref as LegacyRef<HTMLVideoElement>} src={media.url!} width={320} muted={muted} />
      )}
      <Stack direction="row" alignItems="center" spacing={2}>
        <IconButton onClick={rewind}>
          <FastRewind />
        </IconButton>
        {isPlaying ? (
          <IconButton onClick={stop}>
            <Pause />
          </IconButton>
        ) : (
          <IconButton onClick={play}>
            <PlayArrow />
          </IconButton>
        )}
        <Box width={4} />
        <Box flexGrow={1}>
          <SelectRange
            value={range}
            duration={media.duration!}
            onChange={changeRange}
            marks={[{ value: time, label: formatTime(time) }]}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default PreviewMedia;
