import { observer, useLocalObservable } from 'mobx-react';
import {
  EFieldGroup,
  EReviewStatus,
  ESortOrder,
  EUserRole,
  IGetApplicationsRequest,
  IYoutuberApplication,
} from '../../modules/rest';
import cache from '../../modules/cache';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { API } from '../../modules/api';
import { runInAction } from 'mobx';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { Check, Close, FilterAlt, Label } from '@mui/icons-material';
import { PagerState } from '../../modules/utils';
import session from '../../modules/session';
import { openDialog } from '../../components/dialogs';
import Loadable from '../../components/loadable';
import Pager from '../../components/pager';
import Empty from '../../components/empty';
import Layout from '../../components/layout';
import ReviewNavigation from './nav';
import InputUser from '../../components/input-user';
import InputCountry from '../../components/input-country';
import User from '../../components/user';

const ReviewYoutubersApplications = observer(() => {
  const state = useLocalObservable<PagerState<IYoutuberApplication, IGetApplicationsRequest>>(() => ({
    loading: true,
    pager: cache.get('applications.youtubers.pager'),
    request: cache.get('applications.youtubers.request') ?? {
      page: 1,
      limit: 50,
      status: EReviewStatus.Review,
      order: ESortOrder.DESC,
    },
  }));

  const fetchList = useCallback(() => {
    runInAction(() => (state.loading = true));
    API.Applications.getYoutuberApplications(state.request, [EFieldGroup.ApplicationUser])
      .then((pager) =>
        runInAction(() => {
          state.pager = pager;
          state.request.page = pager.page;
          state.request.limit = pager.limit;
          cache.set('applications.youtubers.pager', state.pager);
          cache.set('applications.youtubers.request', state.request);
        })
      )
      .catch(toast.error)
      .finally(() => runInAction(() => (state.loading = false)));
  }, [state]);

  const approve = useCallback(
    (a: IYoutuberApplication) => {
      openDialog((resolve) => <ApproveDialog application={a} resolve={resolve} />).then(() => {
        return Promise.all([session.fetch(), fetchList()]);
      });
    },
    [fetchList]
  );

  const reject = useCallback(
    (a: IYoutuberApplication) => {
      openDialog((resolve) => <RejectDialog application={a} resolve={resolve} />).then(() => {
        return Promise.all([session.fetch(), fetchList()]);
      });
    },
    [fetchList]
  );

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <Layout
      title="Review youtubers"
      loading={state.loading}
      footer={<Pager pager={state.pager} request={state.request} onChange={fetchList} />}
      breadcrumbs={<ReviewNavigation />}
      actions={
        <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
          <TextField
            placeholder="Search"
            size="small"
            value={state.request.query || ''}
            sx={{ width: 300 }}
            onChange={(e) => runInAction(() => (state.request.query = e.target.value || undefined))}
          />
          <InputUser
            value={state.request.manager}
            onChange={(value) =>
              runInAction(() => {
                state.request.manager = value?.id;
              })
            }
            filter={{ role: EUserRole.Admin }}
          />
          <InputCountry
            label="Country"
            value={state.request.country}
            onChange={(value) => runInAction(() => (state.request.country = value ?? undefined))}
            size="small"
          />
          <TextField
            select
            size="small"
            value={state.request.status}
            sx={{ width: 120 }}
            label="Status"
            onChange={(e) => runInAction(() => (state.request.status = e.target.value as EReviewStatus))}
          >
            {Object.entries(EReviewStatus).map(([name, value]) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            size="small"
            value={state.request.order}
            sx={{ width: 170 }}
            label="Sort order"
            onChange={(e) => runInAction(() => (state.request.order = e.target.value as ESortOrder))}
          >
            <MenuItem value={ESortOrder.DESC}>New ones first</MenuItem>
            <MenuItem value={ESortOrder.ASC}>Old ones first</MenuItem>
          </TextField>

          <Button
            size="small"
            variant="contained"
            onClick={() =>
              runInAction(() => {
                state.request.page = 1;
                fetchList();
              })
            }
            startIcon={<FilterAlt />}
          >
            Apply
          </Button>
          <Button
            size="small"
            onClick={() =>
              runInAction(() => {
                state.request = { status: EReviewStatus.Review, order: ESortOrder.DESC };
                fetchList();
              })
            }
            startIcon={<Close />}
          >
            Reset
          </Button>
        </Stack>
      }
    >
      {state.pager ? (
        <>
          {state.pager.data.map((a) => (
            <Card key={a.id} sx={{ marginBottom: 2 }}>
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  #{a.id} / {moment(a.createdAt).format('DD.MM.YYYY')}
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    E-mail:
                    <br /> <b>{a.email}</b>
                  </Grid>
                  <Grid item xs={2}>
                    Contacts:
                    <br /> <b>{a.contacts}</b>
                  </Grid>
                  <Grid item xs={2}>
                    Channels:
                    {a.links.map((url, idx) => (
                      <div key={idx}>
                        <a href={url} target="blank">
                          Channel #{idx + 1}
                        </a>
                      </div>
                    ))}
                  </Grid>
                  <Grid item xs={2}>
                    Views:
                    <br /> <b>{a.views}</b>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack spacing={1}>
                      <div>
                        IP / Country:
                        <br /> {a.ip} ({a.country?.toUpperCase() ?? 'N/A'})
                      </div>
                      <div>
                        Service: <b>{a.service ?? 'N/A'}</b>
                      </div>
                      <div>
                        <Chip label={a.source || 'N/A'} icon={<Label />} size="small" />
                      </div>
                    </Stack>
                  </Grid>
                </Grid>

                {a.user && (
                  <Stack spacing={1} mb={2}>
                    <small className="text-muted">Account</small>
                    <User user={a.user} />
                  </Stack>
                )}
                <div>
                  Status: <b>{a.status}</b>
                </div>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => approve(a)}>
                  Approve
                </Button>
                <Button size="small" onClick={() => reject(a)} color="error">
                  Reject
                </Button>
              </CardActions>
            </Card>
          ))}
          <Empty show={!state.pager.data.length} />
        </>
      ) : (
        <CircularProgress />
      )}
    </Layout>
  );
});

const ApproveDialog = ({
  application,
  resolve,
}: {
  application: IYoutuberApplication;
  resolve(a: IYoutuberApplication | null): void;
}) => {
  const [loading, setLoading] = useState(false);
  // const [password, setPassword] = useState<string | null>(generatePassword(16));
  // const [more, setMore] = useState(false);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Applications.approveYoutuberApplication(application.id, {})
        .then((res) => {
          toast.success('Application approved!');
          resolve(res);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [application, setLoading, resolve]
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit}>
        <DialogTitle>Youtuber monetization approval</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure to approve monetization for account {application.email}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" startIcon={<Check />}>
            Approve
          </Button>
          <Button type="button" onClick={() => resolve(null)}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Loadable>
  );
};

const RejectDialog = ({
  application,
  resolve,
}: {
  application: IYoutuberApplication;
  resolve(a: IYoutuberApplication | null): void;
}) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Applications.rejectYoutuberApplication(application.id, { reason })
        .then((res) => {
          toast.success('Application rejected!');
          resolve(res);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [application, setLoading, reason, resolve]
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit}>
        <DialogTitle>Youtuber application rejection</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to reject this application? Specify the reason for rejecting the application. It will be sent
            to {application.email}
          </DialogContentText>

          <Stack mt={2}>
            <TextField
              margin="dense"
              label="Reject reason"
              type="text"
              required
              autoFocus
              fullWidth
              variant="outlined"
              value={reason || ''}
              onChange={(e) => setReason(e.target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="error">
            Reject
          </Button>
          <Button type="button" onClick={() => resolve(null)}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Loadable>
  );
};

export default ReviewYoutubersApplications;
