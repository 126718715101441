import React, { useCallback, useEffect, useState } from 'react';
import { IconButton, Stack, TextField, TextFieldProps } from '@mui/material';
import { Add, Edit } from '@mui/icons-material';

type Props = {
  value: string | null;
  onChange(value: string | null): void;
  textFieldProps?: TextFieldProps;
};

const Editable = ({ value, onChange, textFieldProps = {} }: Props) => {
  const [edit, setEdit] = useState(false);
  const [val, setVal] = useState<string | null>(value);

  const save = useCallback(() => {
    if (val !== value) onChange(val);
    setEdit(false);
  }, [onChange, val, value, setEdit]);

  const cancel = useCallback(() => {
    setVal(value);
    setEdit(false);
  }, [value, setVal, setEdit]);

  const keyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        save();
      }
      if (e.key === 'Escape') {
        e.preventDefault();
        cancel();
      }
    },
    [save, cancel]
  );

  useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <div>
      {edit ? (
        <Stack direction="row" spacing={1} alignItems="center">
          <TextField
            size="small"
            autoFocus
            onKeyDown={keyDown}
            onBlur={save}
            value={val || ''}
            onChange={(e) => setVal(e.target.value || null)}
            {...textFieldProps}
          />
        </Stack>
      ) : (
        <Stack direction="row" spacing={1} alignItems="center">
          {value && <span onDoubleClick={() => setEdit(true)}>{value}</span>}
          <IconButton size="small" onClick={() => setEdit(true)}>
            {value ? <Edit /> : <Add />}
          </IconButton>
        </Stack>
      )}
    </div>
  );
};

export default Editable;
