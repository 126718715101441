import { Navigate, Route, Routes } from 'react-router-dom';
import AlbumsList from './list';

const AlbumsRoutes = () => (
  <Routes>
    <Route path="/" element={<AlbumsList />} />
    <Route path="*" element={<Navigate to="/albums/" replace />} />
  </Routes>
);

export default AlbumsRoutes;
