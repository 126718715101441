import Layout from '../../components/layout';
import { observer, useLocalObservable } from 'mobx-react';
import { PagerState } from '../../modules/utils';
import { EFieldGroup, EReviewStatus, IAlbum, IGetAlbumsRequest } from '../../modules/rest';
import { useCallback, useEffect } from 'react';
import { runInAction } from 'mobx';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import { Link, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Empty from '../../components/empty';
import Album from '../../components/album';
import Expandable from '../../components/expandable';
import Pager from '../../components/pager';
import Player from '../../components/player';

const AlbumsList = observer(() => {
  const state = useLocalObservable<PagerState<IAlbum, IGetAlbumsRequest>>(() => ({
    loading: true,
    request: { limit: 25, status: EReviewStatus.Approve, excludePerformers: [4, 5], withLinks: true },
  }));

  const fetch = useCallback(() => {
    runInAction(() => (state.loading = true));
    API.Albums.getAlbums(state.request, [
      EFieldGroup.AlbumPerformer,
      EFieldGroup.AlbumLinks,
      EFieldGroup.AlbumTracks,
      EFieldGroup.TrackSample,
    ])
      .then((res) =>
        runInAction(() => {
          state.pager = res;
          state.request.page = res.page;
          state.request.limit = res.limit;
        })
      )
      .catch(toast.error)
      .finally(() => runInAction(() => (state.loading = false)));
  }, [state]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Layout
      title="Albums"
      loading={state.loading}
      footer={<Pager pager={state.pager} onChange={fetch} request={state.request} />}
    >
      <TableContainer component={Paper}>
        {state.pager && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={50}>ID</TableCell>
                <TableCell>Album</TableCell>
                <TableCell width={300}>Tracks</TableCell>
                <TableCell width={300}>Links</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.pager.data.map((a) => (
                <TableRow key={a.id}>
                  <TableCell>{a.id}</TableCell>
                  <TableCell>
                    <Album album={a} />
                  </TableCell>
                  <TableCell>
                    <Expandable height={30}>
                      <Stack spacing={0.5} alignItems="start">
                        {a.tracks?.map((t) => (
                          <Stack direction="row" alignItems="center" spacing={0.5}>
                            <Player track={t} />
                            <span>{t.title}</span>
                          </Stack>
                        ))}
                      </Stack>
                    </Expandable>
                  </TableCell>
                  <TableCell>
                    <Expandable height={40}>
                      <Stack>
                        {a.links?.array.map((link) => (
                          <Link key={link}>{link.substring(0, 50)}</Link>
                        ))}
                      </Stack>
                    </Expandable>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <Empty show={state.pager?.count === 0} />
      </TableContainer>
    </Layout>
  );
});

export default AlbumsList;
