import { IAsset, IBatch } from '../../modules/rest';
import React, { useCallback, useState } from 'react';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import session from '../../modules/session';
import Loadable from '../../components/loadable';
import { Button, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import InputFile from '../../components/input-file';

const PayDialog = ({ batch, resolve }: { batch: IBatch; resolve(signed: boolean): void }) => {
  const [comment, setComment] = useState('');
  const [attachment, setAttachment] = useState<IAsset | null>(null);
  const [loading, setLoading] = useState(false);

  const sign = useCallback(() => {
    setLoading(true);
    API.Batches.payoutBatch(batch.id, { comment, attachmentId: attachment!.id })
      .then(() => {
        toast.success('Batch paid');
        resolve(true);
        return session.fetch();
      })
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [attachment, batch, resolve, setLoading, comment]);

  return (
    <Loadable loading={loading}>
      <DialogTitle>Payment details for {batch.tag}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={1}>
          <TextField
            multiline
            required
            label="Payment details"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            inputProps={{ maxLength: 200 }}
          />

          <Stack alignItems="start">
            <span className="text-muted">Attachment</span>
            <InputFile text="Attach file" value={attachment} onChange={setAttachment} accept=".pdf, .zip, .jpg, .png" />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={!comment} onClick={sign} variant="contained">
          Make payout
        </Button>
        <Button onClick={() => resolve(false)}>Cancel</Button>
      </DialogActions>
    </Loadable>
  );
};

export default PayDialog;
