import { Alert, Paper } from '@mui/material';
import React from 'react';
import { AlertColor } from '@mui/material/Alert/Alert';

const Empty = ({
  show,
  text = 'Nothing to show',
  color = 'info',
}: {
  show: boolean;
  text?: string;
  color?: AlertColor;
}) => {
  return show ? (
    <Paper>
      <Alert severity={color}>{text}</Alert>
    </Paper>
  ) : null;
};

export default Empty;
