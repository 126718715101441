import { ECountry, EFieldGroup, IAsset, IPerformer, IUpdatePerformerRequest } from '../../../modules/rest';
import { API } from '../../../modules/api';
import { openDialog } from '../../../components/dialogs';
import { observer, useLocalObservable } from 'mobx-react';
import Loadable from '../../../components/loadable';
import { Avatar, Button, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material';
import { FormEvent, useCallback } from 'react';
import { runInAction } from 'mobx';
import { toast } from 'react-toastify';
import InputCountry from '../../../components/input-country';
import { InputBankDetails } from '../../../components/input-bank-details';
import { InputPassport } from '../../../components/input-passport';
import InputFile from '../../../components/input-file';
import { download, thumb } from '../../../modules/utils';
import { Close, Download } from '@mui/icons-material';

const Scope = [EFieldGroup.PerformerEdit, EFieldGroup.PerformerStatus, EFieldGroup.PerformerBio];
type Result = IPerformer | null;
type State = {
  loading: boolean;
  request: IUpdatePerformerRequest;
};

const EditPerformerProfile = observer(
  ({ performer, resolve }: { performer: IPerformer; resolve(performer: Result): void }) => {
    const state = useLocalObservable<State>(() => ({
      loading: false,
      // @ts-ignore
      request: {
        ...performer,
        photoIds: performer.photos?.map((p) => p.id) ?? [],
        nationality: performer.nationality!,
        passportScanId: performer.passportScan?.id,
        passportProofId: performer.passportProof?.id,
        addressScanId: performer.addressScan?.id,
        bankDetailsProofId: performer.bankDetailsProof?.id,
      },
    }));

    const submit = useCallback(
      (e: FormEvent) => {
        e.preventDefault();
        runInAction(() => (state.loading = true));
        API.Performers.updatePerformer(performer.id, state.request, Scope)
          .then((res) => {
            toast.success('Profile updated');
            resolve(res);
          })
          .catch(toast.error)
          .finally(() => runInAction(() => (state.loading = false)));
      },
      [performer, state, resolve]
    );

    return (
      <form onSubmit={submit}>
        <Loadable loading={state.loading}>
          <DialogTitle>Performer profile #{performer.id}</DialogTitle>
          <DialogContent>
            <Stack sx={{ mt: 1 }} spacing={2}>
              <Stack direction="row" spacing={2}>
                <TextField
                  label="First name"
                  required
                  value={state.request.firstName || ''}
                  onChange={(e) => runInAction(() => (state.request.firstName = e.target.value ?? null))}
                  fullWidth
                />
                <TextField
                  label="Middle name"
                  value={state.request.middleName || ''}
                  onChange={(e) => runInAction(() => (state.request.middleName = e.target.value ?? null))}
                  fullWidth
                />
                <TextField
                  label="Last name"
                  required
                  value={state.request.lastName || ''}
                  onChange={(e) => runInAction(() => (state.request.lastName = e.target.value ?? null))}
                  fullWidth
                />
              </Stack>

              <Stack direction="row" spacing={2}>
                <TextField
                  label="Stage name"
                  required
                  value={state.request.stageName}
                  onChange={(e) => runInAction(() => (state.request.stageName = e.target.value))}
                  fullWidth
                />
                <InputCountry
                  required
                  label="Nationality / Citizenship"
                  value={state.request.nationality}
                  onChange={(value) => runInAction(() => (state.request.nationality = value as ECountry))}
                  fullWidth
                />
                <TextField
                  label="Phone number"
                  required
                  value={state.request.phoneNumber || ''}
                  onChange={(e) => runInAction(() => (state.request.phoneNumber = e.target.value ?? null))}
                  fullWidth
                />
              </Stack>

              <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
                <TextField
                  label="Address"
                  required
                  value={state.request.address || ''}
                  onChange={(e) => runInAction(() => (state.request.address = e.target.value ?? null))}
                  sx={{ flexGrow: 1 }}
                />
                <Stack alignItems="start" spacing={1} sx={{ width: 200 }}>
                  <small className="text-muted">Address proof scan*</small>
                  <Stack direction="row" alignItems="center">
                    <InputFile
                      accept=".zip, .jpg, .pdf"
                      value={state.request.addressScanId || null}
                      onChange={(value) => runInAction(() => (state.request.addressScanId = value?.id))}
                    />
                    {state.request.addressScanId && (
                      <IconButton size="small" onClick={() => download({ id: state.request.addressScanId } as IAsset)}>
                        <Download />
                      </IconButton>
                    )}
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
                <InputPassport
                  value={state.request.passport}
                  onChange={(passport) => runInAction(() => (state.request.passport = passport))}
                  sx={{ flexGrow: 1 }}
                />
                <Stack alignItems="start" spacing={1} sx={{ width: 200 }}>
                  <small className="text-muted">ID document scan*</small>
                  <Stack direction="row" alignItems="center">
                    <InputFile
                      accept=".zip, .jpg, .pdf"
                      value={state.request.passportScanId || null}
                      onChange={(value) => runInAction(() => (state.request.passportScanId = value?.id))}
                    />
                    {state.request.passportScanId && (
                      <IconButton size="small" onClick={() => download({ id: state.request.passportScanId } as IAsset)}>
                        <Download />
                      </IconButton>
                    )}
                  </Stack>
                </Stack>
                <Stack alignItems="start" spacing={1} sx={{ width: 200 }}>
                  <small className="text-muted">ID document proof*</small>
                  <Stack direction="row" alignItems="center">
                    <InputFile
                      accept=".jpg"
                      value={state.request.passportProofId || null}
                      onChange={(value) => runInAction(() => (state.request.passportProofId = value?.id))}
                    />
                    {state.request.passportProofId && (
                      <IconButton
                        size="small"
                        onClick={() => download({ id: state.request.passportProofId } as IAsset)}
                      >
                        <Download />
                      </IconButton>
                    )}
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
                <InputBankDetails
                  value={state.request.bankDetails}
                  onChange={(value) => runInAction(() => (state.request.bankDetails = value))}
                />
                <Stack alignItems="start" spacing={1} sx={{ width: 200 }}>
                  <small className="text-muted">Bank details proof scan</small>
                  <Stack direction="row" alignItems="center">
                    <InputFile
                      accept=".zip, .jpg, .pdf"
                      value={state.request.bankDetailsProofId || null}
                      onChange={(value) => runInAction(() => (state.request.bankDetailsProofId = value?.id))}
                    />
                    {state.request.bankDetailsProofId && (
                      <IconButton
                        size="small"
                        onClick={() => download({ id: state.request.bankDetailsProofId } as IAsset)}
                      >
                        <Download />
                      </IconButton>
                    )}
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction="row" spacing={2}>
                <TextField
                  label="Bio"
                  required
                  multiline
                  rows={5}
                  value={state.request.bio}
                  onChange={(e) => runInAction(() => (state.request.bio = e.target.value))}
                  sx={{ flexGrow: 1 }}
                />

                <TextField
                  label="Fans Also Like"
                  multiline
                  rows={5}
                  value={state.request.similar || ''}
                  onChange={(e) => runInAction(() => (state.request.similar = e.target.value || null))}
                  sx={{ flexGrow: 1 }}
                />
              </Stack>

              <Stack spacing={1} alignItems="start">
                <span>
                  Photos <small className="text-muted">{state.request.photoIds?.length}</small>
                </span>
                <Stack direction="row" spacing={1}>
                  {state.request.photoIds!.map((id) => (
                    <Stack key={id}>
                      <Avatar sx={{ width: 100, height: 100 }} variant="rounded">
                        <img alt="" src={thumb(id, 200)} />
                      </Avatar>
                      <Stack direction="row" alignItems="center" justifyContent="center">
                        <IconButton size="small" onClick={() => download({ id } as IAsset)} color="primary">
                          <Download />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() =>
                            runInAction(
                              () => (state.request.photoIds = state.request.photoIds!.filter((i) => i !== id))
                            )
                          }
                          color="error"
                        >
                          <Close />
                        </IconButton>
                      </Stack>
                    </Stack>
                  ))}
                </Stack>

                <InputFile
                  value={null}
                  onChange={(photo) => photo && runInAction(() => state.request.photoIds!.push(photo.id))}
                  accept="image/jpeg"
                  text="Add photo"
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained">
              Update profile
            </Button>
            <Button onClick={() => resolve(null)}>Cancel</Button>
          </DialogActions>
        </Loadable>
      </form>
    );
  }
);

const openEditPerformerProfileDialog = (p: IPerformer) =>
  API.Performers.getPerformer(p.id, Scope).then((performer) =>
    openDialog<Result>((resolve) => <EditPerformerProfile performer={performer} resolve={resolve} />, {
      fullWidth: true,
      maxWidth: 'lg',
    })
  );

export { openEditPerformerProfileDialog };
