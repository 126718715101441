import { Navigate, Route, Routes } from 'react-router-dom';
import UploadList from './list';
import UploadProcess from './process';

const UploadRoutes = () => (
  <Routes>
    <Route path="/" element={<UploadList />} />
    <Route path="/:id/" element={<UploadProcess />} />
    <Route path="*" element={<Navigate to="/upload/" replace />} />
  </Routes>
);

export default UploadRoutes;
