import { IAttachment, IBatch, ICreateAttachmentRequest, IUpdateAttachmentRequest } from '../modules/rest';
import Loadable from './loadable';
import React, { FormEvent, useCallback, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  TextField,
} from '@mui/material';
import { confirmDialog, openDialog } from './dialogs';
import { API } from '../modules/api';
import InputFile from './input-file';
import { toast } from 'react-toastify';
import { AttachmentOutlined, Delete, Edit } from '@mui/icons-material';
import Empty from './empty';
import { download, formatSize } from '../modules/utils';

const AttachmentDialog = ({
  attachment,
  action,
  resolve,
}: {
  attachment?: IAttachment;
  action(request: ICreateAttachmentRequest | IUpdateAttachmentRequest): Promise<IAttachment>;
  resolve(attachment: IAttachment | null): void;
}) => {
  const [loading, setLoading] = useState(false);
  const [asset, setAsset] = useState(attachment?.asset ?? null);
  const [comment, setComment] = useState(attachment?.comment ?? null);
  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      action({ asset: asset?.id, comment })
        .then(resolve)
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [setLoading, asset, comment, action, resolve]
  );
  return (
    <form onSubmit={submit}>
      <Loadable loading={loading}>
        <DialogTitle>Attachment</DialogTitle>
        <DialogContent>
          <Stack spacing={3} mt={1} alignItems="start">
            <Stack>
              <span className="text-muted">File*</span>
              {attachment ? (
                <span>
                  {attachment.asset.name} ({formatSize(attachment.asset.size)})
                </span>
              ) : (
                <InputFile value={asset} onChange={setAsset} />
              )}
            </Stack>
            <TextField
              fullWidth
              label="Comment"
              multiline
              value={comment || ''}
              inputProps={{ maxLength: 255 }}
              onChange={(e) => setComment(e.target.value || null)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" disabled={!asset}>
            {attachment ? 'Update' : 'Add'}
          </Button>
          <Button onClick={() => resolve(null)}>Cancel</Button>
        </DialogActions>
      </Loadable>
    </form>
  );
};

const openCreateBatchAttachmentDialog = (batch: IBatch) =>
  openDialog<IAttachment | null>(
    (resolve) => {
      const action = useCallback(
        (request: ICreateAttachmentRequest | IUpdateAttachmentRequest): Promise<IAttachment> => {
          return API.Batches.addAttachment(batch.id, request as ICreateAttachmentRequest);
        },
        []
      );
      return <AttachmentDialog action={action} resolve={resolve} />;
    },
    { fullWidth: true, maxWidth: 'sm' }
  );

const openEditAttachmentDialog = (attachment: IAttachment) =>
  openDialog<IAttachment | null>(
    (resolve) => {
      const action = useCallback((request: IUpdateAttachmentRequest): Promise<IAttachment> => {
        return API.Attachments.updateAttachment(attachment.id, request);
      }, []);
      return <AttachmentDialog action={action} resolve={resolve} attachment={attachment} />;
    },
    { fullWidth: true, maxWidth: 'sm' }
  );

const deleteAttachment = (attachment: IAttachment): Promise<boolean> =>
  confirmDialog(`Are you sure to delete ${attachment.asset.name}?`, {
    title: 'Delete attachment',
    confirmColor: 'error',
    confirmText: 'Delete',
  }).then((agree) => {
    if (!agree) return false;
    return API.Attachments.deleteAttachment(attachment.id);
  });

const Attachments = ({ attachments, add, fetch }: { attachments: IAttachment[]; add(): void; fetch(): void }) => {
  return (
    <Card>
      <CardHeader
        title="Attachments"
        action={
          <Button startIcon={<AttachmentOutlined />} onClick={add}>
            Add attachment
          </Button>
        }
      />
      <CardContent>
        <Empty show={!attachments.length} />
        <List>
          {attachments.map((att) => (
            <ListItem
              key={att.id}
              secondaryAction={
                <Stack direction="row" spacing={1}>
                  <IconButton size="small" onClick={() => openEditAttachmentDialog(att).then((att) => att && fetch())}>
                    <Edit />
                  </IconButton>
                  <IconButton size="small" onClick={() => deleteAttachment(att).then((deleted) => deleted && fetch())}>
                    <Delete />
                  </IconButton>
                </Stack>
              }
            >
              <ListItemIcon>
                <AttachmentOutlined />
              </ListItemIcon>
              <Stack>
                <Link
                  href={att.asset.url}
                  download
                  onClick={(e) => {
                    e.preventDefault();
                    download(att.asset);
                  }}
                >
                  {att.asset.name}
                </Link>
                <small className="text-muted">{att.comment}</small>
              </Stack>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export { openCreateBatchAttachmentDialog, openEditAttachmentDialog, deleteAttachment, Attachments };
