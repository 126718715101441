import { openDialog } from './dialogs';
import { EContentIdStatus, EFieldGroup, IGetTracksRequest, ITrack } from '../modules/rest';
import Loadable from './loadable';
import {
  Box,
  Button,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import Empty from './empty';
import Track from './track';
import { observer, useLocalObservable } from 'mobx-react';
import { PagerState } from '../modules/utils';
import { runInAction } from 'mobx';
import { API } from '../modules/api';
import { toast } from 'react-toastify';
import Player from './player';
import { Search, Videocam, VolumeUp } from '@mui/icons-material';
import Pager from './pager';

const OpenSelectTrackDialog = observer(
  ({ request, resolve }: { request: IGetTracksRequest; resolve(result: ITrack | null): void }) => {
    const state = useLocalObservable<PagerState<ITrack, IGetTracksRequest>>(() => ({
      request: { ...request, page: 1, limit: 20 },
      loading: true,
    }));

    const fetch = useCallback(() => {
      runInAction(() => (state.loading = true));
      API.Tracks.getTracksList(state.request, [
        EFieldGroup.TrackSample,
        EFieldGroup.TrackAlbum,
        EFieldGroup.TrackStatus,
      ])
        .then((pager) =>
          runInAction(() => {
            state.pager = pager;
            state.request.page = pager.page;
            state.request.limit = pager.limit;
          })
        )
        .catch(toast.error)
        .finally(() => runInAction(() => (state.loading = false)));
    }, [state]);

    useEffect(() => {
      fetch();
    }, [fetch]);

    return (
      <Loadable loading={state.loading}>
        <DialogTitle>Select track</DialogTitle>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ px: 2, mb: 1 }}>
          <TextField
            placeholder="Search by title, artist, album, ISRC..."
            value={state.request.query || ''}
            onChange={(e) => runInAction(() => (state.request.query = e.target.value || undefined))}
            size="small"
            onKeyDown={(e) => e.key === 'Enter' && fetch()}
            sx={{ flexGrow: 1 }}
          />
          {/*<TextField*/}
          {/*  select*/}
          {/*  size="small"*/}
          {/*  label="Audio monetization"*/}
          {/*  sx={{ width: 150 }}*/}
          {/*  value={state.request.audioStatus ?? 0}*/}
          {/*  onChange={(e) =>*/}
          {/*    runInAction(() => (state.request.audioStatus = (e.target.value as EContentIdStatus) || undefined))*/}
          {/*  }*/}
          {/*>*/}
          {/*  <MenuItem value={0}>Any</MenuItem>*/}
          {/*  {Object.entries(EContentIdStatus).map(([name, value]) => (*/}
          {/*    <MenuItem key={value} value={value}>*/}
          {/*      {name}*/}
          {/*    </MenuItem>*/}
          {/*  ))}*/}
          {/*</TextField>*/}

          {/*<TextField*/}
          {/*  select*/}
          {/*  size="small"*/}
          {/*  label="Video monetization"*/}
          {/*  sx={{ width: 150 }}*/}
          {/*  value={state.request.videoStatus ?? 0}*/}
          {/*  onChange={(e) =>*/}
          {/*    runInAction(() => (state.request.videoStatus = (e.target.value as EContentIdStatus) || undefined))*/}
          {/*  }*/}
          {/*>*/}
          {/*  <MenuItem value={0}>Any</MenuItem>*/}
          {/*  {Object.entries(EContentIdStatus).map(([name, value]) => (*/}
          {/*    <MenuItem key={value} value={value}>*/}
          {/*      {name}*/}
          {/*    </MenuItem>*/}
          {/*  ))}*/}
          {/*</TextField>*/}

          <Button
            onClick={() =>
              runInAction(() => {
                state.request.page = 1;
                return fetch();
              })
            }
            startIcon={<Search />}
            variant="contained"
          >
            Search
          </Button>
        </Stack>

        <TableContainer sx={{ minHeight: 500, maxHeight: 500, overflowY: 'scroll' }}>
          {state.pager && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={30}>ID</TableCell>
                  <TableCell>Track</TableCell>
                  <TableCell width={50}>Player</TableCell>
                  <TableCell width={100}>Monetization</TableCell>
                  <TableCell width={1} />
                </TableRow>
              </TableHead>
              <TableBody>
                {state.pager.data.map((track) => (
                  <TableRow key={track.id}>
                    <TableCell>{track.id}</TableCell>
                    <TableCell>
                      <Track track={track} showAvatar={true} />
                    </TableCell>
                    <TableCell>
                      <Player track={track} />
                    </TableCell>
                    <TableCell>
                      <VolumeUp className={`cid-status__${track.audioStatus}`} />
                      <Videocam className={`cid-status__${track.videoStatus}`} />
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        onClick={() => resolve(track)}
                        disabled={
                          track.videoStatus !== EContentIdStatus.Active && track.audioStatus !== EContentIdStatus.Active
                        }
                      >
                        Select
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <Empty show={!state.loading && state.pager?.data.length === 0} />
        </TableContainer>
        <Box p={2}>
          <Pager pager={state.pager} request={state.request} onChange={fetch} />
        </Box>
        {/*<DialogActions>*/}
        {/*  <Button onClick={() => resolve(null)}>Close</Button>*/}
        {/*</DialogActions>*/}
      </Loadable>
    );
  }
);

const openSelectTrackDialog = (request: IGetTracksRequest) => {
  return openDialog<ITrack | null>((resolve) => <OpenSelectTrackDialog request={request} resolve={resolve} />, {
    fullWidth: true,
    maxWidth: 'lg',
  });
};

export { openSelectTrackDialog };
