import { useNavigate, useParams } from 'react-router-dom';
import { EFieldGroup, IBatch } from '../../modules/rest';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Stack } from '@mui/material';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import Layout from '../../components/layout';
import { ArrowLeft } from '@mui/icons-material';
import { Attachments, openCreateBatchAttachmentDialog } from '../../components/deleteAttachment';
import Album from '../../components/album';
import Track from '../../components/track';

const BatchItem = () => {
  const [batch, setBatch] = useState<IBatch>();
  const params = useParams<{ id: string }>();

  const fetch = useCallback(() => {
    API.Batches.getBatch(params.id!, [EFieldGroup.BatchFull, EFieldGroup.AlbumTracks, EFieldGroup.AlbumPerformer])
      .then(setBatch)
      .catch(toast.error);
  }, [params, setBatch]);

  const navigate = useNavigate();

  useEffect(() => {
    fetch();
  }, [fetch, params.id]);

  if (!batch) return <CircularProgress />;

  return (
    <Layout
      title={`Batch ${batch.tag}`}
      breadcrumbs={
        <Stack direction="row" spacing={1}>
          <IconButton onClick={() => navigate('../', { replace: true })}>
            <ArrowLeft />
          </IconButton>
        </Stack>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {batch.albums && (
            <Card>
              <CardHeader title="Albums and tracks" />
              <CardContent>
                <Stack spacing={2}>
                  {batch.albums.map((album) => (
                    <div key={album.id}>
                      <Album album={album} />
                      {!album.isSingle && (
                        <Stack pl={2} mt={1} spacing={1}>
                          {album.tracks?.map((track) => (
                            <Track key={track.id} track={track} showAvatar={false} />
                          ))}
                        </Stack>
                      )}
                    </div>
                  ))}
                </Stack>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={6}>
          {batch.attachments && (
            <Attachments
              attachments={batch.attachments}
              add={() => openCreateBatchAttachmentDialog(batch).then((attachment) => attachment && fetch())}
              fetch={fetch}
            />
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default BatchItem;
