import { IAsset, IBatch } from '../../modules/rest';
import React, { useCallback, useState } from 'react';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import session from '../../modules/session';
import Loadable from '../../components/loadable';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from '@mui/material';
import { download } from '../../modules/utils';
import { Download } from '@mui/icons-material';
import InputFile from '../../components/input-file';

const SignDialog = ({ batch, resolve }: { batch: IBatch; resolve(signed: boolean): void }) => {
  const [attachment, setAttachment] = useState<IAsset | null>(null);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const sign = useCallback(() => {
    setLoading(true);
    API.Batches.signTwoBatch(batch.id, { documentId: attachment!.id })
      .then(() => {
        toast.success('Batch signed');
        resolve(true);
        return session.fetch();
      })
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [attachment, batch, resolve, setLoading]);

  return (
    <Loadable loading={loading}>
      <DialogTitle>Signing</DialogTitle>
      <DialogContent>
        <Stepper activeStep={step} orientation="vertical">
          <Step>
            <StepLabel>Step 1</StepLabel>
            <StepContent>
              <p>Download performer's signed contract</p>
              <Button
                disabled={!batch.contractOne}
                size="small"
                onClick={() => {
                  download(batch.contractOne!);
                  setStep(1);
                }}
                startIcon={<Download />}
              >
                Download
              </Button>
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Step 2</StepLabel>
            <StepContent>
              <p>Print downloaded document, then sign and scan it</p>
              <Button size="small" onClick={() => setStep(2)}>
                Next
              </Button>
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Step 3</StepLabel>
            <StepContent>
              <p>Upload scanned document</p>
              <InputFile text="Attach file" value={attachment} onChange={setAttachment} accept=".pdf, .zip" />
            </StepContent>
          </Step>
        </Stepper>
      </DialogContent>
      <DialogActions>
        <Button disabled={!attachment} onClick={sign} variant="contained">
          Sign batch
        </Button>
        <Button onClick={() => resolve(false)}>Cancel</Button>
      </DialogActions>
    </Loadable>
  );
};

export default SignDialog;
