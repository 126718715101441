import {
  Breadcrumbs,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { IDoc, IDocItem } from '../../modules/rest';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Delete,
  EditOutlined,
  Folder,
  Home,
  LabelOutlined,
} from '@mui/icons-material';
import Empty from '../../components/empty';
import { confirmDialog } from '../../components/dialogs';
import { openCreateDocumentDialog, openEditDocumentDialog } from './edit-dialog';
import Layout from '../../components/layout';

const ManageDocs = () => {
  const params = useParams<{ id?: string }>();
  const navigate = useNavigate();

  const [doc, setDoc] = useState<IDoc | null>();
  const [docs, setDocs] = useState<IDoc[]>();
  const [path, setPath] = useState<IDocItem[]>();
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const doc = params.id ? await API.Docs.getById(params.id) : null;
      const docs = await API.Docs.index({ parentId: doc ? doc.id : null, onlyPublished: false });
      const path = doc ? await API.Docs.getPath(doc.id) : [];
      setDoc(doc);
      setDocs(docs);
      setPath(path);
      setLoading(false);
    } catch (e: any) {
      toast.error(e);
    }
  }, [params.id, setDoc, setDocs, setPath, setLoading]);

  const fetchDocs = useCallback(() => {
    setLoading(true);
    API.Docs.index({ parentId: doc ? doc.id : null, onlyPublished: false })
      .then(setDocs)
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [doc, setLoading]);

  const swap = useCallback(
    (doc1: IDoc, doc2: IDoc) => {
      setLoading(true);
      Promise.all([
        API.Docs.update(doc1.id, { position: doc2.position }),
        API.Docs.update(doc2.id, { position: doc1.position }),
      ]).then(fetchDocs);
    },
    [fetchDocs]
  );

  const toggle = useCallback(
    (doc: IDoc) => {
      setLoading(true);
      API.Docs.update(doc.id, { isPublished: !doc.isPublished }).then(fetchDocs);
    },
    [fetchDocs]
  );

  const remove = useCallback(
    (doc: IDoc) => {
      confirmDialog(`Are you sure to delete "${doc.title}"?`, {
        title: 'Document deletion',
        confirmText: 'Delete',
        confirmColor: 'error',
      }).then((agree) => {
        if (!agree) return;
        setLoading(true);
        API.Docs.delete(doc.id)
          .then(() => {
            toast.success('Document deleted');
            return fetchDocs();
          })
          .catch(toast.error)
          .finally(() => setLoading(false));
      });
    },
    [fetchDocs]
  );

  const create = useCallback(() => openCreateDocumentDialog(doc!).then((res) => res && fetchDocs()), [doc, fetchDocs]);

  const edit = useCallback((doc: IDoc) => openEditDocumentDialog(doc).then((doc) => doc && fetchDocs()), [fetchDocs]);

  useEffect(() => {
    fetch().then();
  }, [params.id, fetch]);

  if (doc === undefined || !docs || !path) return <CircularProgress />;

  return (
    <Layout
      title="Docs"
      loading={loading}
      breadcrumbs={
        <Breadcrumbs separator="›">
          <Link to="/docs/" className="d-flex">
            <Home sx={{ marginRight: 0.5 }} />
            Home
          </Link>
          {path.map((d) => (
            <Link key={d.id} to={`/docs/${d.id}`}>
              {d.title}
            </Link>
          ))}
        </Breadcrumbs>
      }
      actions={
        <Button startIcon={<Add />} onClick={create}>
          Create document
        </Button>
      }
    >
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 30 }}>ID</TableCell>
              <TableCell>Title</TableCell>
              <TableCell sx={{ width: 300 }}>Tag</TableCell>
              <TableCell sx={{ width: 50 }}>Publish</TableCell>
              <TableCell sx={{ width: 140 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.map((d, idx) => {
              const prev = docs[idx - 1];
              const next = docs[idx + 1];
              return (
                <TableRow key={d.id} className="tr-show">
                  <TableCell>{d.id}</TableCell>
                  <TableCell>
                    <IconButton size="small" onClick={() => navigate(`/docs/${d.id}/`)}>
                      <Folder />
                    </IconButton>
                    {d.title}
                  </TableCell>
                  <TableCell>
                    {d.tag ? (
                      <Stack spacing={1} direction="row">
                        <LabelOutlined />
                        <span>{d.tag}</span>
                      </Stack>
                    ) : (
                      <IconButton onClick={() => edit(d)} size="small">
                        <Add />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>
                    <Switch checked={d.isPublished} onChange={() => toggle(d)} size="small" />
                  </TableCell>

                  <TableCell align="right" className="tr-show-items">
                    <IconButton size="small" onClick={() => swap(d, prev)} disabled={!prev}>
                      <ArrowUpward />
                    </IconButton>
                    <IconButton size="small" onClick={() => swap(d, next)} disabled={!next}>
                      <ArrowDownward />
                    </IconButton>
                    <IconButton size="small" onClick={() => edit(d)} color="primary">
                      <EditOutlined />
                    </IconButton>
                    <IconButton size="small" onClick={() => remove(d)} color="error">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Empty show={!docs.length} />
      </TableContainer>
    </Layout>
  );
};

export default ManageDocs;
