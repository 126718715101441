import { RestAPI } from './rest';

const isDev = !!window.location.host.match(/^(localhost|127\.|192\.)/);
const backendUrl = isDev ? 'http://192.168.1.223:8004' : 'https://api.tubyx.com';
const wsUrl = isDev ? 'ws://localhost:8080' : 'wss://wss.tubyx.com';
// const frontendUrl = isDev ? 'http://localhost:3004' : 'https://cp.tubyx.com';
// const memberUrl = isDev ? 'http://192.168.1.144:3000' : 'https://app.tubyx.com';

const API = new RestAPI(backendUrl, isDev);
API.setHeadersHandler((headers) => {
  headers['Accept-Language'] = 'en';
});
export { API, isDev, backendUrl, wsUrl };
