import Layout from '../../components/layout';
import { observer, useLocalObservable } from 'mobx-react';
import { formatSize, formatTime, PagerState } from '../../modules/utils';
import { EFieldGroup, EVideoContentCategory, IGetVideoContentRequest, IVideoContent } from '../../modules/rest';
import cache from '../../modules/cache';
import Pager from '../../components/pager';
import React, { useCallback, useEffect, useState } from 'react';
import { API } from '../../modules/api';
import { runInAction } from 'mobx';
import { toast } from 'react-toastify';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { stringAvatar } from '../../modules/string-avatar';
import {
  CloudTwoTone,
  CopyAll,
  DeleteOutline,
  Download,
  Edit,
  MoreVertOutlined,
  Search,
  Upload,
} from '@mui/icons-material';
import { blue, green } from '@mui/material/colors';
import Empty from '../../components/empty';
import { openAddContentDialog } from './upload';
import { confirmDialog } from '../../components/dialogs';

const Media = observer(() => {
  const state = useLocalObservable<PagerState<IVideoContent, IGetVideoContentRequest>>(() => ({
    loading: true,
    pager: cache.get('vc.pager'),
    request: cache.get('vc.request') ?? { limit: 20 },
  }));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentVC, setCurrentVC] = useState<IVideoContent | null>(null);

  const openMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>, vc: IVideoContent) => {
      setAnchorEl(event.currentTarget);
      setCurrentVC(vc);
    },
    [setAnchorEl, setCurrentVC]
  );

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
    setCurrentVC(null);
  }, [setAnchorEl, setCurrentVC]);

  const copyToClipboard = useCallback((vc: IVideoContent) => {
    navigator.clipboard
      .writeText(vc.media.url)
      .then(() => {
        toast.success('Copied to clipboard!');
      })
      .catch(toast.error);
  }, []);

  const download = useCallback((vc: IVideoContent) => {
    window.location.href = vc.media.url;
  }, []);

  const fetch = useCallback(() => {
    runInAction(() => (state.loading = true));
    API.VideoContent.getList(state.request, [EFieldGroup.AssetS3])
      .then((pager) =>
        runInAction(() => {
          state.pager = pager;
          state.request.page = pager.page;
          state.request.limit = pager.limit;
          cache.set('vc.pager', state.pager);
          cache.set('vc.request', state.request);
        })
      )
      .catch(toast.error)
      .finally(() => runInAction(() => (state.loading = false)));
  }, [state]);

  const remove = useCallback(
    (vc: IVideoContent) => {
      confirmDialog(`Are you sure to delete "${vc.media.name}"?`, {
        confirmColor: 'error',
        confirmText: 'Delete',
        title: 'Media deletion #' + vc.id,
      }).then((agree) => {
        if (!agree) return false;
        runInAction(() => (state.loading = true));
        API.VideoContent.deleteItem(vc.id)
          .then(() => {
            toast.success('Media deleted');
            return fetch();
          })
          .catch(toast.error)
          .finally(() => runInAction(() => (state.loading = false)));
      });
    },
    [fetch, state]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Layout
      title="Media content"
      loading={state.loading}
      actions={
        <Button
          variant="contained"
          startIcon={<Upload />}
          onClick={() => openAddContentDialog().then((r) => r && fetch())}
        >
          Upload
        </Button>
      }
      header={
        <Paper>
          <Box p={2}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <TextField
                placeholder="Search by title or assignors"
                value={state.request.query || ''}
                onChange={(e) => runInAction(() => (state.request.query = e.target.value || undefined))}
                size="small"
                onKeyDown={(e) => e.key === 'Enter' && fetch()}
                sx={{ flexGrow: 1 }}
              />
              <TextField
                select
                size="small"
                value={state.request.category || 0}
                onChange={(e) =>
                  runInAction(() => (state.request.category = (e.target.value as EVideoContentCategory) || undefined))
                }
              >
                <MenuItem value={0}>All media</MenuItem>
                {Object.entries(EVideoContentCategory).map(([name, id]) => (
                  <MenuItem key={id} value={id}>
                    {name}s
                  </MenuItem>
                ))}
              </TextField>
              <Button
                onClick={() =>
                  runInAction(() => {
                    state.request.page = 1;
                    return fetch();
                  })
                }
                startIcon={<Search />}
                variant="contained"
              >
                Search
              </Button>
            </Stack>
          </Box>
        </Paper>
      }
      footer={<Pager request={state.request} pager={state.pager} onChange={fetch} />}
    >
      {state.pager ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Media</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Assignors</TableCell>
                <TableCell>Track</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Status</TableCell>
                <TableCell width={40} />
              </TableRow>
            </TableHead>
            <TableBody>
              {state.pager.data.map((vc) => (
                <TableRow key={vc.id}>
                  <TableCell>{vc.id}</TableCell>
                  <TableCell>
                    <div>{vc.media.name}</div>
                    <small className="text-muted">{vc.media.mime}</small>
                  </TableCell>
                  <TableCell sx={{ textTransform: 'capitalize' }}>{vc.category}</TableCell>
                  <TableCell>
                    <AvatarGroup sx={{ justifyContent: 'start' }}>
                      {vc.assignors.map((a: string) => (
                        <Avatar title={a} key={a} {...stringAvatar(a)} />
                      ))}
                    </AvatarGroup>
                  </TableCell>

                  <TableCell>{vc.track ?? '-'}</TableCell>
                  <TableCell>{formatTime(vc.duration)}</TableCell>
                  <TableCell>{formatSize(vc.media.size)}</TableCell>
                  <TableCell>
                    {!vc.media.isS3 ? (
                      <Stack direction="row" spacing={1} alignItems="center" sx={{ color: blue[500] }}>
                        <CircularProgress size={20} />
                        <span>Uploading</span>
                      </Stack>
                    ) : (
                      <Stack direction="row" spacing={1} alignItems="center" sx={{ color: green[500] }}>
                        <CloudTwoTone />
                        <span>Stored</span>
                      </Stack>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => openMenu(e, vc)}>
                      <MoreVertOutlined />
                    </IconButton>
                    {currentVC === vc && (
                      <Menu open={true} anchorEl={anchorEl} onClose={closeMenu} onClick={closeMenu}>
                        <MenuItem onClick={() => download(vc)}>
                          <ListItemIcon>
                            <Download fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Download</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => copyToClipboard(vc)}>
                          <ListItemIcon>
                            <CopyAll fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Copy download url</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem disabled>
                          <ListItemIcon>
                            <Edit fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Edit</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => remove(vc)}>
                          <ListItemIcon>
                            <DeleteOutline fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Delete</ListItemText>
                        </MenuItem>
                      </Menu>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Empty show={state.pager.count === 0} color="warning" text="Video content not found" />
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
    </Layout>
  );
});

export default Media;
