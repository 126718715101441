import { observer, useLocalObservable } from 'mobx-react';
import { download, downloadTrackMp3, downloadTrackWav, formatTime, PagerState, thumb } from '../../modules/utils';
import {
  EContentIdStatus,
  EDistributor,
  EFieldGroup,
  EReviewStatus,
  IAlbum,
  IGetTracksRequest,
  ITrack,
} from '../../modules/rest';
import cache from '../../modules/cache';
import {
  Avatar,
  AvatarGroup,
  Badge,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { runInAction } from 'mobx';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import Pager from '../../components/pager';
import Empty from '../../components/empty';
import Track from '../../components/track';
import { useNavigate } from 'react-router-dom';
import Editable from '../../components/editable';
import {
  Add,
  Bolt,
  Close,
  Download,
  Edit,
  LinkSharp,
  MoreVertOutlined,
  Search,
  Videocam,
  VolumeUp,
} from '@mui/icons-material';
import Layout from '../../components/layout';
import { editAlbumLinksDialog } from './album-links';
import { openMonetizationDialog } from './monetization';
import { openEditTrackDialog } from './edit-track';
import { confirmDialog } from '../../components/dialogs';
import User from '../../components/user';
import Player from '../../components/player';
import { waitForTask } from '../../components/dialogs/tasks';
import { purple } from '@mui/material/colors';

const TracksList = observer(() => {
  const state = useLocalObservable<PagerState<ITrack, IGetTracksRequest>>(() => ({
    loading: true,
    pager: cache.get('tracks.pager'),
    request: cache.get('tracks.request') ?? { limit: 20 },
  }));

  const [selection, setSelection] = useState<ITrack[]>(cache.get('tracks.selection') ?? []);

  const navigate = useNavigate();

  const toggleSelection = useCallback(
    (track: ITrack) => {
      const newSelection = selection.map((t) => t.id).includes(track.id)
        ? selection.filter((t) => t.id !== track.id)
        : [...selection, track];
      setSelection(newSelection);
      cache.set('tracks.selection', newSelection);
    },
    [selection, setSelection]
  );

  const fetch = useCallback(() => {
    runInAction(() => (state.loading = true));
    return API.Tracks.getTracksList(state.request, [
      EFieldGroup.TrackStatus,
      EFieldGroup.TrackIsrc,
      EFieldGroup.TrackAlbum,
      EFieldGroup.TrackFile,
      EFieldGroup.TrackSample,
      EFieldGroup.TrackVideoClaim,
      EFieldGroup.AlbumLinks,
      EFieldGroup.AlbumBatch,
      EFieldGroup.BatchDistributor,
      EFieldGroup.BatchScheme,
      EFieldGroup.TrackPerformer,
      EFieldGroup.PerformerUser,
      EFieldGroup.TrackScheme,
      EFieldGroup.TrackDistributor,
      EFieldGroup.TrackDuration,
    ])
      .then((res) =>
        runInAction(() => {
          state.pager = res;
          state.request.page = res.page;
          state.request.limit = res.limit;
          cache.set('tracks.pager', state.pager);
          cache.set('tracks.request', state.request);
        })
      )
      .catch(toast.error)
      .finally(() => runInAction(() => (state.loading = false)));
  }, [state]);

  const setIsrc = useCallback(
    (track: ITrack, isrc: string | null) => {
      const prevIsrc = track.isrc;
      runInAction(() => {
        track.isrc = isrc;
        state.loading = true;
      });
      API.Tracks.updateTrack(track.id, { isrc }, [EFieldGroup.TrackIsrc])
        .then((res) => {
          runInAction(() => (track.isrc = res.isrc));
        })
        .catch((e) => {
          toast.error(e.includes('Duplicate') ? 'ISRC already used' : e);
          runInAction(() => (track.isrc = prevIsrc));
        })
        .finally(() => runInAction(() => (state.loading = false)));
    },
    [state]
  );

  const adminDraft = useCallback(
    (track: ITrack) => {
      confirmDialog(`Are you sure to draft track «${track.title}»?`, {
        confirmText: 'Draft',
      }).then((agree) => {
        if (!agree) return;
        runInAction(() => (state.loading = true));
        API.Tracks.adminDraftTrack(track.id)
          .then(() => {
            toast.success('Track drafted');
            return fetch();
          })
          .catch(toast.error)
          .finally(() => runInAction(() => (state.loading = false)));
      });
    },
    [fetch, state]
  );

  const adminReview = useCallback(
    (track: ITrack) => {
      confirmDialog(`Are you sure to send to review track «${track.title}»?`, {
        confirmText: 'Send to review',
      }).then((agree) => {
        if (!agree) return;
        runInAction(() => (state.loading = true));
        API.Tracks.adminReviewTrack(track.id)
          .then(() => {
            toast.success('Track sent to review');
            return fetch();
          })
          .catch(toast.error)
          .finally(() => runInAction(() => (state.loading = false)));
      });
    },
    [fetch, state]
  );

  const editAlbumLinks = useCallback(
    (a: IAlbum) => {
      editAlbumLinksDialog(a).then(fetch);
    },
    [fetch]
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentTrack, setCurrentTrack] = useState<ITrack | null>(null);

  const openMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>, t: ITrack) => {
      setAnchorEl(event.currentTarget);
      setCurrentTrack(t);
    },
    [setAnchorEl, setCurrentTrack]
  );

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
    setCurrentTrack(null);
  }, [setAnchorEl, setCurrentTrack]);

  const downloadCovers = useCallback(() => {
    API.Tasks.exportTracks({ ids: selection.map((t) => t.id), exportCovers: true })
      .then((task) => waitForTask(task, 'Preparing covers for download'))
      .then((res) => {
        if (res) {
          toast.info('Downloading...', { autoClose: 1000 });
          download(res.zip!);
        }
      });
  }, [selection]);

  const downloadAudio = useCallback(() => {
    API.Tasks.exportTracks({ ids: selection.map((t) => t.id), exportMp3: true })
      .then((task) => waitForTask(task, 'Preparing audio for download'))
      .then((res) => {
        if (res) {
          toast.info('Downloading...', { autoClose: 1000 });
          download(res.zip!);
        }
      });
  }, [selection]);

  useEffect(() => {
    fetch().then();
  }, [fetch]);

  return (
    <Layout
      loading={state.loading}
      title="Tracks"
      header={
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
          {selection.length > 0 && (
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <IconButton
                size="small"
                onClick={() => {
                  setSelection([]);
                  cache.remove('tracks.selection');
                }}
              >
                <Close />
              </IconButton>

              <span className="text-muted">
                {selection.length} selected track{selection.length > 1 ? 's' : ''}
              </span>

              <AvatarGroup max={5} className="selection__group">
                {selection.map((t) => (
                  <Avatar
                    key={t.id}
                    src={t.album?.cover ? thumb(t.album.cover.id, 60) : undefined}
                    sx={{ width: 30, height: 30, fontSize: 10, background: purple[300] }}
                  >
                    {t.id}
                  </Avatar>
                ))}
              </AvatarGroup>

              <Button size="small" startIcon={<Download />} onClick={downloadCovers}>
                Covers
              </Button>
              <Button size="small" startIcon={<Download />} onClick={downloadAudio}>
                Audio
              </Button>
            </Stack>
          )}
        </Stack>
      }
      actions={
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextField
            placeholder="Search by title, artist, album, ISRC..."
            value={state.request.query || ''}
            onChange={(e) => runInAction(() => (state.request.query = e.target.value || undefined))}
            size="small"
            onKeyDown={(e) => e.key === 'Enter' && fetch()}
            sx={{ flexGrow: 1 }}
          />

          <TextField
            select
            size="small"
            label="Distributor"
            sx={{ width: 150 }}
            value={state.request.distributor ?? 0}
            onChange={(e) =>
              runInAction(() => (state.request.distributor = (e.target.value as EDistributor) || undefined))
            }
          >
            <MenuItem value={0}>Any</MenuItem>
            {Object.entries(EDistributor).map(([name, value]) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            size="small"
            label="Review status"
            sx={{ width: 150 }}
            value={state.request.status ?? 0}
            onChange={(e) => runInAction(() => (state.request.status = (e.target.value as EReviewStatus) || undefined))}
          >
            <MenuItem value={0}>Any</MenuItem>
            {Object.entries(EReviewStatus).map(([name, value]) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            size="small"
            label="Audio monetization"
            sx={{ width: 150 }}
            value={state.request.audioStatus ?? 0}
            onChange={(e) =>
              runInAction(() => (state.request.audioStatus = (e.target.value as EContentIdStatus) || undefined))
            }
          >
            <MenuItem value={0}>Any</MenuItem>
            {Object.entries(EContentIdStatus).map(([name, value]) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            size="small"
            label="Video monetization"
            sx={{ width: 150 }}
            value={state.request.videoStatus ?? 0}
            onChange={(e) =>
              runInAction(() => (state.request.videoStatus = (e.target.value as EContentIdStatus) || undefined))
            }
          >
            <MenuItem value={0}>Any</MenuItem>
            {Object.entries(EContentIdStatus).map(([name, value]) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </TextField>

          <FormControlLabel
            checked={state.request.hasIsrc === false}
            onChange={(_, checked) => runInAction(() => (state.request.hasIsrc = checked ? false : undefined))}
            control={<Checkbox />}
            label="No ISRC"
          />
          <Button
            onClick={() =>
              runInAction(() => {
                state.request.page = 1;
                return fetch();
              })
            }
            startIcon={<Search />}
            variant="contained"
          >
            Search
          </Button>
        </Stack>
      }
      footer={<Pager pager={state.pager} request={state.request} onChange={fetch} />}
    >
      {state.pager ? (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell width={30}>ID</TableCell>
                  <TableCell>Track</TableCell>
                  <TableCell>Account</TableCell>
                  <TableCell>Distributor</TableCell>
                  <TableCell width={100}>Status</TableCell>
                  <TableCell width={200}>Links</TableCell>
                  <TableCell width={200}>ISRC</TableCell>
                  <TableCell width={120}>Monetization</TableCell>
                  <TableCell width={1} />
                </TableRow>
              </TableHead>
              <TableBody>
                {state.pager.data.map((track) => (
                  <TableRow key={track.id}>
                    <TableCell>
                      <Checkbox
                        checked={selection.map((t) => t.id).includes(track.id)}
                        onChange={() => toggleSelection(track)}
                      />
                    </TableCell>
                    <TableCell>{track.id}</TableCell>
                    <TableCell>
                      <Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2}>
                        <Track track={track} onClick={() => navigate(`/tracks/${track.id}/`)} />
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <small className="text-muted">{formatTime(track.duration!)}</small>
                          <Player track={track} />
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <User user={track.performer!.user!} />
                    </TableCell>
                    <TableCell>
                      <div>{track.distributor}</div>
                      <div className="text-muted small">{track.scheme}</div>
                    </TableCell>
                    <TableCell
                      sx={{ textTransform: 'capitalize' }}
                      className={`review-status review-status-${track.status}`}
                    >
                      {track.status}
                    </TableCell>
                    <TableCell>
                      {track.album?.links?.array.length === 0 ? (
                        <IconButton size="small" onClick={() => editAlbumLinks(track.album!)}>
                          <Add />
                        </IconButton>
                      ) : (
                        <Badge badgeContent={track.album?.links?.array?.length.toString()} color="secondary">
                          <IconButton size="small" onClick={() => editAlbumLinks(track.album!)}>
                            <LinkSharp />
                          </IconButton>
                        </Badge>
                      )}
                    </TableCell>
                    <TableCell>
                      <Editable
                        value={track.isrc!}
                        onChange={(isrc) => setIsrc(track, isrc)}
                        textFieldProps={{ placeholder: 'BRBMG0300729' }}
                      />
                    </TableCell>

                    <TableCell>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        {/*{track.au === EMonetizationStatus.None && <span>None</span>}*/}

                        <VolumeUp className={`cid-status__${track.audioStatus}`} />
                        <Videocam className={`cid-status__${track.videoStatus}`} />

                        <IconButton
                          size="small"
                          onClick={() => openMonetizationDialog(track).then((res) => res && fetch())}
                        >
                          <Edit />
                        </IconButton>
                      </Stack>
                    </TableCell>

                    <TableCell>
                      <IconButton onClick={(e) => openMenu(e, track)}>
                        <MoreVertOutlined />
                      </IconButton>
                      {currentTrack === track && (
                        <Menu open={true} anchorEl={anchorEl} onClose={closeMenu} onClick={closeMenu}>
                          <MenuItem onClick={() => openEditTrackDialog(track).then((res) => res && fetch())}>
                            <ListItemIcon>
                              <Edit fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Edit track</ListItemText>
                          </MenuItem>

                          {track.status !== EReviewStatus.Draft && (
                            <MenuItem onClick={() => adminDraft(track)}>
                              <ListItemIcon>
                                <Bolt fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Draft track</ListItemText>
                            </MenuItem>
                          )}

                          {track.status !== EReviewStatus.Review && (
                            <MenuItem onClick={() => adminReview(track)}>
                              <ListItemIcon>
                                <Bolt fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Review track</ListItemText>
                            </MenuItem>
                          )}

                          <Divider />
                          <MenuItem onClick={() => downloadTrackWav(track)}>
                            <ListItemIcon>
                              <Download fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>WAV</ListItemText>
                          </MenuItem>
                          <MenuItem onClick={() => downloadTrackMp3(track)}>
                            <ListItemIcon>
                              <Download fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>MP3</ListItemText>
                          </MenuItem>
                        </Menu>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Empty show={!state.pager.data.length} />
          </TableContainer>
        </>
      ) : (
        <CircularProgress />
      )}
    </Layout>
  );
});

export default TracksList;
