import React, { useCallback, useEffect, useState } from 'react';
import { EFieldGroup, ETrackReviewStatus, IAlbum, ITrack } from '../../../modules/rest';
import { API } from '../../../modules/api';
import { toast } from 'react-toastify';
import { Button, Checkbox, Chip, CircularProgress, Stack } from '@mui/material';
import Track from '../../../components/track';
import Loadable from '../../../components/loadable';
import { ArrowRight, CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@mui/icons-material';
import Empty from '../../../components/empty';
import session from '../../../modules/session';

const scope = [EFieldGroup.TrackAlbum, EFieldGroup.AlbumPerformer];

const ContentIdSelect = ({ setStep }: { setStep(step: number): void }) => {
  const [tracks, setTracks] = useState<ITrack[]>();
  const [albums, setAlbums] = useState<IAlbum[]>();
  const [loading, setLoading] = useState(true);
  const [selection, setSelection] = useState<number[]>([]);

  const fetch = useCallback(() => {
    setLoading(true);
    API.Tracks.getTracksList({ limit: 1000, reviewStatus: ETrackReviewStatus.Init }, scope)
      .then((res) => {
        const albums: IAlbum[] = [];
        res.data.forEach((t) => {
          if (!albums.find((a) => a.id === t.album?.id)) albums.push(t.album!);
        });
        setAlbums(albums);
        setTracks(res.data);
      })
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setAlbums, setTracks, setLoading]);

  const changeSelection = useCallback(
    (album: IAlbum, checked: boolean) => {
      if (checked) {
        setSelection([...selection, album.id]);
      } else {
        setSelection(selection.filter((id) => id !== album.id));
      }
    },
    [selection, setSelection]
  );

  const proceed = useCallback(() => {
    const ids = tracks!.filter((t) => selection.includes(t.album!.id)).map((t) => t.id);
    setLoading(true);
    API.ReviewTracks.proceedContentId({ ids })
      .then(() => setStep(1))
      .catch(toast.error)
      .finally(() => {
        setLoading(false);
        return session.fetch();
      });
  }, [tracks, selection, setStep]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Loadable loading={loading}>
      {albums ? (
        <Stack spacing={2}>
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              startIcon={<CheckBoxOutlined />}
              onClick={() => setSelection(albums!.map((a) => a.id))}
              disabled={selection.length === albums.length}
            >
              Select all
            </Button>
            <Button
              size="small"
              startIcon={<CheckBoxOutlineBlankOutlined />}
              onClick={() => setSelection([])}
              disabled={!selection.length}
            >
              Select none
            </Button>
          </Stack>
          {albums.map((album) => (
            <div key={album.id}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Checkbox
                  checked={selection.includes(album.id)}
                  onChange={(_, checked) => changeSelection(album, checked)}
                />
                <h4>
                  {album.title} ({album.performer?.stageName})
                </h4>
                {album.isSingle ? (
                  <Chip size="small" variant="outlined" label="Single" />
                ) : (
                  <Chip size="small" variant="outlined" label={`${album.tracksCount} tracks`} />
                )}
              </Stack>

              {tracks ? (
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: 20 }}>
                  {tracks
                    .filter((t) => t.album!.id === album.id)
                    .map((track) => (
                      <Stack
                        direction="row"
                        alignItems="center"
                        key={track.id}
                        style={{ minWidth: 250, marginTop: 10 }}
                        spacing={1}
                      >
                        <Stack direction="row" spacing={1} alignItems="center">
                          <small className="text-muted">#{track.id}</small>
                          <Track track={track} />
                        </Stack>
                      </Stack>
                    ))}
                </div>
              ) : (
                <CircularProgress />
              )}
            </div>
          ))}
          <Empty show={!!tracks && tracks.length === 0} />
          <Button
            onClick={proceed}
            disabled={!selection.length}
            variant="contained"
            endIcon={<ArrowRight />}
            sx={{ alignSelf: 'start' }}
          >
            Proceed
          </Button>
        </Stack>
      ) : (
        <CircularProgress />
      )}
    </Loadable>
  );
};

export default ContentIdSelect;
