import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { API } from '../../modules/api';
import { EFieldGroup, ITrack } from '../../modules/rest';
import { Box, Button, Card, CardContent, CircularProgress, IconButton, Stack } from '@mui/material';
import Track from '../../components/track';
import Layout from '../../components/layout';
import { ArrowLeft, Edit } from '@mui/icons-material';
import { openEditTrackDialog } from './edit-track';
import { openEditAlbumDialog } from './edit-album';

const TracksItem = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [track, setTrack] = useState<ITrack>();

  const fetchTrack = useCallback(() => {
    API.Tracks.getTrack(params.id!, [EFieldGroup.TrackAlbum, EFieldGroup.TrackEdit, EFieldGroup.TrackIsrc]).then(
      setTrack
    );
  }, [params.id, setTrack]);

  useEffect(() => {
    fetchTrack();
  }, [params.id, fetchTrack]);

  return (
    <Layout
      title={`Track #${params.id}`}
      breadcrumbs={
        <Stack direction="row" spacing={1}>
          <IconButton onClick={() => navigate('../', { replace: true })}>
            <ArrowLeft />
          </IconButton>
          {track && <Track track={track} />}
        </Stack>
      }
    >
      <Box p={1}>
        {track ? (
          <Card>
            <CardContent>
              <p>ISRC: {track.isrc || 'NOT SET'}</p>
              <Stack direction="row">
                <Button
                  startIcon={<Edit />}
                  onClick={() => openEditTrackDialog(track).then((res) => res && fetchTrack())}
                >
                  Edit track
                </Button>

                {!track.album?.isSingle && (
                  <Button
                    onClick={() => openEditAlbumDialog(track.album!).then((res) => res && fetchTrack())}
                    startIcon={<Edit />}
                  >
                    Edit album
                  </Button>
                )}
              </Stack>
            </CardContent>
          </Card>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Layout>
  );
};

export default TracksItem;
