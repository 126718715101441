import Layout from '../../components/layout';
import { observer, useLocalObservable } from 'mobx-react';
import Pager from '../../components/pager';
import { PagerState, thumb } from '../../modules/utils';
import { EFieldGroup, IGetMonitoringChannelsRequest, IMonitoringChannel } from '../../modules/rest';
import { useCallback, useEffect } from 'react';
import { runInAction } from 'mobx';
import { API } from '../../modules/api';
import cache from '../../modules/cache';
import { toast } from 'react-toastify';
import {
  Avatar,
  Button,
  Chip,
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { openCreateMonitoringChannelDialog } from './dialogs';
import Empty from '../../components/empty';
import { confirmDialog } from '../../components/dialogs';
import MonitoringNav from './nav';

const MonitoringChannels = observer(() => {
  const state = useLocalObservable<PagerState<IMonitoringChannel, IGetMonitoringChannelsRequest>>(() => ({
    loading: true,
    pager: cache.get('monitoring.channels.pager'),
    request: cache.get('monitoring.channels.request') ?? { scope: 'global' },
  }));

  const fetch = useCallback(() => {
    runInAction(() => (state.loading = true));
    API.Monitoring.getChannels(state.request, [EFieldGroup.MonitoringCategories])
      .then((res) =>
        runInAction(() => {
          state.pager = res;
          state.request.page = res.page;
          state.request.limit = res.limit;
          cache.set('monitoring.channels.pager', state.pager);
          cache.set('monitoring.channels.request', state.request);
        })
      )
      .catch(toast.error)
      .finally(() => runInAction(() => (state.loading = false)));
  }, [state]);

  const deleteChannel = useCallback(
    (c: IMonitoringChannel) => {
      confirmDialog(`Are you sure to delete channel ${c.channel.name} from monitoring?`, {
        confirmColor: 'error',
        confirmText: 'Delete',
      }).then((agree) => {
        if (agree) {
          runInAction(() => (state.loading = true));
          API.Monitoring.deleteChannel(c.id)
            .then(fetch)
            .catch(toast.error)
            .finally(() => runInAction(() => (state.loading = false)));
        }
      });
    },
    [fetch, state]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Layout
      title="Channels"
      loading={state.loading}
      actions={
        <Stack direction="row" spacing={4} alignItems="center">
          <MonitoringNav value="channels" />
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => openCreateMonitoringChannelDialog().then((res) => res && fetch())}
          >
            Create
          </Button>
        </Stack>
      }
      footer={<Pager request={state.request} onChange={fetch} pager={state.pager} />}
    >
      {state.pager && (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={50}>ID</TableCell>
                  <TableCell>Channel</TableCell>
                  <TableCell>Categories</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {state.pager.data.map((c) => (
                  <TableRow key={c.id}>
                    <TableCell>{c.id}</TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Avatar src={c.channel.icon ? thumb(c.channel.icon.id, 80) : ''} />
                        <Stack sx={{ lineHeight: '120%' }}>
                          <div style={{ fontWeight: 500 }}>{c.channel.name}</div>
                          <Link href={c.channel.url} target="_blank">
                            <small>@{c.channel.handle}</small>
                          </Link>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack spacing={0.5} direction="row">
                        {c.categories!.map((cat) => (
                          <Chip key={cat.id} label={cat.name} size="small" variant="outlined" />
                        ))}
                      </Stack>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton size="small" color="error" onClick={() => deleteChannel(c)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Empty show={!state.pager.count} />
          </TableContainer>
        </>
      )}
    </Layout>
  );
});

export default MonitoringChannels;
