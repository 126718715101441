import { EContentIdStatus, ITrack } from '../modules/rest';
import { Avatar, Stack, Typography } from '@mui/material';
import { thumb } from '../modules/utils';
import { purple } from '@mui/material/colors';
import { MusicNote, Videocam, VolumeUp } from '@mui/icons-material';
import React from 'react';

const Track = ({
  track,
  onClick,
  showAvatar = true,
  showIcons = false,
}: {
  track: ITrack;
  onClick?(): void;
  showAvatar?: boolean;
  showIcons?: boolean;
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="start"
      onClick={onClick}
      className={onClick ? 'cursor-pointer' : ''}
      display="inline-flex"
    >
      <Stack alignItems="center">
        {showAvatar && (
          <Avatar
            src={track.album?.cover ? thumb(track.album?.cover.id, 80) : undefined}
            sx={{ background: purple[300], fontSize: 12 }}
            variant="rounded"
          >
            {track.id ?? <MusicNote />}
          </Avatar>
        )}
        {showIcons && (
          <Typography variant="caption" color="gray">
            {track.audioStatus === EContentIdStatus.Active && <VolumeUp sx={{ width: 16 }} />}
            {track.videoStatus === EContentIdStatus.Active && track.videoClaim && <Videocam sx={{ width: 16 }} />}
          </Typography>
        )}
      </Stack>

      <Stack>
        <Typography variant="body2">{track.title}</Typography>
        <Typography variant="caption" color="gray">
          {track.performer?.stageName}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Track;
