import { Chip, Stack, TextField } from '@mui/material';
import { useCallback } from 'react';

const Tags = ({
  value,
  label = 'Add tag',
  size = 'small',
  onChange,
}: {
  value: string[];
  label?: string;
  size?: 'small' | 'medium';
  onChange(value: string[]): void;
}) => {
  const remove = useCallback((tag: string) => onChange(value.filter((item) => item !== tag)), [value, onChange]);

  const add = useCallback(
    (e: any) => {
      if (e.code === 'Enter') {
        e.preventDefault();
        const item = e.target.value.replaceAll('#', '').trim();
        if (item.length > 0 && !value.includes(item)) {
          onChange([...value, item]);
        }
        e.target.value = '';
      }
    },
    [value, onChange]
  );

  return (
    <Stack spacing={1}>
      <TextField onKeyDown={add} label={label} size={size} placeholder="Press Enter to add" />
      <Stack direction="row" spacing={1}>
        {value.map((tag) => (
          <Chip key={tag} label={tag} variant="outlined" size="small" onDelete={() => remove(tag)} />
        ))}
      </Stack>
    </Stack>
  );
};

export default Tags;
