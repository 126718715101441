import Layout from '../../components/layout';
import { observer, useLocalObservable } from 'mobx-react';
import { PagerState } from '../../modules/utils';
import {
  EFieldGroup,
  EWithdrawStatus,
  IAsset,
  IGetWithdrawHistoryRequest,
  IWithdraw,
  IWithdrawMethodInfo,
} from '../../modules/rest';
import { FormEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import { runInAction } from 'mobx';
import { API } from '../../modules/api';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import Empty from '../../components/empty';
import { toast } from 'react-toastify';
import User from '../../components/user';
import { AccessTime, Bolt, Check, DeleteOutlined, Visibility, WarningOutlined } from '@mui/icons-material';
import moment from 'moment';
import { openDialog, rejectDialog } from '../../components/dialogs';
import Loadable from '../../components/loadable';
import { CopyToClipboard, CopyToClipboardText } from '../../components/copy-to-clipboard';
import InputFile from '../../components/input-file';

const scope = [EFieldGroup.WithdrawUser, EFieldGroup.WithdrawDetails];

const WithdrawList = observer(() => {
  const st = useLocalObservable<PagerState<IWithdraw, IGetWithdrawHistoryRequest>>(() => ({
    loading: true,
    request: { limit: 1000, viewAsAdmin: true },
  }));

  const fetch = useCallback(() => {
    runInAction(() => (st.loading = true));
    API.Withdraw.getHistory(st.request, scope)
      .then((res) =>
        runInAction(() => {
          st.pager = res;
          st.request.page = res.page;
          st.request.limit = res.limit;
        })
      )
      .catch(toast.error)
      .finally(() => (st.loading = false));
  }, [st]);

  const view = useCallback(
    (w: IWithdraw) => {
      return openWithdrawDialog(w).then(fetch);
    },
    [fetch]
  );

  const process = useCallback(
    (w: IWithdraw) => {
      if (w.status === EWithdrawStatus.Processing) {
        return view(w);
      } else {
        return API.Withdraw.process(w.id, scope)
          .then((w) => view(w))
          .catch(toast.error);
      }
    },
    [view]
  );

  const cancel = useCallback(
    (w: IWithdraw) => {
      rejectDialog('Are you sure to cancel payment request?', {
        rejectText: 'Cancel request',
        title: 'Payment request cancelation',
        labelText: 'Reason',
      }).then((comment) => {
        if (comment) {
          API.Withdraw.cancel(w.id, { comment }).then(fetch).catch(toast.error);
        }
      });
    },
    [fetch]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Layout title="Payments" loading={st.loading}>
      {st.pager && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Method</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell>Request date</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {st.pager.data.map((w) => (
                <TableRow>
                  <TableCell>{w.id}</TableCell>
                  <TableCell>
                    <User user={w.user!} />
                  </TableCell>
                  <TableCell>{w.method}</TableCell>
                  <TableCell>{w.title}</TableCell>
                  <TableCell align="right">
                    {w.amount.toFixed(2)} <small className="text-muted">USD</small>
                  </TableCell>
                  <TableCell>
                    <Stack alignItems="center">
                      <Status status={w.status} />
                      <small className="text-muted">{moment(w.updatedAt).fromNow()}</small>
                    </Stack>
                  </TableCell>
                  <TableCell>{moment(w.createdAt).format('DD.MM.YYYY, HH:mm')}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1} justifyContent="end">
                      <IconButton size="small" title="View details" onClick={() => view(w)}>
                        <Visibility />
                      </IconButton>
                      {[EWithdrawStatus.Pending, EWithdrawStatus.Processing].includes(w.status) && (
                        <IconButton size="small" title="Process" onClick={() => process(w)}>
                          <Bolt />
                        </IconButton>
                      )}
                      {[EWithdrawStatus.Pending, EWithdrawStatus.Failed].includes(w.status) && (
                        <IconButton size="small" title="Cancel" onClick={() => cancel(w)}>
                          <DeleteOutlined />
                        </IconButton>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Empty show={st.pager.data.length === 0} />
        </TableContainer>
      )}
    </Layout>
  );
});

const openFinishWithdrawDialog = (w: IWithdraw) =>
  openDialog((resolve) => {
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState<string | null>(null);
    const [attachment, setAttachment] = useState<IAsset | null>(null);
    const submit = useCallback(
      (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        API.Withdraw.finish(w.id, { comment, attachment: attachment?.id ?? null })
          .then(() => resolve(true))
          .catch(toast.error)
          .finally(() => setLoading(false));
      },
      [setLoading, comment, attachment]
    );
    return (
      <Loadable loading={loading}>
        <form onSubmit={submit}>
          <DialogTitle>Confirm payout request #{w.id}</DialogTitle>
          <DialogContent>
            <Stack mt={1} spacing={2} alignItems="start">
              <p>
                Are you sure to confirm payment? Please specify payment details or/and attach confirmation document
                (receipt or screenshot from payment system)
              </p>
              <TextField
                fullWidth
                label="Comment or payment details"
                value={comment ?? ''}
                onChange={(e) => setComment(e.target.value || null)}
              />
              <InputFile value={attachment} onChange={setAttachment} text="Attach document" />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              startIcon={<Check />}
              color="success"
              variant="contained"
              disabled={!comment && !attachment}
              type="submit"
            >
              Confirm
            </Button>

            <Button variant="contained" onClick={() => resolve(null)}>
              Close
            </Button>
          </DialogActions>
        </form>
      </Loadable>
    );
  });

const openWithdrawDialog = (w: IWithdraw) =>
  openDialog((resolve) => {
    const [method, setMethod] = useState<IWithdrawMethodInfo>();

    useEffect(() => {
      API.Withdraw.getMethod(w.method).then(setMethod);
    }, [setMethod]);

    const confirm = useCallback(() => {
      openFinishWithdrawDialog(w).then((res) => res && resolve(true));
    }, [resolve]);

    const cancel = useCallback(() => {
      rejectDialog('Are you sure to cancel payment request?', {
        rejectText: 'Cancel request',
        title: 'Payment request cancelation',
        labelText: 'Reason',
      }).then((comment) => {
        if (comment) {
          API.Withdraw.cancel(w.id, { comment })
            .then(() => resolve(true))
            .catch(toast.error);
        }
      });
    }, [resolve]);

    if (!method) return <CircularProgress />;

    return (
      <>
        <DialogTitle>
          Payout request #{w.id} ({moment(w.createdAt).format('DD.MM.YYYY')})
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <span>Status:</span>
              <Status status={w.status} />
            </Stack>
            <div>
              Method: <b>{w.method}</b>
            </div>
            <div>
              <Stack direction="row" spacing={1}>
                <span>Amount:</span>
                <b>
                  <span>{w.amount.toFixed(2)}</span>
                  <small style={{ marginLeft: 5 }}>USD</small>
                </b>
                <CopyToClipboard text={w.amount.toFixed(2)} />
              </Stack>
            </div>
            {method.details.map((p) => (
              <Stack direction="row" spacing={1}>
                <div>{p.name}:</div>
                <b>
                  <CopyToClipboardText>{w.details![p.id]}</CopyToClipboardText>
                </b>
              </Stack>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          {w.status === EWithdrawStatus.Processing && (
            <Button startIcon={<Check />} color="success" variant="contained" onClick={confirm}>
              Confirm
            </Button>
          )}
          {[EWithdrawStatus.Processing, EWithdrawStatus.Failed, EWithdrawStatus.Pending].includes(w.status) && (
            <Button startIcon={<DeleteOutlined />} color="error" variant="contained" onClick={cancel}>
              Cancel request
            </Button>
          )}
          <Button variant="contained" onClick={() => resolve(null)}>
            Close
          </Button>
        </DialogActions>
      </>
    );
  });

const Status = ({ status }: { status: EWithdrawStatus }) => {
  const states: Record<EWithdrawStatus, { icon: ReactElement; color: string; name: string }> = {
    canceled: { color: 'gray', icon: <DeleteOutlined />, name: 'Canceled' },
    failed: { color: 'red', icon: <WarningOutlined />, name: 'Failed' },
    finished: { color: 'green', icon: <Check />, name: 'Finished' },
    pending: { color: 'orange', icon: <AccessTime />, name: 'Pending' },
    processing: { color: 'navy', icon: <Bolt />, name: 'Processing' },
  };
  return (
    <Stack direction="row" sx={{ color: states[status].color }} spacing={0.2} alignItems="center">
      {states[status].icon}
      <span style={{ fontWeight: '500' }}>{states[status].name}</span>
    </Stack>
  );
};

export default WithdrawList;
