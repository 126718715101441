import Layout from '../../components/layout';
import { IMonitoringCategory } from '../../modules/rest';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../../modules/api';
import cache from '../../modules/cache';
import { toast } from 'react-toastify';
import {
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { openCreateMonitoringCategoryDialog } from './dialogs';
import Empty from '../../components/empty';
import { confirmDialog } from '../../components/dialogs';
import MonitoringNav from './nav';
import Editable from '../../components/editable';

const MonitoringCategories = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState(cache.get<IMonitoringCategory[]>('monitoring.categories'));

  const fetch = useCallback(() => {
    setLoading(true);
    API.Monitoring.getCategories()
      .then(setCategories)
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setLoading, setCategories]);

  const deleteChannel = useCallback(
    (c: IMonitoringCategory) => {
      confirmDialog(`Are you sure to delete category ${c.name}?`, {
        confirmColor: 'error',
        confirmText: 'Delete',
      }).then((agree) => {
        if (agree) {
          setLoading(true);
          API.Monitoring.deleteCategory(c.id)
            .then(fetch)
            .catch(toast.error)
            .finally(() => setLoading(false));
        }
      });
    },
    [fetch]
  );

  const renameCategory = useCallback(
    (c: IMonitoringCategory, name: string) => {
      setLoading(true);
      API.Monitoring.updateCategory(c.id, { name })
        .then(fetch)
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [setLoading, fetch]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Layout
      title="Categories"
      loading={loading}
      actions={
        <Stack direction="row" spacing={4} alignItems="center">
          <MonitoringNav value="categories" />
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => openCreateMonitoringCategoryDialog().then((res) => res && fetch())}
          >
            Create{' '}
          </Button>
        </Stack>
      }
    >
      {categories && (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={50}>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((c) => (
                  <TableRow key={c.id}>
                    <TableCell>{c.id}</TableCell>
                    <TableCell>
                      <Editable value={c.name} onChange={(name) => renameCategory(c, name!)} />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton size="small" color="error" onClick={() => deleteChannel(c)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Empty show={!categories.length} />
          </TableContainer>
        </>
      )}
    </Layout>
  );
};

export default MonitoringCategories;
