import { EReviewStatus } from '../modules/rest';
import { Box, Chip } from '@mui/material';
import { Check, Edit, RemoveCircleOutline, TimerOutlined } from '@mui/icons-material';
import React from 'react';

const ReviewStatus = ({ status, onClick }: { status: EReviewStatus; onClick?(): void }) => (
  <Box onClick={onClick} display="inline-flex">
    {status === EReviewStatus.Draft && (
      <Chip label="Draft" color="default" variant="outlined" size="small" icon={<Edit />} />
    )}
    {status === EReviewStatus.Review && (
      <Chip label="In review" color="warning" variant="filled" size="small" icon={<TimerOutlined />} />
    )}
    {status === EReviewStatus.Approve && (
      <Chip label="Approved" color="success" variant="outlined" size="small" icon={<Check />} />
    )}
    {status === EReviewStatus.Reject && (
      <Chip label="Rejected" color="error" variant="outlined" size="small" icon={<RemoveCircleOutline />} />
    )}
  </Box>
);

export default ReviewStatus;
