import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import VideoEmbedsStats from './video-embeds';

const StatsRoutes = () => {
  return (
    <Routes>
      <Route path="/video-embeds/" element={<VideoEmbedsStats />} />
      <Route path="*" element={<Navigate to="/stats/video-embeds/" replace />} />
    </Routes>
  );
};

export default StatsRoutes;
