import { Navigate, Route, Routes } from 'react-router-dom';
import LibraryList from './list';

const LibraryRoutes = () => (
  <Routes>
    <Route path="/" element={<LibraryList />} />
    <Route path="*" element={<Navigate to="/library/" replace />} />
  </Routes>
);

export default LibraryRoutes;
