import { useCallback, useEffect, useState } from 'react';
import { EFieldGroup, ETrackReviewStatus, ITrack } from '../../../modules/rest';
import { API } from '../../../modules/api';
import { toast } from 'react-toastify';
import { Box, Button, CircularProgress, IconButton, Stack } from '@mui/material';
import Track from '../../../components/track';
import { Delete, Videocam } from '@mui/icons-material';
import Loadable from '../../../components/loadable';
import Empty from '../../../components/empty';
import { waitForTask } from '../../../components/dialogs/tasks';
import { download } from '../../../modules/utils';

const scope = [EFieldGroup.TrackAlbum, EFieldGroup.AlbumPerformer];

const ContentIdVideo = ({ setStep }: { setStep(step: number): void }) => {
  const [tracks, setTracks] = useState<ITrack[]>();
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(() => {
    setLoading(true);
    API.Tracks.getTracksList({ reviewStatus: ETrackReviewStatus.ContentID, limit: 1000 }, scope)
      .then((res) => setTracks(res.data))
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setTracks, setLoading]);

  const resetStatus = useCallback(
    (track: ITrack) => {
      setLoading(true);
      API.ReviewTracks.setReviewStatus(track.id, { reviewStatus: ETrackReviewStatus.Init })
        .then(fetch)
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [setLoading, fetch]
  );

  const proceed = useCallback(() => {
    API.ReviewTracks.proceedContentIdVideo()
      .then(waitForTask)
      .then((task) => {
        if (task) {
          download(task.video!);
          setStep(2);
        }
      });
  }, [setStep]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Loadable loading={loading} minHeight={0}>
      {tracks ? (
        <Stack spacing={2}>
          {tracks.map((track) => (
            <Stack key={track.id} direction="row" spacing={2} alignItems="center">
              <small className="text-muted">#{track.id}</small>
              <Track track={track} />
              <Box flexGrow={1} />
              <IconButton onClick={() => resetStatus(track)}>
                <Delete />
              </IconButton>
            </Stack>
          ))}
          <Empty show={tracks && tracks.length === 0} />
          <Button
            disabled={!tracks.length}
            startIcon={<Videocam />}
            variant="contained"
            sx={{ alignSelf: 'start' }}
            onClick={proceed}
          >
            Generate video
          </Button>
        </Stack>
      ) : (
        <CircularProgress />
      )}
    </Loadable>
  );
};

export default ContentIdVideo;
