import Layout from '../../../components/layout';
import { useState } from 'react';
import { Badge, Card, CardContent, Step, StepButton, Stepper } from '@mui/material';
import ContentIdSelect from './content-id-select';
import ContentIdVideo from './content-id-video';
import ContentIdResult from './content-id-result';
import NavTabs from './tabs';
import session from '../../../modules/session';
import { observer } from 'mobx-react';

const ContentId = observer(() => {
  const [step, setStep] = useState(0);
  return (
    <Layout
      title="Review Content ID"
      breadcrumbs={
        <Stepper nonLinear activeStep={step} sx={{ my: 1 }}>
          <Step completed={step > 0}>
            <Badge badgeContent={session.badges?.reviewTracksInit} color="error">
              <StepButton onClick={() => setStep(0)}>Tracks selection</StepButton>
            </Badge>
          </Step>
          <Step completed={step > 1}>
            <Badge badgeContent={session.badges?.reviewTracksContentId} color="error">
              <StepButton onClick={() => setStep(1)}>Generate video</StepButton>
            </Badge>
          </Step>
          <Step>
            <Badge badgeContent={session.badges?.reviewTracksContentId} color="error">
              <StepButton onClick={() => setStep(2)}>Save results</StepButton>
            </Badge>
          </Step>
        </Stepper>
      }
      actions={<NavTabs value={0} />}
    >
      <Card sx={{ mb: 2 }}>
        <CardContent>
          {step === 0 && <ContentIdSelect setStep={setStep} />}
          {step === 1 && <ContentIdVideo setStep={setStep} />}
          {step === 2 && <ContentIdResult />}
        </CardContent>
      </Card>
    </Layout>
  );
});

export default ContentId;
