import { ECurrency } from '../modules/rest';
import { MenuItem, TextField } from '@mui/material';
import { FC } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type Props = {
  value: ECurrency | undefined | null;
  onChange(value: ECurrency | null): any;
  label?: string;
  required?: boolean;
  size?: 'small' | 'medium';
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
};

const InputCurrency: FC<Props> = ({ value, onChange, label, required, size, sx, fullWidth }) => (
  <TextField
    sx={sx}
    label={label ?? 'Currency'}
    size={size}
    required={required}
    value={value || 0}
    onChange={(e) => onChange(e.target.value as ECurrency) || null}
    select
    fullWidth={fullWidth}
  >
    <MenuItem value={0}>Not set</MenuItem>
    {Object.entries(ECurrency).map(([id, name]) => (
      <MenuItem key={id} value={id}>
        {name}
      </MenuItem>
    ))}
  </TextField>
);

export default InputCurrency;
