import packageJson from '../package.alias.json';
import { makeAutoObservable, runInAction } from 'mobx';
import { API } from './api';
import { toast } from 'react-toastify';
import { Button, Stack, Typography } from '@mui/material';
import { Refresh } from '@mui/icons-material';

class Version {
  readonly current: string = packageJson.version;
  latest?: string;
  upgrade: boolean = false;
  private name: string;

  constructor(name: string) {
    this.name = name;
    makeAutoObservable(this);
  }

  init = (): void => {
    setInterval(this.check, 60000);
    this.check();
  };

  check = (): void => {
    API.Main.checkVersion(this.name, this.current).then((res) => {
      runInAction(() => {
        this.latest = res.version;
        this.upgrade = res.upgrade;
      });
      if (res.upgrade) {
        toast(
          <>
            <Stack>
              <Typography variant="subtitle2">Update available</Typography>
              <Typography variant="body2">Reload page to start using new version</Typography>
              <Typography variant="caption" color="gray" mb={1}>
                Version: {res.version}
              </Typography>
              <Button onClick={() => window.location.reload()} size="small" startIcon={<Refresh />} variant="outlined">
                Reload page
              </Button>
            </Stack>
          </>,
          {
            position: 'bottom-right',
            toastId: 'version',
            autoClose: false,
          }
        );
      }
    });
  };
}

const version = new Version('admin');

export default version;
