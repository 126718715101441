import { makeAutoObservable, runInAction } from 'mobx';
import { EFieldGroup, EUserRole, IAdminBadges, IUser } from './rest';
import { API } from './api';
import version from './version';
import { isGranted } from './utils';

class Session {
  public user: IUser | null = null;
  public badges: IAdminBadges | null = null;
  public ready: boolean = false;

  constructor() {
    version.init();
    makeAutoObservable(this);
    setInterval(this.fetch, 60000);
    this.fetch().finally(() => runInAction(() => (this.ready = true)));
  }

  fetch = (): Promise<any> => {
    const token = window.localStorage.getItem('token');
    if (token) {
      API.setToken(token);
      return API.Users.getMe([EFieldGroup.UserPermissions])
        .then((user) => runInAction(() => (this.user = user)))
        .then(() => {
          if (isGranted(EUserRole.Admin)) {
            API.Badges.getAdminBadges().then((badges) => runInAction(() => (this.badges = badges)));
          }
        })
        .catch(() => {
          if (API.getStatusCode() === 401) {
            API.setToken(null);
            window.localStorage.removeItem('token');
            runInAction(() => {
              this.user = null;
              this.badges = null;
            });
          }
        });
    } else {
      return Promise.resolve();
    }
  };

  logout = (): void => {
    if (!this.user) return;
    const token = window.localStorage.getItem('fallback_token');
    if (token) {
      window.localStorage.removeItem('fallback_token');
      window.localStorage.setItem('token', token);
      window.location.replace('/accounts/');
    } else {
      window.localStorage.removeItem('token');
      API.setToken(null);
      runInAction(() => {
        this.user = null;
        this.badges = null;
      });
    }
  };
}

const session = new Session();

export default session;
