import Layout from '../../components/layout';
import Empty from '../../components/empty';
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Add, AttachMoney, Bolt } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import { IReport } from '../../modules/rest';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import { addReport } from './add';
import { waitForTask } from '../../components/dialogs/tasks';
import { formatNumber } from '../../modules/utils';

const ReportsList = () => {
  const [reports, setReports] = useState<IReport[]>();
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(() => {
    setLoading(true);
    API.Reports.getList()
      .then(setReports)
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setLoading, setReports]);

  const process = useCallback((r: IReport) => {
    API.Reports.processReport(r.id).then(waitForTask).then(console.log).catch(toast.error);
  }, []);

  const pay = useCallback((r: IReport) => {
    API.Reports.payReport(r.id).then(console.log).catch(toast.error);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Layout
      title="Reports"
      loading={loading}
      actions={
        <Button startIcon={<Add />} variant="contained" onClick={() => addReport().then(fetch)}>
          Add report
        </Button>
      }
    >
      {reports && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Report</TableCell>
                <TableCell>Distributor</TableCell>
                <TableCell>Paid</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Service (7%)</TableCell>
                <TableCell>Performer (70%)</TableCell>
                <TableCell>Youtuber (50%)</TableCell>
                <TableCell>Referral (10%)</TableCell>
                <TableCell>System</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map((r) => (
                <TableRow key={r.id}>
                  <TableCell>{r.title}</TableCell>
                  <TableCell>{r.distributor}</TableCell>
                  <TableCell>{r.isPaid ? 'YES' : 'no'}</TableCell>
                  <TableCell>$ {formatNumber(r.total, 2)}</TableCell>
                  <TableCell>$ {formatNumber(r.service, 2)}</TableCell>
                  <TableCell>$ {formatNumber(r.performer, 2)}</TableCell>
                  <TableCell>$ {formatNumber(r.youtuber, 2)}</TableCell>
                  <TableCell>$ {formatNumber(r.referral, 2)}</TableCell>
                  <TableCell>$ {formatNumber(r.system, 2)}</TableCell>
                  <TableCell>
                    {!r.isPaid && (
                      <IconButton onClick={() => process(r)}>
                        <Bolt />
                      </IconButton>
                    )}
                    {r.isProcessed && !r.isPaid && (
                      <IconButton onClick={() => pay(r)}>
                        <AttachMoney />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Empty show={!reports.length} />
        </TableContainer>
      )}
    </Layout>
  );
};

export default ReportsList;
