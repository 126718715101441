import { Navigate, Route, Routes } from 'react-router-dom';
import WithdrawList from './list';
// import WithdrawItem from './item';

const WithdrawRoutes = () => (
  <Routes>
    <Route path="/" element={<WithdrawList />} />
    {/*<Route path="/:id/" element={<WithdrawItem />} />*/}
    <Route path="*" element={<Navigate to="/withdraw/" replace />} />
  </Routes>
);

export default WithdrawRoutes;
