import { MenuItem, Stack, TextField } from '@mui/material';
import { IGenre } from '../modules/rest';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../modules/api';

const SelectGenre = ({
  genre,
  subgenre,
  onChange,
}: {
  genre?: IGenre | null;
  subgenre?: IGenre | null;
  onChange(genre: IGenre | null, subgenre: IGenre | null): void;
}) => {
  const [genres, setGenres] = useState<IGenre[]>();
  const [subgenres, setSubgenres] = useState<IGenre[]>();

  const changeGenre = useCallback(
    (g: IGenre | null) => {
      setSubgenres(undefined);
      onChange(g, null);
      if (g) {
        API.Genres.getSubgenres(g.id).then(setSubgenres);
      }
    },
    [onChange, setSubgenres]
  );

  useEffect(() => {
    API.Genres.getGenres().then(setGenres);
    if (genre) {
      API.Genres.getSubgenres(genre.id).then(setSubgenres);
    } else {
      setSubgenres(undefined);
    }
  }, [setGenres, genre]);

  return (
    <Stack direction="row" spacing={2}>
      <TextField required label="Genre" value={genre?.id || 0} select fullWidth sx={{ minWidth: 250 }}>
        <MenuItem value={0} onClick={() => changeGenre(null)}>
          Not selected
        </MenuItem>
        {genres?.map((g) => (
          <MenuItem key={g.id} value={g.id} onClick={() => changeGenre(g)}>
            {g.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        required
        label="Subgenre"
        disabled={!genre}
        value={subgenre?.id || 0}
        select
        fullWidth
        sx={{ minWidth: 250 }}
      >
        <MenuItem value={0} onClick={() => onChange(genre!, null)}>
          Not selected
        </MenuItem>
        {subgenres?.map((g) => (
          <MenuItem key={g.id} value={g.id} onClick={() => onChange(genre!, g)}>
            {g.name}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
};
export default SelectGenre;
