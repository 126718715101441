import { openDialog } from '../../components/dialogs';
import { EVideoContentCategory, IAsset, IVideoContent } from '../../modules/rest';
import { Button, Chip, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import Loadable from '../../components/loadable';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import InputFile from '../../components/input-file';
import Tags from '../../components/tags';
import { runInAction } from 'mobx';
import { Add } from '@mui/icons-material';

const openAddContentDialog = () => {
  return openDialog<IVideoContent | null>(
    (resolve) => {
      const [category, setCategory] = useState(EVideoContentCategory.Reaction);
      const [track, setTrack] = useState<string | null>(null);
      const [loading, setLoading] = useState(false);
      const [assignors, setAssignors] = useState<string[]>([]);
      const [media, setMedia] = useState<IAsset | null>(null);
      const [assignorsList, setAssignorsList] = useState<string[]>([]);

      const submit = useCallback(
        (e: FormEvent) => {
          e.preventDefault();
          setLoading(true);
          API.VideoContent.addItem({ category, assignors, mediaAssetId: media?.id!, track })
            .then((res) => {
              toast.success('Video content added');
              resolve(res);
            })
            .catch(toast.error)
            .finally(() => setLoading(false));
        },
        [category, assignors, media, track, setLoading, resolve]
      );

      useEffect(() => {
        API.VideoContent.getAssignors().then(setAssignorsList).catch(toast.error);
      }, [setAssignorsList]);

      return (
        <form onSubmit={submit}>
          <Loadable loading={loading}>
            <DialogTitle>Add new video content</DialogTitle>
            <DialogContent>
              <Stack sx={{ pt: 1 }} spacing={2} alignItems="stretch">
                <TextField
                  label="Category"
                  required
                  select
                  value={category}
                  onChange={(e) => runInAction(() => setCategory(e.target.value as EVideoContentCategory))}
                >
                  {Object.entries(EVideoContentCategory).map(([name, id]) => (
                    <MenuItem key={id} value={id}>
                      {name}s
                    </MenuItem>
                  ))}
                </TextField>
                <TextField label="Track" value={track || ''} onChange={(e) => setTrack(e.target.value || null)} />
                <Tags value={assignors} size="medium" label="Assignors" onChange={(v) => setAssignors(v)} />
                <Stack direction="row" spacing={1}>
                  {assignorsList.map((a) =>
                    assignors.includes(a) ? null : (
                      <Chip
                        label={a}
                        key={a}
                        size="small"
                        icon={<Add />}
                        onClick={() => setAssignors([...assignors, a])}
                      />
                    )
                  )}
                </Stack>

                <InputFile value={media} onChange={(m) => setMedia(m)} accept="video/*" deletable={false} />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button type="submit" disabled={!media}>
                Add content
              </Button>
              <Button onClick={() => resolve(null)}>Cancel</Button>
            </DialogActions>
          </Loadable>
        </form>
      );
    },
    { fullWidth: true, maxWidth: 'md' }
  );
};

export { openAddContentDialog };
