import { EContentIdStatus, ITrack, IUpdateMonetizationRequest } from '../../modules/rest';
import { openDialog } from '../../components/dialogs';
import { Button, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, Stack, TextField } from '@mui/material';
import React, { FormEvent, useCallback, useState } from 'react';
import Track from '../../components/track';
import Loadable from '../../components/loadable';
import InputFile from '../../components/input-file';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';

const openMonetizationDialog = (track: ITrack) => {
  return openDialog<ITrack | null>(
    (resolve) => {
      const [loading, setLoading] = useState(false);

      const [request, setRequest] = useState<IUpdateMonetizationRequest>({
        audioStatus: track.audioStatus,
        videoStatus: track.videoStatus,
        isrc: track.isrc,
        videoClaimId: track.videoClaim?.id || null,
      });

      const submit = useCallback(
        (e: FormEvent) => {
          e.preventDefault();
          setLoading(true);
          API.Tracks.updateTrackMonetization(track.id, request)
            .then((res) => {
              resolve(res);
              toast.success('Monetization settings saved');
            })
            .catch(toast.error)
            .finally(() => setLoading(false));
        },
        [setLoading, request, resolve]
      );

      return (
        <Loadable loading={loading}>
          <form onSubmit={submit}>
            <DialogTitle>Monetization settings</DialogTitle>
            <DialogContent>
              <Track track={track} />
              <Stack spacing={2} sx={{ mt: 3 }}>
                <TextField
                  select
                  required={true}
                  label="Audio monetization"
                  value={request.audioStatus}
                  onChange={(e) => setRequest({ ...request, audioStatus: e.target.value as EContentIdStatus })}
                >
                  {Object.entries(EContentIdStatus).map(([name, value]) => (
                    <MenuItem key={value} value={value}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>

                {request.audioStatus !== EContentIdStatus.None && (
                  <TextField
                    label="ISRC"
                    required={request.audioStatus === EContentIdStatus.Active}
                    placeholder="BRBMG0300728"
                    value={request.isrc || ''}
                    onChange={(e) => setRequest({ ...request, isrc: e.target.value || null })}
                  />
                )}

                <Divider />
                <TextField
                  select
                  required
                  label="Video monetization"
                  value={request.videoStatus}
                  onChange={(e) => setRequest({ ...request, videoStatus: e.target.value as EContentIdStatus })}
                >
                  {Object.entries(EContentIdStatus).map(([name, value]) => (
                    <MenuItem key={value} value={value}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>

                {request.videoStatus !== EContentIdStatus.None && (
                  <div className="d-flex flex-column align-items-start">
                    <small className="text-muted">Video *</small>
                    <InputFile
                      accept=".mp4, .mkv"
                      value={request.videoClaimId || null}
                      onChange={(value) => setRequest({ ...request, videoClaimId: value?.id || null })}
                    />
                  </div>
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button type="submit">Save</Button>
              <Button onClick={() => resolve(null)}>Cancel</Button>
            </DialogActions>
          </form>
        </Loadable>
      );
    },
    { fullWidth: true, maxWidth: 'xs' }
  );
};

export { openMonetizationDialog };
