import { Route, Routes } from 'react-router-dom';
import AccountsRoutes from './accounts';
import ReviewRoutes from './review';
import BatchesRoutes from './batches';
import TracksRoutes from './tracks';
import DocsRoutes from './docs';
import { hasPermission } from '../modules/utils';
import { EPermission } from '../modules/rest';
import Media from './media';
import SettingsRoutes from './settings';
import UploadRoutes from './upload';
import ReviewTracksRoutes from './review/tracks';
import LibraryRoutes from './library';
import StatsRoutes from './stats';
import AlbumsRoutes from './albums';
import MonitoringRoutes from './monitoring';
import ReportsRoutes from './reports';
import { Stack } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import WithdrawRoutes from './withdraw';

const AppRoutes = () => (
  <Routes>
    {hasPermission(EPermission.ReviewTracks) && <Route path="/review-tracks/*" element={<ReviewTracksRoutes />} />}
    {hasPermission(EPermission.Review) && <Route path="/review/*" element={<ReviewRoutes />} />}
    {hasPermission(EPermission.Tracks) && <Route path="/tracks/*" element={<TracksRoutes />} />}
    {hasPermission(EPermission.Accounts) && <Route path="/accounts/*" element={<AccountsRoutes />} />}
    {hasPermission(EPermission.Batches) && <Route path="/batches/*" element={<BatchesRoutes />} />}
    {hasPermission(EPermission.Upload) && <Route path="/upload/*" element={<UploadRoutes />} />}
    {hasPermission(EPermission.Docs) && <Route path="/docs/*" element={<DocsRoutes />} />}
    {hasPermission(EPermission.Full) && <Route path="/settings/*" element={<SettingsRoutes />} />}
    {hasPermission(EPermission.Library) && <Route path="/library/*" element={<LibraryRoutes />} />}
    {hasPermission(EPermission.Stats) && <Route path="/stats/*" element={<StatsRoutes />} />}
    {hasPermission(EPermission.Reports) && <Route path="/reports/*" element={<ReportsRoutes />} />}
    {hasPermission(EPermission.Monitoring) && <Route path="/monitoring/*" element={<MonitoringRoutes />} />}
    {hasPermission(EPermission.Media) && <Route path="/media/*" element={<Media />} />}
    {hasPermission(EPermission.Albums) && <Route path="/albums/*" element={<AlbumsRoutes />} />}
    {hasPermission(EPermission.Finances) && <Route path="/withdraw/*" element={<WithdrawRoutes />} />}
    <Route
      path="*"
      element={
        <Stack direction="row" p={2} alignItems="center" spacing={1}>
          <KeyboardArrowLeft />
          <span>Select section</span>
        </Stack>
      }
    />
  </Routes>
);

export default AppRoutes;
