import { IMonitoringCategory } from '../../modules/rest';
import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { API } from '../../modules/api';

const SelectCategories = ({
  values,
  onChange,
}: {
  values: IMonitoringCategory[];
  onChange(value: IMonitoringCategory[]): void;
}) => {
  const [categories, setCategories] = useState<IMonitoringCategory[]>();

  useEffect(() => {
    API.Monitoring.getCategories().then(setCategories);
  }, [setCategories]);

  if (!categories) return null;

  return (
    <Autocomplete
      multiple
      value={values}
      onChange={(_, v) => onChange(v)}
      options={categories}
      getOptionLabel={(c) => c.name}
      renderInput={(params) => <TextField {...params} label="Categories *" />}
    />
  );
};

export default SelectCategories;
