import { Avatar, Box, Button, CircularProgress, Container, CssBaseline, TextField, Typography } from '@mui/material';
import React, { FormEvent, useCallback, useState } from 'react';
import { API } from './modules/api';
import session from './modules/session';
import { toast } from 'react-toastify';
import { LockOutlined } from '@mui/icons-material';
import { runInAction } from 'mobx';
import { EFieldGroup } from './modules/rest';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const login = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Users.login({ email, password }, [EFieldGroup.UserPermissions])
        .then((res) => {
          API.setToken(res.token);
          localStorage.setItem('token', res.token);
          runInAction(() => (session.user = res.user));
          return session.fetch();
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [email, password]
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Control Panel
        </Typography>
        <Box component="form" onSubmit={login} noValidate sx={{ mt: 1 }}>
          <TextField
            disabled={loading}
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            disabled={loading}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginForm;
