import {
  Badge,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { download, PagerState } from '../../modules/utils';
import { EFieldGroup, IBatch, IGetBatchesRequest } from '../../modules/rest';
import cache from '../../modules/cache';
import { runInAction } from 'mobx';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import Pager from '../../components/pager';
import Empty from '../../components/empty';
import { AddCircle, Check, CheckCircleOutline, Close, Download } from '@mui/icons-material';
import { openCreateBatchDialog } from './create';
import Layout from '../../components/layout';
import User from '../../components/user';
import { confirmDialog, openDialog } from '../../components/dialogs';
import SignDialog from './sign';
import PayDialog from './pay';
import session from '../../modules/session';
import SelectCompany from '../../components/select-company';
import { Link } from 'react-router-dom';

const BatchesList = observer(() => {
  const state = useLocalObservable<PagerState<IBatch, IGetBatchesRequest>>(() => ({
    loading: true,
    pager: cache.get('batches.pager'),
    request: cache.get('batches.request') ?? {
      page: 1,
      limit: 50,
      preset: session.badges?.unsignedBatches ? 'unsigned' : session.badges?.unpaidBatches ? 'unpaid' : null,
    },
  }));

  const fetch = useCallback(() => {
    runInAction(() => (state.loading = true));
    API.Batches.getBatchesList(state.request, [
      EFieldGroup.BatchPerformer,
      EFieldGroup.BatchCompany,
      EFieldGroup.BatchScheme,
      EFieldGroup.BatchDistributor,
      EFieldGroup.BatchCost,
      EFieldGroup.BatchContract,
      EFieldGroup.BatchTransaction,
      EFieldGroup.BatchTracksCount,
      EFieldGroup.PerformerUser,
    ])
      .then((pager) =>
        runInAction(() => {
          state.pager = pager;
          state.request.page = pager.page;
          state.request.limit = pager.limit;
          cache.set('batches.pager', state.pager);
          cache.set('batches.request', state.request);
        })
      )
      .catch(toast.error)
      .finally(() => runInAction(() => (state.loading = false)));
  }, [state]);

  const create = useCallback(() => {
    openCreateBatchDialog().then((res) => res && fetch());
  }, [fetch]);

  const chargeBatch = useCallback(
    (batch: IBatch) => {
      confirmDialog(`Are you sure to charge user balance with $${batch.cost?.toFixed(2)}?`).then((agree) => {
        if (!agree) return;
        runInAction(() => (state.loading = true));
        API.Batches.chargeBatch(batch.id)
          .then(fetch)
          .catch(toast.error)
          .finally(() => runInAction(() => (state.loading = false)));
      });
    },
    [state, fetch]
  );

  const payoutBatch = useCallback(
    (batch: IBatch) => {
      openDialog((resolve) => <PayDialog batch={batch} resolve={resolve} />, { fullWidth: true, maxWidth: 'sm' }).then(
        fetch
      );
    },
    [fetch]
  );

  const deleteBatch = useCallback(
    (batch: IBatch) => {
      confirmDialog(`Are you sure to delete batch ${batch.tag} and rollback billing?`, {
        title: 'Confirm delete',
        confirmColor: 'error',
        confirmText: 'Delete',
      }).then((agree) => {
        if (!agree) return;
        runInAction(() => (state.loading = true));
        API.Batches.deleteBatch(batch.id)
          .then(fetch)
          .catch(toast.error)
          .finally(() => runInAction(() => (state.loading = false)));
      });
    },
    [state, fetch]
  );

  const rejectContract = useCallback(
    (batch: IBatch) => {
      confirmDialog(`Are you sure to reject performer's signed contract?`, {
        title: 'Confirm rejection',
        confirmColor: 'error',
        confirmText: 'Reject',
      }).then((agree) => {
        if (!agree) return;
        runInAction(() => (state.loading = true));
        API.Batches.rejectOneBatch(batch.id)
          .then(fetch)
          .catch(toast.error)
          .finally(() => runInAction(() => (state.loading = false)));
      });
    },
    [state, fetch]
  );

  const signBatch = useCallback(
    (batch: IBatch) => {
      openDialog((resolve) => <SignDialog batch={batch} resolve={resolve} />, { fullWidth: true, maxWidth: 'sm' }).then(
        fetch
      );
    },
    [fetch]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Layout
      title="Batches"
      loading={state.loading}
      actions={
        <Stack direction="row" alignItems="center" spacing={2}>
          <SelectCompany
            size="small"
            company={state.request.companyId}
            onChange={(c) =>
              runInAction(() => {
                state.request.companyId = c?.id || undefined;
                fetch();
              })
            }
          />
          <Tabs
            sx={{ mr: 3 }}
            value={state.request.preset}
            onChange={(_, value) =>
              runInAction(() => {
                state.request.preset = value;
                state.request.page = 1;
                fetch();
              })
            }
          >
            <Tab label="All" value={null} />
            <Tab
              label={
                <Badge color="error" badgeContent={session.badges?.unsignedBatches} sx={{ pr: 1 }}>
                  Unsigned
                </Badge>
              }
              value="unsigned"
            />
            <Tab
              label={
                <Badge color="error" badgeContent={session.badges?.unpaidBatches} sx={{ pr: 1 }}>
                  Unpaid
                </Badge>
              }
              value="unpaid"
            />
            <Tab label="Finished" value="finished" />
          </Tabs>

          <Button startIcon={<AddCircle />} onClick={create}>
            Create batch
          </Button>
        </Stack>
      }
      footer={<Pager pager={state.pager} request={state.request} onChange={fetch} />}
    >
      {state.pager ? (
        <>
          <Paper>
            <Table className="table-hover">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Performer</TableCell>
                  <TableCell>Account</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>Scheme</TableCell>
                  <TableCell>Distributor</TableCell>
                  <TableCell>Contract</TableCell>
                  <TableCell>Payment</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {state.pager.data.map((batch) => (
                  <TableRow key={batch.id}>
                    <TableCell>
                      <Link className="link" to={`/batches/${batch.id}`}>
                        {batch.tag}
                      </Link>
                    </TableCell>
                    <TableCell>{batch.performer?.stageName}</TableCell>
                    <TableCell>
                      <User user={batch.performer!.user!} />
                    </TableCell>
                    <TableCell>{batch.company?.name}</TableCell>
                    <TableCell>{batch.scheme}</TableCell>
                    <TableCell>{batch.distributor}</TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1} display="inline-flex" alignItems="center">
                        {batch.contractTwo ? (
                          <>
                            <Chip
                              label="Signed"
                              size="small"
                              variant="outlined"
                              color="success"
                              icon={<CheckCircleOutline />}
                            />
                            <IconButton
                              title="Download signed contract"
                              size="small"
                              onClick={() => download(batch.contractTwo!)}
                            >
                              <Download />
                            </IconButton>
                          </>
                        ) : batch.contractOne ? (
                          <>
                            <Chip label="Signed by performer" size="small" variant="outlined" color="primary" />
                            <IconButton
                              title="Download signed by performer contract"
                              size="small"
                              onClick={() => download(batch.contractOne!)}
                            >
                              <Download />
                            </IconButton>
                            <IconButton title="Sign contract" size="small" onClick={() => signBatch(batch)}>
                              <Check />
                            </IconButton>
                            <IconButton title="Reject contract" size="small" onClick={() => rejectContract(batch)}>
                              <Close />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <Chip label="Unsigned" size="small" variant="outlined" color="default" />
                            <IconButton
                              title="Download blank"
                              size="small"
                              onClick={() => download(batch.contractBlank!)}
                            >
                              <Download />
                            </IconButton>
                          </>
                        )}
                      </Stack>
                    </TableCell>

                    <TableCell>
                      <Stack direction="row" spacing={1} alignItems="center">
                        {batch.payoutTransaction ? (
                          <Stack direction="row" className="color-success" alignItems="center" spacing={1}>
                            <CheckCircleOutline />
                            <span>$ {batch.cost?.toFixed(2)}</span>
                          </Stack>
                        ) : (
                          <span>$ {batch.cost?.toFixed(2)}</span>
                        )}

                        {!batch.isPaid && batch.contractTwo && (
                          <Button size="small" variant="contained" onClick={() => chargeBatch(batch)}>
                            Charge
                          </Button>
                        )}
                        {batch.isPaid && !batch.payoutTransaction && (
                          <Button size="small" variant="contained" onClick={() => payoutBatch(batch)}>
                            Payout
                          </Button>
                        )}
                      </Stack>
                    </TableCell>

                    <TableCell align="right">
                      {!batch.payoutTransaction && (
                        <Button size="small" color="error" onClick={() => deleteBatch(batch)}>
                          Delete
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Empty show={!state.pager.data.length} />
          </Paper>
        </>
      ) : (
        <CircularProgress />
      )}
    </Layout>
  );
});

export default BatchesList;
