import { Navigate, Route, Routes } from 'react-router-dom';
import Settings from './settings';

const SettingsRoutes = () => (
  <Routes>
    <Route path="/" element={<Settings />} />
    <Route path="*" element={<Navigate to="/settings/" replace />} />
  </Routes>
);

export default SettingsRoutes;
