import { EFieldGroup, ICreateDocRequest, IDoc, IUpdateDocRequest } from '../../modules/rest';
import { openDialog } from '../../components/dialogs';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
} from '@mui/material';
import { FormEvent, useCallback, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { API, backendUrl } from '../../modules/api';
import Loadable from '../../components/loadable';
import { toast } from 'react-toastify';

const ComposeDocumentDialog = ({
  doc,
  parent,
  resolve,
}: {
  doc?: IDoc;
  parent?: IDoc | null;
  resolve(doc: IDoc | null): void;
}) => {
  const [request, setRequest] = useState<ICreateDocRequest | IUpdateDocRequest>({
    parentId: doc?.parentId ?? parent?.id ?? null,
    title: doc?.title ?? '',
    content: doc?.content ?? null,
    isPublished: doc?.isPublished ?? false,
    tag: doc?.tag ?? null,
    position: doc?.position,
  });
  const [loading, setLoading] = useState(false);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      const promise = doc
        ? API.Docs.update(doc.id, request as IUpdateDocRequest)
        : API.Docs.create(request as ICreateDocRequest);
      promise
        .then((d) => {
          toast.success(doc ? 'Document updated' : 'Document created');
          resolve(d);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [setLoading, request, resolve, doc]
  );

  return (
    <form onSubmit={submit}>
      <Loadable loading={loading}>
        <DialogTitle>{doc ? 'Edit document' : 'Create document'}</DialogTitle>
        <DialogContent>
          <Stack mt={1} spacing={1}>
            <Stack mb={1} spacing={1} direction="row">
              <TextField
                fullWidth
                label="Title"
                required
                value={request.title}
                autoFocus
                onChange={(e) => setRequest({ ...request, title: e.target.value })}
                sx={{ width: '75%' }}
              />
              <TextField
                fullWidth
                label="Tag"
                value={request.tag || ''}
                placeholder="tubyx.faq"
                onChange={(e) => setRequest({ ...request, tag: e.target.value || null })}
                sx={{ width: '25%' }}
              />
            </Stack>
            <Editor
              value={request.content || ''}
              onEditorChange={(content) => setRequest({ ...request, content })}
              apiKey="zy0l565my45ll6eiu8eyaziusoc9s7eigo19c3kus9zum5pa"
              init={{
                height: 500,
                //menubar: false,
                plugins: 'code image link codesample table charmap pagebreak nonbreaking advlist lists',
                toolbar:
                  'undo redo | styles | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | code',
                language: 'en',
                //language_url: '/assets/editor_ru.js',
                relative_urls: false,
                remove_script_host: false,
                // content_style: 'body { font-family:Gilroy,Arial,sans-serif; font-size:16px }',
                paste_data_images: true,
                images_upload_url: `${backendUrl}/assets/upload/tiny`,
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={request.isPublished}
                  onChange={(e) => setRequest({ ...request, isPublished: e.target.checked })}
                />
              }
              label="Publicate document"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="submit">{doc ? 'Update' : 'Create'}</Button>
          <Button onClick={() => resolve(null)}>Close</Button>
        </DialogActions>
      </Loadable>
    </form>
  );
};

const openCreateDocumentDialog = (parent: IDoc | null) =>
  openDialog<IDoc | null>((resolve) => <ComposeDocumentDialog parent={parent} resolve={resolve} />, {
    maxWidth: 'lg',
    fullWidth: true,
    disableEnforceFocus: true,
  });

const openEditDocumentDialog = (doc: IDoc) =>
  API.Docs.getById(doc.id, [EFieldGroup.DocContent, EFieldGroup.DocParentId]).then((doc) =>
    openDialog<IDoc | null>((resolve) => <ComposeDocumentDialog doc={doc} resolve={resolve} />, {
      maxWidth: 'lg',
      fullWidth: true,
      disableEnforceFocus: true,
    })
  );

export { openCreateDocumentDialog, openEditDocumentDialog };
