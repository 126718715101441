import { openDialog } from './dialogs';
import { EFieldGroup, ELibraryItemType, IGetLibraryItemsRequest, ILibraryItem } from '../modules/rest';
import Loadable from './loadable';
import { useCallback, useEffect } from 'react';
import { DialogContent, DialogTitle, Stack, Tab, Tabs } from '@mui/material';
import { API } from '../modules/api';
import { toast } from 'react-toastify';
import Empty from './empty';
import { VideoPreview } from '../routes/library/list';
import Pager from './pager';
import { observer, useLocalObservable } from 'mobx-react';
import { PagerState } from '../modules/utils';
import { runInAction } from 'mobx';

const SelectLibraryItem = observer(
  ({ resolve, request }: { resolve(item: ILibraryItem | null): void; request?: IGetLibraryItemsRequest }) => {
    const state = useLocalObservable<PagerState<ILibraryItem, IGetLibraryItemsRequest>>(() => ({
      request: request ?? { type: ELibraryItemType.School },
      loading: true,
    }));

    const fetch = useCallback(() => {
      runInAction(() => (state.loading = true));
      API.Library.getItemsList(state.request, [
        EFieldGroup.AssetFull,
        EFieldGroup.AssetDuration,
        EFieldGroup.AssetResolution,
      ])
        .then((res) =>
          runInAction(() => {
            state.pager = res;
            state.request.page = res.page;
            state.request.limit = res.limit;
          })
        )
        .catch(toast.error)
        .finally(() => runInAction(() => (state.loading = false)));
    }, [state]);

    useEffect(() => {
      fetch();
    }, [fetch]);

    return (
      <Loadable loading={state.loading}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <span>Library</span>
            <Tabs
              value={state.request.type}
              onChange={(_, value) =>
                runInAction(() => {
                  state.request.type = value;
                  fetch();
                })
              }
            >
              {Object.entries(ELibraryItemType).map(([name, id]) => (
                <Tab label={name} key={id} value={id} />
              ))}
            </Tabs>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ height: 480, width: 1005 }}>
          {state.pager && (
            <>
              <div style={{ height: 440 }}>
                <Stack direction="row" flexWrap="wrap" sx={{ overflowY: 'auto' }}>
                  {state.pager.data.map((item) => (
                    <div key={item.id} onClick={() => resolve(item)} style={{ marginRight: 8, marginBottom: 8 }}>
                      <VideoPreview item={item} noClick={true} />
                    </div>
                  ))}
                </Stack>
              </div>
              <Pager pager={state.pager} onChange={fetch} request={state.request} />
              <Empty show={!state.pager.count} />
            </>
          )}
        </DialogContent>
      </Loadable>
    );
  }
);

const selectLibraryItem = (request?: IGetLibraryItemsRequest) =>
  openDialog<ILibraryItem | null>((resolve) => <SelectLibraryItem request={request} resolve={resolve} />, {
    maxWidth: 'lg',
  });

export { selectLibraryItem };
