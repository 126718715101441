import { observer, useLocalObservable } from 'mobx-react';
import {
  EFieldGroup,
  EReviewStatus,
  ESortOrder,
  EUserRole,
  IGetApplicationsRequest,
  IPerformerApplication,
} from '../../modules/rest';
import cache from '../../modules/cache';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { API } from '../../modules/api';
import { runInAction } from 'mobx';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import {
  ArrowDownward,
  ArrowUpward,
  Bolt,
  Check,
  Close,
  Download,
  FilterAlt,
  Label,
  Warning,
} from '@mui/icons-material';
import { download, PagerState } from '../../modules/utils';
import session from '../../modules/session';
import { confirmDialog, openDialog } from '../../components/dialogs';
import Loadable from '../../components/loadable';
import Pager from '../../components/pager';
import Empty from '../../components/empty';
import Layout from '../../components/layout';
import ReviewNavigation from './nav';
import User from '../../components/user';
import Editable from '../../components/editable';
import InputCountry from '../../components/input-country';
import InputUser from '../../components/input-user';
import { generatePassword, PasswordStrength } from '../../components/password';

const ReviewPerformersApplications = observer(() => {
  const state = useLocalObservable<PagerState<IPerformerApplication, IGetApplicationsRequest>>(() => ({
    loading: true,
    pager: cache.get('applications.performers.pager'),
    request: cache.get('applications.performers.request') ?? {
      page: 1,
      limit: 50,
      status: EReviewStatus.Review,
      order: ESortOrder.DESC,
    },
    manager: undefined,
  }));

  const fetchList = useCallback(() => {
    runInAction(() => (state.loading = true));
    API.Applications.getPerformerApplications(state.request, [
      EFieldGroup.ApplicationUser,
      EFieldGroup.ApplicationManager,
    ])
      .then((pager) =>
        runInAction(() => {
          state.pager = pager;
          state.request.page = pager.page;
          state.request.limit = pager.limit;
          cache.set('applications.performers.pager', state.pager);
          cache.set('applications.performers.request', state.request);
        })
      )
      .catch(toast.error)
      .finally(() => runInAction(() => (state.loading = false)));
  }, [state]);

  const approve = useCallback(
    (a: IPerformerApplication) => {
      openDialog((resolve) => <ApproveDialog application={a} resolve={resolve} />).then(() => {
        return Promise.all([session.fetch(), fetchList()]);
      });
    },
    [fetchList]
  );

  const reject = useCallback(
    (a: IPerformerApplication) => {
      openDialog((resolve) => <RejectDialog application={a} resolve={resolve} />).then(() => {
        return Promise.all([session.fetch(), fetchList()]);
      });
    },
    [fetchList]
  );

  const changeComment = useCallback((a: IPerformerApplication, comment: string | null) => {
    API.Applications.updatePerformerApplication(a.id, { comment })
      .then((res) => {
        runInAction(() => (a.comment = res.comment));
        toast.success('Comment updated');
      })
      .catch(toast.error);
  }, []);

  const becomeManager = useCallback(
    (a: IPerformerApplication) => {
      confirmDialog(`Would you like to be ${a.name}'s manager?`).then((agree) => {
        if (!agree) return;
        runInAction(() => (state.loading = true));
        API.Applications.becomePerformerManager(a.id)
          .then(fetchList)
          .catch(toast.error)
          .finally(() => runInAction(() => (state.loading = false)));
      });
    },
    [state, fetchList]
  );

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <Layout
      loading={state.loading}
      title="Review performers"
      footer={<Pager pager={state.pager} request={state.request} onChange={fetchList} />}
      breadcrumbs={<ReviewNavigation />}
      actions={
        <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
          <TextField
            placeholder="Search"
            size="small"
            value={state.request.query || ''}
            sx={{ width: 300 }}
            onChange={(e) => runInAction(() => (state.request.query = e.target.value || undefined))}
          />
          <InputUser
            value={state.request.manager}
            onChange={(value) =>
              runInAction(() => {
                state.request.manager = value?.id;
              })
            }
            filter={{ role: EUserRole.Admin }}
          />
          <InputCountry
            label="Country"
            value={state.request.country}
            onChange={(value) => runInAction(() => (state.request.country = value ?? undefined))}
            size="small"
          />
          <TextField
            select
            size="small"
            value={state.request.status}
            sx={{ width: 120 }}
            label="Status"
            onChange={(e) => runInAction(() => (state.request.status = e.target.value as EReviewStatus))}
          >
            {Object.entries(EReviewStatus).map(([name, value]) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            size="small"
            value={state.request.order}
            sx={{ width: 170 }}
            label="Sort order"
            onChange={(e) => runInAction(() => (state.request.order = e.target.value as ESortOrder))}
          >
            <MenuItem value={ESortOrder.DESC}>New ones first</MenuItem>
            <MenuItem value={ESortOrder.ASC}>Old ones first</MenuItem>
          </TextField>

          <Button
            size="small"
            variant="contained"
            onClick={() =>
              runInAction(() => {
                state.request.page = 1;
                fetchList();
              })
            }
            startIcon={<FilterAlt />}
          >
            Apply
          </Button>
          <Button
            size="small"
            onClick={() =>
              runInAction(() => {
                state.request = { status: EReviewStatus.Review, order: ESortOrder.DESC };
                fetchList();
              })
            }
            startIcon={<Close />}
          >
            Reset
          </Button>
        </Stack>
      }
    >
      {state.pager ? (
        <>
          {state.pager.data.map((a) => (
            <Card key={a.id} sx={{ marginBottom: 2 }}>
              <CardContent>
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    #{a.id} / {moment(a.createdAt).format('DD.MM.YYYY, HH:mm')}
                  </Typography>

                  {a.duplicate && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{ cursor: 'pointer', zIndex: 100 }}
                      className="color-error"
                      onClick={() =>
                        runInAction(() => {
                          state.request = {
                            query: a.duplicate!.id.toString(),
                            status: a.duplicate!.status,
                            page: 1,
                            limit: state.request.limit,
                          };
                          fetchList();
                        })
                      }
                    >
                      <Warning />
                      <b>Duplicate #{a.duplicate.id}</b>
                      <span>({a.duplicate.status})</span>
                    </Stack>
                  )}
                </Stack>

                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <small className="text-muted small">Name</small>
                    <br /> <b>{a.name}</b>
                  </Grid>
                  <Grid item xs={3}>
                    <small className="text-muted small">E-mail</small>
                    <br /> <b>{a.email}</b>
                  </Grid>
                  <Grid item xs={3}>
                    <small className="text-muted small">Contacts</small>
                    <br /> <b>{a.contacts}</b>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack alignItems="start" spacing={1}>
                      <small className="text-muted small">Info</small>
                      <span>
                        IP: <b>{a.ip || 'N/A'}</b>
                      </span>
                      <span>
                        Country: <b>{a.country?.toUpperCase() ?? 'N/A'}</b>
                      </span>
                      <span>
                        Service: <b>{a.service ?? 'N/A'}</b>
                      </span>
                      <Chip label={a.source || 'N/A'} icon={<Label />} size="small" />
                    </Stack>
                  </Grid>
                </Grid>

                {a.bio && (
                  <div>
                    Bio: <b>{a.bio}</b>
                  </div>
                )}
                {a.questions && (
                  <div>
                    Questions: <b>{a.questions}</b>
                  </div>
                )}
                {a.unpublishedTracks && (
                  <div>
                    Unpublished tracks: <b>{a.unpublishedTracks}</b>
                  </div>
                )}
                {a.originalBeats && (
                  <div>
                    Original beats: <b>{a.originalBeats}</b>
                  </div>
                )}
                <div>
                  Samples:{' '}
                  {a.samples.map((s, idx) => (
                    <Button key={s.id} size="small" startIcon={<Download />} onClick={() => download(s)}>
                      {idx + 1}
                    </Button>
                  ))}
                </div>
                <div></div>
                <div>
                  Status: <b>{a.status}</b>
                </div>
                {a.rejectReason && (
                  <div>
                    Reject reason: <b>{a.rejectReason}</b>
                  </div>
                )}
                <Stack direction="row" spacing={1} alignItems="center" mt={1}>
                  <span>Comment:</span>
                  <b>
                    <Editable value={a.comment} onChange={(comment) => changeComment(a, comment)} />
                  </b>
                </Stack>

                <Stack direction="row" spacing={3} mt={2}>
                  {a.user && (
                    <Stack mt={2} spacing={1}>
                      <small className="text-muted">Account</small>
                      <User user={a.user} />
                    </Stack>
                  )}

                  <Stack spacing={1}>
                    <small className="text-muted">Manager</small>
                    {a.manager ? (
                      <User user={a.manager} />
                    ) : (
                      <Button value="contained" size="small" onClick={() => becomeManager(a)}>
                        Assign yourself
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </CardContent>
              {a.status === EReviewStatus.Review && (
                <CardActions>
                  <Button size="small" onClick={() => approve(a)}>
                    Approve
                  </Button>
                  <Button size="small" onClick={() => reject(a)} color="error">
                    Reject
                  </Button>
                </CardActions>
              )}
            </Card>
          ))}
        </>
      ) : (
        <CircularProgress />
      )}
      <Empty show={state.pager?.data.length === 0} />
    </Layout>
  );
});

const ApproveDialog = ({
  application,
  resolve,
}: {
  application: IPerformerApplication;
  resolve(a: IPerformerApplication | null): void;
}) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string | null>(application.email);
  const [password, setPassword] = useState<string | null>(generatePassword(16));
  const [more, setMore] = useState(false);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Applications.approvePerformerApplication(application.id, { email, password })
        .then((res) => {
          toast.success('Application approved!');
          resolve(res);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [application, setLoading, email, password, resolve]
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit}>
        <DialogTitle>Performer application approval</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to approve this application? A new performer account will be created and activated.
          </DialogContentText>
          {more && (
            <Stack mt={2}>
              <TextField
                margin="dense"
                label="E-mail"
                type="email"
                fullWidth
                variant="outlined"
                value={email || ''}
                onFocus={(e) => e.target.select()}
                onChange={(e) => setEmail(e.target.value || null)}
              />
              <TextField
                margin="dense"
                label="Password"
                type="text"
                fullWidth
                variant="outlined"
                value={password || ''}
                onFocus={(e) => e.target.select()}
                onChange={(e) => setPassword(e.target.value || null)}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setPassword(generatePassword(16))}>
                      <Bolt />
                    </IconButton>
                  ),
                }}
              />
              {password && <PasswordStrength password={password} />}
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button type="button" startIcon={more ? <ArrowUpward /> : <ArrowDownward />} onClick={() => setMore(!more)}>
            {more ? 'Less options' : 'More options'}
          </Button>
          <Button type="submit" startIcon={<Check />}>
            Approve
          </Button>
          <Button type="button" onClick={() => resolve(null)}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Loadable>
  );
};

const RejectDialog = ({
  application,
  resolve,
}: {
  application: IPerformerApplication;
  resolve(a: IPerformerApplication | null): void;
}) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Applications.rejectPerformerApplication(application.id, { reason })
        .then((res) => {
          toast.success('Application rejected!');
          resolve(res);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [application, setLoading, reason, resolve]
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit}>
        <DialogTitle>Performer application rejection</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to reject this application? Specify the reason for rejecting the application. It will be sent
            to {application.email}
          </DialogContentText>

          <Stack mt={2}>
            <TextField
              margin="dense"
              label="Reject reason"
              type="text"
              required
              autoFocus
              fullWidth
              variant="outlined"
              value={reason || ''}
              onChange={(e) => setReason(e.target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="error">
            Reject
          </Button>
          <Button type="button" onClick={() => resolve(null)}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Loadable>
  );
};

export default ReviewPerformersApplications;
