import { Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const MonitoringNav = ({ value }: { value: 'channels' | 'categories' }) => {
  const navigate = useNavigate();
  return (
    <Tabs value={value} onChange={(_, value) => navigate(`/monitoring/${value}/`)}>
      <Tab value="channels" label="Channels" />
      <Tab value="categories" label="Categories" />
    </Tabs>
  );
};

export default MonitoringNav;
