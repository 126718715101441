import './user.scss';
import { IUser } from '../modules/rest';
import { Avatar, Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { thumb } from '../modules/utils';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

const User = ({
  user,
  onClick,
  noClick,
  sx,
}: {
  user: IUser;
  onClick?(): void;
  noClick?: boolean;
  sx?: SxProps<Theme>;
}) => {
  const navigate = useNavigate();
  return (
    <Box
      onClick={noClick ? () => {} : onClick ?? (() => navigate(`/accounts/${user.id}/`))}
      sx={{ cursor: noClick ? 'default' : 'pointer' }}
    >
      <UserView user={user} sx={sx} />
    </Box>
  );
};

const UserView = ({ user, sx }: { user: IUser; sx?: SxProps<Theme> }) => {
  return (
    <Stack spacing={1} direction="row" alignItems="center" sx={sx}>
      <Avatar src={user.avatar ? thumb(user.avatar.id, 100) : ''} className={`user__avatar user__avatar-${user.role}`}>
        {user.id}
      </Avatar>
      <Stack>
        <div className="user__email">{user.name || user.email}</div>
        <div className="user__role">
          <span>{user.role.replaceAll('_', ' ')}</span>
          {user.avatar && <span style={{ marginLeft: 4 }}>ID:{user.id}</span>}
        </div>
      </Stack>
    </Stack>
  );
};

export { UserView };
export default User;
