import Layout from '../../components/layout';
import Nav from './nav';
import React, { useCallback, useEffect, useState } from 'react';
import { EFieldGroup, EReviewStatus, IVideoEmbed } from '../../modules/rest';
import { API } from '../../modules/api';
import cache from '../../modules/cache';
import { toast } from 'react-toastify';
import {
  CircularProgress,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import User from '../../components/user';
import Empty from '../../components/empty';
import { Check, Close, Edit } from '@mui/icons-material';
import { openVideoEmbedEditDialog } from './video-embed-edit';
import { confirmDialog, rejectDialog } from '../../components/dialogs';
import langs from '../../assets/json/langs.en.json';
import { formatNumber } from '../../modules/utils';
import { openVideoEmbedStatsDialog, openVideoEmbedVideosDialog } from './video-embeds-dialogs';
import Expandable from '../../components/expandable';
import ReviewStatus from '../../components/review-status';

const ReviewVideoEmbeds = () => {
  const [list, setList] = useState(cache.get<IVideoEmbed[]>('review.embeds'));
  const [loading, setLoading] = useState(true);

  const fetchList = useCallback(() => {
    setLoading(true);
    API.VideoEmbeds.getList({ limit: 1000 }, [
      EFieldGroup.VideoEmbedUser,
      EFieldGroup.VideoEmbedStatus,
      EFieldGroup.VideoEmbedVideo,
      EFieldGroup.VideoEmbedLanguage,
      EFieldGroup.VideoEmbedComment,
      EFieldGroup.VideoEmbedTracks,
      EFieldGroup.VideoEmbedStats,
      EFieldGroup.TrackFile,
    ])
      .then((res) => {
        setList(res.data);
        cache.set('review.embeds', res.data);
      })
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setLoading, setList]);

  const approve = useCallback(
    (ve: IVideoEmbed) => {
      confirmDialog('Approve video embed?').then((agree) => {
        if (!agree) return;
        setLoading(true);
        API.VideoEmbeds.approveVideoEmbed(ve.id)
          .then(() => {
            toast.success('Video embed approved!');
            fetchList();
          })
          .catch(toast.error)
          .finally(() => setLoading(false));
      });
    },
    [setLoading, fetchList]
  );

  const reject = useCallback(
    (ve: IVideoEmbed) => {
      rejectDialog('Are you sure to reject Video Embed request?').then((reason) => {
        if (!reason) return;
        setLoading(true);
        API.VideoEmbeds.rejectVideoEmbed(ve.id, { reason })
          .then(fetchList)
          .catch(toast.error)
          .finally(() => setLoading(false));
      });
    },
    [fetchList, setLoading]
  );

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <Layout title="Video embeds" breadcrumbs={<Nav />} loading={loading}>
      {list ? (
        <TableContainer component={Paper} sx={{ mb: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Account</TableCell>
                <TableCell>Service</TableCell>
                <TableCell>Language</TableCell>
                <TableCell width="30%">Comment</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Videos</TableCell>
                <TableCell align="right">Views</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((e) => (
                <TableRow key={e.id}>
                  <TableCell>{e.id}</TableCell>
                  <TableCell>
                    <User user={e.user!} />
                  </TableCell>
                  <TableCell>{e.user?.service}</TableCell>
                  <TableCell>
                    {langs[e.language!]} ({e.language})
                  </TableCell>
                  <TableCell>
                    <Expandable>{e.comment}</Expandable>
                  </TableCell>
                  <TableCell>
                    <ReviewStatus status={e.status!} />
                  </TableCell>
                  <TableCell align="right">
                    <Link
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        return openVideoEmbedVideosDialog(e);
                      }}
                    >
                      {e.videoCount}
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    <Link
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        return openVideoEmbedStatsDialog(e);
                      }}
                    >
                      {formatNumber(e.views!)}
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    {e.status === EReviewStatus.Review && (
                      <IconButton color="success" disabled={!e.video} onClick={() => approve(e)}>
                        <Check />
                      </IconButton>
                    )}
                    {e.status === EReviewStatus.Review && (
                      <IconButton color="error" onClick={() => reject(e)}>
                        <Close />
                      </IconButton>
                    )}
                    <IconButton onClick={() => openVideoEmbedEditDialog(e).then(fetchList)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Empty show={list.length === 0} />
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
    </Layout>
  );
};

export default ReviewVideoEmbeds;
