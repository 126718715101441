import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { EFieldGroup, IAsset, TIdentifier } from '../modules/rest';
import { API } from '../modules/api';
import { Button, Chip, CircularProgress, Stack, Typography } from '@mui/material';
import { download, thumb } from '../modules/utils';
import { AttachFile } from '@mui/icons-material';

const cache: Record<TIdentifier, IAsset> = {};

const getAsset = (id: TIdentifier): Promise<IAsset> => {
  if (cache[id]) return Promise.resolve(cache[id]);
  return API.Assets.getAsset(id, [EFieldGroup.AssetFull]).then((asset) => {
    cache[id] = asset;
    return asset;
  });
};

interface Props {
  value: IAsset | TIdentifier | null;
  deletable?: boolean;
  accept?: string;
  text?: string;
  preview?: boolean;
  size?: 'small' | 'medium';
  variant?: 'text' | 'outlined' | 'contained';
  onChange(file: IAsset | null): void;
}

const InputFile: FC<Props> = ({
  value,
  onChange,
  accept = '*/*',
  text = 'Select',
  preview,
  size = 'small',
  variant = 'text',
  deletable = true,
}) => {
  const [asset, setAsset] = useState<IAsset | null>();
  const [progress, setProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);

  const change = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file: File = e.target.files![0];

      e.target.value = '';

      if (asset) onChange(null);

      const fileSize = file.size;
      const chunkSize = 1024 * 512;

      if (fileSize < chunkSize) {
        const form = new FormData();
        form.append('upload', file);
        form.append('name', file.name);
        form.append('groups', 'asset:full');
        setUploading(true);
        API.Assets.uploadForm(form)
          .then((asset) => {
            onChange(asset);
          })
          .finally(() => setUploading(false))
          .catch(toast.error);
        return;
      }

      let offset = 0;
      let id: string;

      const chunkReaderBlock = () => {
        const reader = new FileReader();
        const blob = file.slice(offset, chunkSize + offset);
        const sendChunk = () => {
          const chunk = (reader.result as string).split(',')[1];
          API.Assets.chunkPartial(
            {
              id,
              chunk,
            },
            [EFieldGroup.AssetFull]
          )
            .then((res) => {
              setProgress(res.progress);
              if (res.isReady) {
                setUploading(false);
                cache[res.asset!.id] = res.asset as IAsset;
                onChange(res.asset as IAsset);
              } else {
                offset += chunkSize;
                chunkReaderBlock();
              }
            })
            .catch((e) => {
              toast.warning(e.message + ', retry in 3 sec....');
              setTimeout(sendChunk, 3000);
            });
        };

        reader.onload = sendChunk;
        reader.readAsDataURL(blob);
      };

      setProgress(0);
      setUploading(true);

      API.Assets.beginPartial({
        fileSize,
        chunkSize,
        fileType: file.type,
        fileName: file.name,
      })
        .then((res) => {
          id = res.id;
          chunkReaderBlock();
        })
        .catch((e) => {
          setUploading(false);
          toast.error(e);
        });
    },
    [onChange, asset]
  );

  useEffect(() => {
    if (typeof value === 'string' && value) {
      getAsset(value).then(setAsset);
    } else {
      setAsset(value as IAsset | null);
    }
  }, [value, setAsset]);

  if (asset === undefined) return <CircularProgress size={20} />;

  return (
    <>
      {preview && asset ? <img src={thumb(asset.id, 96)} alt="" style={{ height: 48, width: 48 }} /> : null}
      {asset ? (
        <Chip
          label={asset.name}
          onClick={() => download(asset)}
          onDelete={deletable ? () => onChange(null) : undefined}
          size={size}
        />
      ) : uploading ? (
        <Stack direction="row" spacing={1} alignItems="center">
          <CircularProgress variant="determinate" value={progress} size={20} />
          <Typography variant="caption">Uploading ({progress}%)</Typography>
        </Stack>
      ) : (
        <Button component="label" variant={variant} startIcon={<AttachFile />} size={size}>
          {text}
          <input type="file" accept={accept} onChange={change} hidden />
        </Button>
      )}
    </>
  );
};

export default InputFile;
