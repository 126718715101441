import { Navigate, Route, Routes } from 'react-router-dom';
import ContentId from './content-id';
import Spotify from './spotify';
import Meta from './meta';
import Albums from './albums';
import Covers from './covers';

const ReviewTracksRoutes = () => (
  <Routes>
    <Route path="/content-id" element={<ContentId />} />
    <Route path="/spotify" element={<Spotify />} />
    <Route path="/meta" element={<Meta />} />
    <Route path="/albums" element={<Albums />} />
    <Route path="/covers" element={<Covers />} />
    <Route path="*" element={<Navigate to="/review-tracks/content-id" replace />} />
  </Routes>
);

export default ReviewTracksRoutes;
