import { IPassport } from '../modules/rest';
import { FC, FormEvent, useCallback, useState } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { openDialog } from './dialogs';
import moment from 'moment';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type Props = {
  value: IPassport | null | undefined;
  onChange(value: IPassport): any;
  sx?: SxProps<Theme>;
};

const defaultValue: IPassport = {
  type: null,
  issuedAt: null,
  issuedBy: null,
  number: null,
  series: null,
};

const InputPassport: FC<Props> = ({ value, onChange, sx }) => {
  const passport: IPassport = value ?? defaultValue;
  return (
    <TextField
      sx={sx}
      label="ID document"
      value={[
        passport.type ?? null,
        passport.series ?? null,
        passport.number ?? null,
        passport.issuedAt ? 'issued at ' + moment(passport.issuedAt).format('DD/MM/YYYY') : null,
        passport.issuedBy ? 'by ' + passport.issuedBy : null,
      ]
        .filter((v) => !!v)
        .join(' ')}
      InputProps={{
        readOnly: true,
      }}
      onClick={() => openEditPassportDialog(passport).then((res) => res && onChange(res))}
    />
  );
};

const openEditPassportDialog = (value: IPassport | null | undefined) =>
  openDialog<IPassport | null>(
    (resolve) => {
      const [passport, setPassport] = useState<IPassport>(value ?? defaultValue);
      const submit = useCallback(
        (e: FormEvent) => {
          e.preventDefault();
          resolve(passport);
        },
        [resolve, passport]
      );
      return (
        <form onSubmit={submit}>
          <DialogTitle>ID document</DialogTitle>
          <DialogContent>
            <Stack mt={1} spacing={2}>
              <TextField
                label="Document type"
                placeholder="Passport, Driving license, etc."
                value={passport.type}
                onChange={(e) => setPassport({ ...passport, type: e.target.value || null })}
              />
              <Stack direction="row" spacing={2}>
                <TextField
                  label="Series"
                  placeholder="AA"
                  value={passport.series}
                  onChange={(e) => setPassport({ ...passport, series: e.target.value || null })}
                  sx={{ width: 200 }}
                />
                <TextField
                  label="Number"
                  placeholder="00012345"
                  value={passport.number}
                  onChange={(e) => setPassport({ ...passport, number: e.target.value || null })}
                  sx={{ flexGrow: 1 }}
                />
              </Stack>

              <Stack direction="row" spacing={2}>
                <TextField
                  label="Issue date"
                  value={moment(passport.issuedAt).format('YYYY-MM-DD')}
                  onChange={(e) => setPassport({ ...passport, issuedAt: e.target.value || null })}
                  sx={{ width: 200 }}
                  InputProps={{ type: 'date' }}
                />
                <TextField
                  label="Issued by"
                  value={passport.issuedBy}
                  onChange={(e) => setPassport({ ...passport, issuedBy: e.target.value || null })}
                  sx={{ flexGrow: 1 }}
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button type="submit">Save</Button>
            <Button onClick={() => resolve(null)}>Cancel</Button>
          </DialogActions>
        </form>
      );
    },
    { fullWidth: true, maxWidth: 'sm' }
  );

export { InputPassport, openEditPassportDialog };
