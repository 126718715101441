import {
  EContentIdStatus,
  EFieldGroup,
  EScheme,
  EVideoEmbedItemType,
  IAddVideoEmbedItemRequest,
  IAsset,
  ITrack,
  IUpdateVideoEmbedItemRequest,
  IVideoEmbed,
  IVideoEmbedItem,
} from '../../modules/rest';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
} from '@mui/material';
import { confirmDialog, openDialog } from '../../components/dialogs';
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Loadable from '../../components/loadable';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import {
  Bolt,
  Check,
  Close,
  Download,
  MusicNote,
  QrCode,
  Refresh,
  TextFields,
  Videocam,
  VideoLibraryOutlined,
} from '@mui/icons-material';
import Empty from '../../components/empty';
import InputFile from '../../components/input-file';
import { TRange } from '../../components/select-range';
import Track from '../../components/track';
import { openSelectTrackDialog } from '../../components/select-track-dialog';
import { download, downloadTrackMp3, formatTime, thumb } from '../../modules/utils';
import { waitForTask } from '../../components/dialogs/tasks';
import PreviewMedia from '../../components/preview';
import { selectLibraryItem } from '../../components/select-library-item';

const texts: Record<EScheme, { title: string; text: string }> = {
  [EScheme.Gray]: {
    title: 'YouTube is not a sponsor and/or entity conducting this contest.',
    text: 'Take part in the competition and get a chance to win an iPhone!\nScan the QR code and vote for your favorite songs.',
  },
  [EScheme.White]: {
    title: 'Start your path to success!',
    text: "Want to record reactions to music videos and make money from it? Scan the QR code and enroll in our YouTube School. We'll guide you step by step, from creating your channel to growing it.",
  },
};

const scope = [
  EFieldGroup.VideoEmbedTracks,
  EFieldGroup.VideoEmbedVideo,
  EFieldGroup.TrackAlbum,
  EFieldGroup.TrackFile,
  EFieldGroup.TrackSample,
  EFieldGroup.TrackVideoClaim,
  EFieldGroup.TrackStatus,
  EFieldGroup.AssetDuration,
];

const VideoEmbedEditDialog = ({ ve, resolve }: { ve: IVideoEmbed; resolve(): void }) => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<IVideoEmbedItem[]>();
  const [videoEmbed, setVideoEmbed] = useState(ve);

  const fetchList = useCallback(() => {
    setLoading(true);
    API.VideoEmbeds.getVideoEmbedItems(ve.id, [EFieldGroup.AssetDuration])
      .then(setList)
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setList, setLoading, ve.id]);

  const addTrack = useCallback(() => {
    openSelectTrackDialog({ hasVideoEmbed: false, audioStatus: EContentIdStatus.Active }).then((track) => {
      if (!track) return;
      API.VideoEmbeds.addTrackToVideoEmbed(ve.id, track.id, scope)
        .then((res) => {
          setVideoEmbed(res);
          toast.success('Track added');
        })
        .catch(toast.error);
      console.log(track);
    });
  }, [ve.id, setVideoEmbed]);

  const removeTrack = useCallback(
    (track: ITrack) => {
      confirmDialog('Remove this track from video embed?', { confirmText: 'Remove track', confirmColor: 'error' }).then(
        (agree) => {
          if (!agree) return;
          API.VideoEmbeds.deleteTrackFromVideoEmbed(ve.id, track.id, scope)
            .then((res) => {
              setVideoEmbed(res);
              toast.success('Track removed');
            })
            .catch(toast.error);
        }
      );
    },
    [ve.id, setVideoEmbed]
  );

  const duration = useMemo(() => {
    if (!list) return;
    let res = 0;
    list.forEach((item) => (res += item.duration));
    return res;
  }, [list]);

  const checkTrack = useCallback(
    (track: ITrack) => {
      if (!list) return true;
      return !!list.find((e) => e.audio?.id === track.file?.id);
    },
    [list]
  );

  const checkItem = useCallback(
    (item: IVideoEmbedItem) => {
      if (!item.audio) return true;
      return !!videoEmbed.tracks?.find((track) => item.audio?.id === track.file?.id);
    },
    [videoEmbed]
  );

  const fetch = useCallback(() => {
    API.VideoEmbeds.getVideoEmbed(ve.id, scope).then(setVideoEmbed);
  }, [ve.id, setVideoEmbed]);

  const render = useCallback(() => {
    API.Tasks.renderVideoEmbed(ve.id)
      .then(waitForTask)
      .then((task) => task?.result && showRenderResultDialog(ve, task?.result!, task?.preview!))
      .then(fetch)
      .catch(toast.error);
  }, [ve, fetch]);

  const preview = useCallback(() => {
    openDialog(() => <video src={ve.video!.url!} width={1080} height={640} controls autoPlay />, {
      maxWidth: 'lg',
    }).then();
  }, [ve]);

  const reset = useCallback(() => {
    confirmDialog('Are you sure to unlink all tracks and delete all elements?', {
      confirmColor: 'error',
      confirmText: 'Reset all',
    }).then((agree) => {
      if (!agree) return;
      setLoading(true);
      API.VideoEmbeds.resetVideoEmbed(ve.id)
        .then(() => Promise.all([fetch(), fetchList()]))
        .catch(toast.error)
        .finally(() => setLoading(false));
    });
  }, [setLoading, fetch, fetchList, ve.id]);

  const exportTrack = useCallback(() => {
    API.Tasks.exportTracks({ ids: [ve.tracks![0].id], exportMp3: true, exportCovers: true, exportQr: true })
      .then((task) => waitForTask(task, 'Preparing tracks for download'))
      .then((res) => {
        if (res) {
          toast.info('Downloading...', { autoClose: 1000 });
          download(res.zip!);
        }
      });
  }, [ve]);

  const generate = useCallback(() => {
    confirmDialog('Are you sure to auto-generate elements?', {
      confirmColor: 'error',
      confirmText: 'Generate',
    }).then((agree) => {
      if (!agree) return;
      setLoading(true);
      API.VideoEmbeds.generateVideoEmbedItems(ve.id)
        .then(() => Promise.all([fetch(), fetchList()]))
        .catch(toast.error)
        .finally(() => setLoading(false));
    });
  }, [setLoading, fetch, fetchList, ve.id]);

  useEffect(() => {
    fetch();
    fetchList();
  }, [fetchList, ve.id, fetch]);

  return (
    <Loadable loading={loading}>
      <DialogTitle>Video Embed</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
            <div>
              <span>Tracks</span>
              <small className="text-muted" style={{ marginLeft: 4 }}>
                {videoEmbed.tracks?.length}
              </small>
            </div>

            <Stack direction="row" spacing={1}>
              <Button size="small" onClick={addTrack} startIcon={<MusicNote />}>
                Add track
              </Button>
              <Button disabled={ve.tracks?.length !== 1} size="small" onClick={exportTrack} startIcon={<Download />}>
                Export
              </Button>
            </Stack>
          </Stack>

          <Empty
            show={videoEmbed.tracks?.length === 0}
            color="warning"
            text="There are no tracks added. Please add new one"
          />

          <Stack direction="row" spacing={3}>
            {videoEmbed.tracks?.map((track) => (
              <Stack
                key={track.id}
                alignItems="start"
                direction="row"
                spacing={1}
                className={checkTrack(track) ? 'bg-success' : 'bg-error'}
                sx={{ padding: 1, borderRadius: 2 }}
              >
                <Track track={track} showIcons />
                <Stack>
                  <IconButton onClick={() => removeTrack(track)} size="small">
                    <Close />
                  </IconButton>
                  <IconButton onClick={() => downloadTrackMp3(track)} size="small">
                    <Download />
                  </IconButton>
                </Stack>
              </Stack>
            ))}
          </Stack>

          {videoEmbed.tracks?.length ? (
            <>
              {list ? (
                <>
                  <Divider />
                  <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <div>
                      <span>Elements</span>
                      <small className="text-muted" style={{ marginLeft: 4 }}>
                        {list.length}
                      </small>
                    </div>
                    <Stack direction="row" spacing={2}>
                      <Button
                        size="small"
                        startIcon={<MusicNote />}
                        onClick={() =>
                          openAddVideoEmbedItemDialog(videoEmbed, EVideoEmbedItemType.Track).then(fetchList)
                        }
                      >
                        Track
                      </Button>
                      <Button
                        size="small"
                        startIcon={<Videocam />}
                        onClick={() =>
                          openAddVideoEmbedItemDialog(videoEmbed, EVideoEmbedItemType.Video).then(fetchList)
                        }
                      >
                        Video
                      </Button>
                      <Button
                        size="small"
                        startIcon={<QrCode />}
                        onClick={() =>
                          openAddVideoEmbedItemDialog(videoEmbed, EVideoEmbedItemType.VideoQR).then(fetchList)
                        }
                      >
                        QR
                      </Button>
                      <Button
                        size="small"
                        startIcon={<TextFields />}
                        onClick={() =>
                          openAddVideoEmbedItemDialog(videoEmbed, EVideoEmbedItemType.Text).then(fetchList)
                        }
                      >
                        Text
                      </Button>
                    </Stack>
                  </Stack>

                  <Empty
                    show={list.length === 0}
                    color="warning"
                    text="There are no elements added. Please add new one"
                  />

                  {list.length > 0 && (
                    <>
                      <Stack spacing={1} direction="row" className="video-embed__timeline">
                        {list.map((item) => (
                          <div
                            key={item.id}
                            onClick={() => openEditVideoEmbedItemDialog(item, videoEmbed).then(fetchList)}
                            className={`video-embed__timeline__item ${checkItem(item) ? 'bg-success' : 'bg-error'}`}
                            style={{ width: (item.duration / (duration ?? 1)) * 100 + '%' }}
                          >
                            <small className="text-muted">
                              {item.type === EVideoEmbedItemType.Track && <MusicNote />}
                              {item.type === EVideoEmbedItemType.Video && <Videocam />}
                              {item.type === EVideoEmbedItemType.VideoQR && <QrCode />}
                              {item.type === EVideoEmbedItemType.Text && <TextFields />}
                            </small>
                            {/*{item.video && <div>Video</div>}*/}
                            {/*{item.audio && <div>Audio</div>}*/}
                            <div className="text-muted">{formatTime(item.duration)}</div>
                          </div>
                        ))}
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        className="text-muted"
                        sx={{ marginTop: '-20px' }}
                      >
                        <small>0:00</small>
                        <small>{formatTime(duration ?? 0)}</small>
                      </Stack>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Skeleton variant="rectangular" height={50} sx={{ marginBottom: 1 }} />
                  <Skeleton variant="rectangular" height={50} sx={{ marginBottom: 1 }} />
                  <Skeleton variant="rectangular" height={50} />
                </>
              )}
            </>
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={preview} disabled={!videoEmbed.video} startIcon={<Videocam />}>
          Preview
        </Button>
        <Button onClick={() => download(videoEmbed.video!)} disabled={!videoEmbed.video} startIcon={<Download />}>
          Download
        </Button>
        <Button onClick={reset} startIcon={<Refresh />}>
          Reset all
        </Button>
        <Button onClick={generate} startIcon={<Bolt />}>
          Generate elements
        </Button>
        <Box flexGrow={1} />

        <Button onClick={render}>Render</Button>
        <Button onClick={resolve}>Close</Button>
      </DialogActions>
    </Loadable>
  );
};

const VideoEmbedItemDialog = ({
  item,
  ve,
  type,
  resolve,
}: {
  item?: IVideoEmbedItem;
  ve: IVideoEmbed;
  type?: EVideoEmbedItemType;
  resolve(item: null | IVideoEmbedItem): void;
}) => {
  const [track, setTrack] = useState(item?.track ?? null);
  const [video, setVideo] = useState(item?.video);
  const [videoRange, setVideoRange] = useState<TRange | null>(item?.videoRange ?? null);
  const [audio, setAudio] = useState(item?.audio);
  const [audioRange, setAudioRange] = useState<TRange | null>(item?.audioRange ?? null);
  const [title, setTitle] = useState(
    item?.title ??
      (type === EVideoEmbedItemType.Track
        ? "Are you enjoying the music? Don't forget to add it to your playlists!"
        : null)
  );
  const [text, setText] = useState(item?.text);
  const [loading, setLoading] = useState(false);
  const [scheme, setScheme] = useState(item?.scheme);
  const itemType: EVideoEmbedItemType = type ?? item!.type;

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      let promise;
      const payload: IAddVideoEmbedItemRequest | IUpdateVideoEmbedItemRequest = {
        audioAssetId: audio?.id,
        videoAssetId: video?.id,
        audioRange,
        videoRange,
        title,
        text,
        scheme,
        trackId: track?.id,
      };
      if (item) {
        promise = API.VideoEmbeds.updateVideoEmbedItem(item.id, payload);
      } else {
        promise = API.VideoEmbeds.addVideoEmbedItem(ve.id, { ...payload, type: type! });
      }
      promise
        .then(resolve)
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [audio, video, scheme, audioRange, videoRange, title, text, resolve, setLoading, item, type, track, ve.id]
  );

  const deleteItem = useCallback(() => {
    confirmDialog('Are you sure to delete this element?', {
      confirmColor: 'error',
      confirmText: 'Delete element',
    }).then((agree) => {
      if (!agree) return;
      setLoading(true);
      API.VideoEmbeds.deleteVideoEmbedItem(item!.id)
        .then(() => {
          toast.success('Element deleted');
          resolve(null);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    });
  }, [item, resolve]);

  const copyItem = useCallback(() => {
    setLoading(true);
    API.VideoEmbeds.copyVideoEmbedItem(item!.id)
      .then((res) => {
        toast.success('Element copied');
        resolve(res);
      })
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [item, resolve]);

  const changeScheme = useCallback(
    (scheme: EScheme | null) => {
      setScheme(scheme);
      if (!scheme) return;
      setTitle(texts[scheme].title);
      setText(texts[scheme].text);
    },
    [setTitle, setText, setScheme]
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit}>
        <DialogTitle sx={{ textTransform: 'capitalize' }}>{itemType} settings</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            {[EVideoEmbedItemType.Video, EVideoEmbedItemType.VideoQR, EVideoEmbedItemType.Track].includes(itemType) && (
              <Stack spacing={1}>
                <h3>Video*</h3>
                <Stack direction="row" spacing={2} alignItems="center">
                  {itemType === EVideoEmbedItemType.VideoQR && (
                    <TextField select value={video?.id} size="small" sx={{ minWidth: 300 }}>
                      {ve.tracks?.map((t) =>
                        t.videoClaim ? (
                          <MenuItem key={t.id} value={t.videoClaim!.id}>
                            <Track
                              track={t}
                              onClick={() => {
                                setVideo(t.videoClaim);
                                setVideoRange([0, t.videoClaim!.duration!]);
                              }}
                            />
                          </MenuItem>
                        ) : null
                      )}
                    </TextField>
                  )}

                  <InputFile
                    value={video || null}
                    onChange={(video) => {
                      setVideo(video);
                      setVideoRange(video ? [0, video.duration!] : null);
                    }}
                    accept=".mp4"
                  />

                  <Button
                    size="small"
                    startIcon={<VideoLibraryOutlined />}
                    onClick={() =>
                      selectLibraryItem().then((item) => {
                        if (item) {
                          setVideo(item.video);
                          setVideoRange([0, item.video.duration!]);
                        }
                      })
                    }
                  >
                    Library
                  </Button>
                </Stack>

                {video && videoRange && (
                  <PreviewMedia
                    type="video"
                    media={video}
                    range={videoRange}
                    onChangeRange={setVideoRange}
                    muted={!!audio}
                  />
                )}

                {/*{video && videoRange && (*/}
                {/*  <div style={{ marginTop: 5 }}>*/}
                {/*    <SelectRange value={videoRange} onChange={setVideoRange} duration={video?.duration!} />*/}
                {/*  </div>*/}
                {/*)}*/}
              </Stack>
            )}
            {[EVideoEmbedItemType.VideoQR, EVideoEmbedItemType.Text, EVideoEmbedItemType.Track].includes(itemType) && (
              <Stack spacing={2}>
                <h3>{itemType === EVideoEmbedItemType.Text ? 'Audio*' : 'Audio'}</h3>
                {ve.tracks?.length ? (
                  <TextField select value={audio?.id || '0'} size="small" sx={{ minWidth: 300 }}>
                    <MenuItem
                      value="0"
                      onClick={() => {
                        setTrack(null);
                        setAudio(null);
                        setAudioRange(null);
                      }}
                    >
                      Not selected
                    </MenuItem>
                    {ve.tracks?.map((t) => (
                      <MenuItem key={t.id} value={t.file!.id}>
                        <Track
                          track={t}
                          onClick={() => {
                            setTrack(t);
                            setAudio(t.file);
                            setAudioRange([0, t.file?.duration!]);
                          }}
                        />
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <Empty show={true} color="error" text="Add some tracks at first" />
                )}

                {audio && audioRange && (
                  <PreviewMedia type="audio" media={audio} range={audioRange} onChangeRange={setAudioRange} />
                )}
                {/*{audio && audioRange && (*/}
                {/*  <div style={{ marginTop: 5 }}>*/}
                {/*    <SelectRange value={audioRange} duration={audio!.duration!} onChange={setAudioRange} />*/}
                {/*  </div>*/}
                {/*)}*/}
              </Stack>
            )}

            {itemType === EVideoEmbedItemType.VideoQR && (
              <TextField
                label="QR template"
                required
                value={scheme || 0}
                select
                onChange={(e) => changeScheme((e.target.value as EScheme) ?? null)}
              >
                {!scheme && <MenuItem value={0}>Not selected</MenuItem>}
                <MenuItem value={EScheme.White}>Reaction School</MenuItem>
                <MenuItem value={EScheme.Gray}>Melomania</MenuItem>
              </TextField>
            )}

            {[EVideoEmbedItemType.Text, EVideoEmbedItemType.VideoQR, EVideoEmbedItemType.Track].includes(itemType) && (
              <>
                <TextField
                  required
                  label="Title"
                  value={title || ''}
                  onChange={(e) => setTitle(e.target.value || null)}
                />
                {itemType !== EVideoEmbedItemType.Track && (
                  <TextField
                    required
                    multiline
                    label="Text"
                    rows={5}
                    value={text || ''}
                    onChange={(e) => setText(e.target.value || null)}
                  />
                )}
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          {item && (
            <>
              <Button onClick={deleteItem} color="error">
                Delete
              </Button>
              <Button onClick={copyItem}>Copy</Button>
            </>
          )}
          <Box flexGrow={1} />
          <Button type="submit">Save</Button>
          <Button onClick={() => resolve(null)}>Cancel</Button>
        </DialogActions>
      </form>
    </Loadable>
  );
};

const openAddVideoEmbedItemDialog = (ve: IVideoEmbed, type: EVideoEmbedItemType) =>
  openDialog<IVideoEmbedItem | null>((resolve) => <VideoEmbedItemDialog ve={ve} type={type} resolve={resolve} />, {
    fullWidth: true,
    maxWidth: 'md',
  });

const openEditVideoEmbedItemDialog = (item: IVideoEmbedItem, ve: IVideoEmbed) =>
  openDialog<IVideoEmbedItem | null>((resolve) => <VideoEmbedItemDialog ve={ve} item={item} resolve={resolve} />, {
    fullWidth: true,
    maxWidth: 'md',
  });

const openVideoEmbedEditDialog = (ve: IVideoEmbed) =>
  openDialog<void>((resolve) => <VideoEmbedEditDialog ve={ve} resolve={resolve} />, {
    fullWidth: true,
    maxWidth: 'lg',
  });

const showRenderResultDialog = (ve: IVideoEmbed, video: IAsset, preview: IAsset | null) =>
  openDialog<void>(
    (resolve) => {
      const apply = useCallback(() => {
        API.VideoEmbeds.updateVideoEmbed(ve.id, { videoId: video.id, previewId: preview?.id || null })
          .then(() => resolve())
          .catch(toast.error);
      }, [resolve]);
      return (
        <>
          <DialogContent>
            <Stack spacing={2}>
              {video && <video src={video.url} width="100%" controls autoPlay />}
              {preview && <img src={thumb(preview.id, 200)} alt="" width={100} />}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button color="success" startIcon={<Check />} onClick={apply}>
              Apply
            </Button>
            <Button color="error" startIcon={<Close />} onClick={() => resolve()}>
              Discard
            </Button>
          </DialogActions>
        </>
      );
    },
    {
      fullWidth: true,
      maxWidth: 'md',
    }
  );

export { openVideoEmbedEditDialog };
