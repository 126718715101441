import { ReactNode, useEffect } from 'react';
import Loadable from './loadable';
import './layout.scss';

type Props = {
  title: string;
  header?: ReactNode;
  actions?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  breadcrumbs?: ReactNode;
  loading?: boolean;
};

const Layout = ({ title, header, breadcrumbs, actions, footer, children, loading = false }: Props) => {
  // const resize = useCallback(() => {
  //   let height = 0;
  //   ['.layout__header', '.layout__footer'].forEach((selector) => {
  //     height += document.querySelector<HTMLDivElement>(selector)?.offsetHeight || 0;
  //   });
  //   const content = document.querySelector<HTMLDivElement>('.layout__content')!;
  //   content.style.height = `calc(100vh - ${height}px)`;
  // }, []);
  //
  // useEffect(() => {
  //   //const interval = setInterval(resize, 100);
  //   //window.addEventListener('resize', resize);
  //   return () => {
  //     // clearInterval(interval);
  //     window.removeEventListener('resize', resize);
  //   };
  // }, [resize]);

  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = prevTitle;
    };
  }, [title]);

  return (
    <div className="layout">
      <div className="layout__heading">
        <h1>{title}</h1>
        {actions && <div className="layout__heading__actions">{actions}</div>}
      </div>

      {breadcrumbs && <div className="layout__breadcrumbs">{breadcrumbs}</div>}
      {header && <div className="layout__header">{header}</div>}

      <div className="layout__body">
        <Loadable loading={loading}>{children}</Loadable>
      </div>

      {footer && <div className="layout__footer">{footer}</div>}
    </div>
  );
};

export default Layout;
