import Layout from '../../../components/layout';
import NavTabs from './tabs';
import React, { useCallback, useEffect, useState } from 'react';
import { EFieldGroup, IAlbum, ITrack } from '../../../modules/rest';
import { API } from '../../../modules/api';
import { toast } from 'react-toastify';
import { Box, Button, Card, CardContent, Chip, CircularProgress, IconButton, Stack, TextField } from '@mui/material';
import Empty from '../../../components/empty';
import Track from '../../../components/track';
import { Edit, Search } from '@mui/icons-material';
import session from '../../../modules/session';
import { openEditAlbumDialog } from '../../tracks/edit-album';
import Checkbox from '../../../components/checkbox';
import { confirmDialog, openDialog, rejectDialog } from '../../../components/dialogs';
import Loadable from '../../../components/loadable';
import { runInAction } from 'mobx';

const scope = [EFieldGroup.TrackAlbum, EFieldGroup.AlbumPerformer, EFieldGroup.AlbumFlags];

const Albums = () => {
  const [albums, setAlbums] = useState<IAlbum[]>();
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState<string | null>(null);

  const fetch = useCallback(() => {
    setLoading(true);
    API.Albums.getAlbums({ limit: 1000, query, preset: 'review' }, scope)
      .then((res) => setAlbums(res.data))
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setAlbums, setLoading, query]);

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Layout
      loading={loading}
      title="Albums"
      actions={
        <Stack spacing={4} direction="row">
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              name="search"
              placeholder="Search by title, performer, UPC..."
              value={query || ''}
              onChange={(e) => setQuery(e.target.value || null)}
              size="small"
              onKeyDown={(e) => {
                if (e.key === 'Enter') fetch();
              }}
              sx={{ flexGrow: 1 }}
            />
            <IconButton
              onClick={() =>
                runInAction(() => {
                  return fetch();
                })
              }
            >
              <Search />
            </IconButton>
          </Stack>

          <NavTabs value={3} />
        </Stack>
      }
    >
      {albums ? (
        <Stack spacing={2}>
          {albums.map((album) => (
            <AlbumItem key={album.id} album={album} fetch={fetch} />
          ))}
          <Empty show={!albums.length} />
        </Stack>
      ) : (
        <CircularProgress />
      )}
    </Layout>
  );
};

const AlbumItem = ({ album, fetch }: { album: IAlbum; fetch(): void }) => {
  const zoom = useCallback((album: IAlbum) => openDialog(() => <img alt="" src={album.cover?.url} />), []);
  const [loading, setLoading] = useState(false);
  const approve = useCallback(() => {
    confirmDialog('Are you sure to approve album?', { confirmText: 'Approve' }).then((res) => {
      if (!res) return;
      setLoading(true);
      API.Albums.approveAlbum(album.id)
        .then(fetch)
        .catch(toast.error)
        .finally(() => {
          setLoading(false);
          return session.fetch();
        });
    });
  }, [setLoading, fetch, album.id]);

  const changeFlag = useCallback(
    (checked: boolean) => {
      setLoading(true);
      API.Albums.updateAlbum(album.id, { flagReplaceCover: checked })
        .catch(toast.error)
        .finally(() => {
          setLoading(false);
          return fetch();
        });
    },
    [fetch, album, setLoading]
  );

  const reject = useCallback(() => {
    rejectDialog('Are you sure to reject this album?', { rejectText: 'Reject' }).then((res) => {
      if (res) {
        setLoading(true);
        API.Albums.rejectAlbum(album.id, { reason: res })
          .then(fetch)
          .catch(toast.error)
          .finally(() => {
            setLoading(false);
            return session.fetch();
          });
      }
    });
  }, [setLoading, fetch, album.id]);
  return (
    <Loadable loading={loading} minHeight={0}>
      <Card>
        <CardContent>
          <Stack direction="row" spacing={2} alignItems="center">
            <small className="text-muted">#{album.id}</small>
            <Track
              onClick={() => zoom(album)}
              track={{ id: album.id, title: album.title, album, performer: album.performer } as ITrack}
            />
            {album.isSingle ? (
              <Chip size="small" variant="outlined" label="Single" />
            ) : (
              <Chip size="small" variant="outlined" label={`${album.tracksCount} tracks`} />
            )}
            <Box flexGrow={1} />
            <IconButton onClick={() => openEditAlbumDialog(album).then(fetch)} size="small">
              <Edit />
            </IconButton>
            <Checkbox label="Change cover" checked={album.flagReplaceCover!} onChange={changeFlag} />
            <Button onClick={approve}>Approve</Button>
            <Button color="error" onClick={reject}>
              Reject
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Loadable>
  );
};

export default Albums;
