import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { EFieldGroup, EPermission, EUserRole, IUser } from '../../modules/rest';
import { API } from '../../modules/api';
import { Alert, Box, Button, Chip, CircularProgress, Grid, IconButton, Paper, Stack } from '@mui/material';
import { toast } from 'react-toastify';
import User from '../../components/user';
import moment from 'moment';
import { canLogin, loginAs } from '../../modules/utils';
import { AdminPanelSettings, ArrowLeft, Check, Close, Edit, Login } from '@mui/icons-material';
import openChargeDialog from '../../components/dialogs/charge';
import Layout from '../../components/layout';
import ManageProfiles from './performers/profles';
import Checkbox from '../../components/checkbox';
import session from '../../modules/session';

const AccountItem = () => {
  const params = useParams<{ id: string }>();
  const [user, setUser] = useState<IUser>();
  const navigate = useNavigate();

  const fetchUser = useCallback(() => {
    API.Users.getUserById(params.id!, [
      EFieldGroup.UserBalance,
      EFieldGroup.UserSource,
      EFieldGroup.UserDate,
      EFieldGroup.UserRef,
      EFieldGroup.UserCountry,
      EFieldGroup.UserIp,
      EFieldGroup.UserLocale,
      EFieldGroup.UserContacts,
      EFieldGroup.UserPermissions,
    ])
      .then(setUser)
      .catch(toast.error);
  }, [params.id]);

  useEffect(() => {
    fetchUser();
  }, [params.id, fetchUser]);

  return (
    <>
      <Layout
        title="Accounts"
        breadcrumbs={
          <Stack direction="row" spacing={1}>
            <IconButton onClick={() => navigate('../', { replace: true })}>
              <ArrowLeft />
            </IconButton>
            {user && <User user={user} />}
          </Stack>
        }
      >
        {user ? (
          <Paper>
            <Box p={2}>
              <Grid container spacing={1}>
                <Grid item sm={3}>
                  Role: <b>{user.role}</b>
                </Grid>
                <Grid item sm={3}>
                  Service: <b>{user.service ?? 'N/A'}</b>
                </Grid>
                <Grid item sm={3}>
                  Source: <b>{user.source ?? 'N/A'}</b>
                </Grid>
                <Grid item sm={3}>
                  Status: <b>{user.status}</b>
                </Grid>
                <Grid item sm={3}>
                  Registered: <b>{moment(user.createdAt).format('DD/MM/YYYY')}</b>
                </Grid>
                <Grid item sm={3}>
                  Accessed: <b>{moment(user.accessedAt).format('DD/MM/YYYY')}</b>
                </Grid>
                <Grid item sm={3}>
                  IP: <b>{user.ip ?? 'N/A'}</b>
                </Grid>
                <Grid item sm={3}>
                  Country: <b>{user.country?.toUpperCase() ?? 'N/A'}</b>
                </Grid>
                <Grid item sm={3}>
                  Telegram: <b>{user.contacts?.telegram ?? 'N/A'}</b>
                </Grid>
                <Grid item sm={3}>
                  Viber: <b>{user.contacts?.viber ?? 'N/A'}</b>
                </Grid>
                <Grid item sm={3}>
                  WhatsApp: <b>{user.contacts?.whatsapp ?? 'N/A'}</b>
                </Grid>
              </Grid>

              <Box my={2}>
                <Stack alignItems="center" display="inline-flex" direction="row" spacing={1}>
                  <span>Balance: $ {user.balance?.toFixed(2)}</span>
                  <IconButton color="primary" size="small" onClick={() => openChargeDialog(user).then(fetchUser)}>
                    <Edit />
                  </IconButton>
                </Stack>
              </Box>

              {user.role === EUserRole.Admin && (
                <Permissions user={user} readonly={session.user?.role !== EUserRole.Admin} />
              )}

              <Button
                disabled={!canLogin(user)}
                onClick={() => loginAs(user)}
                variant="contained"
                startIcon={<Login />}
              >
                Sign in
              </Button>
            </Box>
          </Paper>
        ) : (
          <CircularProgress />
        )}

        {user?.role === EUserRole.Performer && (
          <Box my={2}>
            <h3>Performer profiles</h3>
            <ManageProfiles user={user} />
          </Box>
        )}
      </Layout>
    </>
  );
};

const Permissions = ({ user, readonly }: { user: IUser; readonly: boolean }) => {
  const [permissions, setPermissions] = useState(user.permissions!);

  const toggle = useCallback(
    (permission: EPermission) => {
      let upd: EPermission[];
      if (permissions.includes(permission)) upd = permissions.filter((p) => p !== permission);
      else upd = [...permissions, permission];
      API.Users.updateUser(user.id, { permissions: upd })
        .then(() => {
          setPermissions(upd);
        })
        .catch(toast.error);
    },
    [user, permissions, setPermissions]
  );

  return (
    <Alert icon={<AdminPanelSettings />} sx={{ mb: 2 }}>
      <Stack>
        <b>Permissions</b>
        <Stack direction="row" flexWrap={'wrap'} mt={2}>
          {Object.entries(EPermission).map(([name, id]) => (
            <Box sx={{ width: 200 }} py={0.5}>
              {readonly ? (
                <Chip
                  label={name}
                  size="small"
                  color={permissions.includes(id) ? 'success' : 'error'}
                  variant="outlined"
                  icon={permissions.includes(id) ? <Check /> : <Close />}
                />
              ) : (
                <Checkbox
                  key={id}
                  label={name}
                  checked={permissions.includes(id)}
                  onChange={() => toggle(id as EPermission)}
                />
              )}
            </Box>
          ))}
        </Stack>
      </Stack>
    </Alert>
  );
};

export default AccountItem;
