import React, { FormEvent, useCallback, useEffect } from 'react';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import { observer, useLocalObservable } from 'mobx-react';
import { EFieldGroup, EService, EUserRole, EUserStatus, IGetUsersListRequest, IUser } from '../../modules/rest';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { runInAction } from 'mobx';
import cache from '../../modules/cache';
import { canLogin, loginAs, PagerState } from '../../modules/utils';
import Pager from '../../components/pager';
import { Login, Search, Telegram, WhatsApp } from '@mui/icons-material';
import Empty from '../../components/empty';
import User from '../../components/user';
import Layout from '../../components/layout';
import Viber from '../../components/icons/viber';
import InputUser from '../../components/input-user';
import Editable from '../../components/editable';
import moment from 'moment';

const AccountsList = observer(() => {
  const state = useLocalObservable<PagerState<IUser, IGetUsersListRequest>>(() => ({
    loading: true,
    pager: cache.get('users.pager'),
    request: cache.get('users.request') ?? { page: 1, limit: 25 },
  }));

  const fetch = useCallback(() => {
    runInAction(() => (state.loading = true));
    return API.Users.getUsersList(state.request, [
      EFieldGroup.UserBalance,
      EFieldGroup.UserContacts,
      EFieldGroup.UserManager,
      EFieldGroup.UserComment,
      EFieldGroup.UserDate,
    ])
      .then((pager) =>
        runInAction(() => {
          state.pager = pager;
          state.request.page = pager.page;
          state.request.limit = pager.limit;
          cache.set('users.pager', state.pager);
          cache.set('users.request', state.request);
        })
      )
      .catch(toast.error)
      .finally(() => runInAction(() => (state.loading = false)));
  }, [state]);

  const search = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      runInAction(() => (state.request.page = 1));
      return fetch();
    },
    [state, fetch]
  );

  const setComment = useCallback(
    (user: IUser, comment: string | null) => {
      API.Users.updateUserComment(user.id, { comment }).then(fetch).catch(toast.error);
    },
    [fetch]
  );

  useEffect(() => {
    fetch().then();
  }, [fetch]);

  return (
    <Layout
      loading={state.loading}
      title="Accounts"
      header={
        <form onSubmit={search}>
          <Paper>
            <Box p={2}>
              <Stack direction="row" spacing={1}>
                <TextField
                  label="Search"
                  size="small"
                  placeholder="ID, e-mail or contacts"
                  sx={{ flexGrow: 1 }}
                  value={state.request.query || ''}
                  onChange={(e) => runInAction(() => (state.request.query = e.target.value || undefined))}
                />
                <InputUser
                  value={state.request.manager}
                  onChange={(value) =>
                    runInAction(() => {
                      state.request.manager = value?.id;
                    })
                  }
                  filter={{ role: EUserRole.Admin }}
                />
                <TextField
                  label="Role"
                  select
                  size="small"
                  sx={{ textTransform: 'capitalize', width: 220 }}
                  value={state.request.role || 0}
                  onChange={(e) => runInAction(() => (state.request.role = (e.target.value as EUserRole) || undefined))}
                >
                  <MenuItem value={0}>Any</MenuItem>
                  {Object.entries(EUserRole).map(([, value]) => (
                    <MenuItem key={value} value={value} sx={{ textTransform: 'capitalize' }}>
                      {value.replaceAll('_', ' ')}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  size="small"
                  label="Service"
                  sx={{ width: 220 }}
                  value={state.request.service || 0}
                  onChange={(e) =>
                    runInAction(() => (state.request.service = (e.target.value as EService) || undefined))
                  }
                >
                  <MenuItem value={0}>Any</MenuItem>
                  {Object.entries(EService).map(([name, value]) => (
                    <MenuItem key={value} value={value}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  size="small"
                  label="Status"
                  sx={{ width: 220 }}
                  value={state.request.status || 0}
                  onChange={(e) =>
                    runInAction(() => (state.request.status = (e.target.value as EUserStatus) || undefined))
                  }
                >
                  <MenuItem value={0}>Any</MenuItem>
                  {Object.entries(EUserStatus).map(([name, value]) => (
                    <MenuItem key={value} value={value}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
                <Button variant="contained" type="submit" startIcon={<Search />}>
                  Search
                </Button>
              </Stack>
            </Box>
          </Paper>
        </form>
      }
      footer={<Pager pager={state.pager} request={state.request} onChange={fetch} />}
    >
      {state.pager ? (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Account</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Manager</TableCell>
                  <TableCell>Service</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Contacts</TableCell>
                  <TableCell>Comment</TableCell>
                  <TableCell>Reg.date</TableCell>
                  <TableCell>Balance</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {state.pager.data.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>
                      <User user={user} />
                    </TableCell>
                    <TableCell sx={{ textTransform: 'capitalize' }}>{user.role.replaceAll('_', ' ')}</TableCell>
                    <TableCell>{user.manager && <User user={user.manager} />}</TableCell>
                    <TableCell>{user.service}</TableCell>
                    <TableCell sx={{ textTransform: 'capitalize' }}>{user.status}</TableCell>
                    <TableCell>
                      {user.contacts?.telegram && (
                        <small className="d-flex align-items-center" style={{ color: '#37aee2' }}>
                          <Telegram /> {user.contacts?.telegram}
                        </small>
                      )}
                      {user.contacts?.whatsapp && (
                        <small className="d-flex align-items-center" style={{ color: '#25D366' }}>
                          <WhatsApp /> {user.contacts?.whatsapp}
                        </small>
                      )}
                      {user.contacts?.viber && (
                        <small className="d-flex align-items-center" style={{ color: '#7360f2' }}>
                          <Viber /> {user.contacts?.viber}
                        </small>
                      )}
                    </TableCell>
                    <TableCell>
                      <Editable value={user.comment!} onChange={(comment) => setComment(user, comment)} />
                    </TableCell>
                    <TableCell>{moment(user.createdAt).format('DD.MM.YYYY')}</TableCell>
                    <TableCell>{user.balance ? <>$ {user.balance.toFixed(2)}</> : null}</TableCell>
                    <TableCell align="right">
                      {canLogin(user) && (
                        <IconButton onClick={() => loginAs(user)} size="small">
                          <Login />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Empty show={!state.pager.data.length} />
          </TableContainer>
        </>
      ) : (
        <CircularProgress />
      )}
    </Layout>
  );
});

export default AccountsList;
