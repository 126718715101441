import { useState } from 'react';
import { IconButton } from '@mui/material';
import { ITrack } from '../modules/rest';
import { PlayArrow, Stop } from '@mui/icons-material';

const Player = ({ track }: { track: ITrack }) => {
  const [active, setActive] = useState<boolean>(false);
  return (
    <>
      <IconButton size="small" color={active ? 'error' : 'primary'} onClick={() => setActive(!active)}>
        {active ? <Stop /> : <PlayArrow />}
      </IconButton>
      {active && <audio src={track.sample?.url ?? track.file?.url ?? ''} autoPlay={true} controls={false} />}
    </>
  );
};

export default Player;
