import { Navigate, Route, Routes } from 'react-router-dom';
import ReportsList from './list';

const ReportsRoutes = () => (
  <Routes>
    <Route path="/" element={<ReportsList />} />
    <Route path="*" element={<Navigate to="/reports/" replace />} />
  </Routes>
);

export default ReportsRoutes;
