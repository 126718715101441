import { EFieldGroup, IPerformer, IUser } from '../../../modules/rest';
import { useCallback, useEffect, useState } from 'react';
import Loadable from '../../../components/loadable';
import { API } from '../../../modules/api';
import { toast } from 'react-toastify';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Empty from '../../../components/empty';
import { Edit } from '@mui/icons-material';
import { openEditPerformerProfileDialog } from './edit-profile';

const ManageProfiles = ({ user }: { user: IUser }) => {
  const [list, setList] = useState<IPerformer[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchList = useCallback(() => {
    setLoading(true);
    API.Performers.getList({ userId: user.id, limit: 1000 }, [EFieldGroup.PerformerEdit])
      .then((res) => setList(res.data))
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setLoading, user, setList]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <Loadable loading={loading}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={50}>ID</TableCell>
              <TableCell>Stage name</TableCell>
              <TableCell>Name</TableCell>
              <TableCell width={200}>Status</TableCell>
              <TableCell width={10} />
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((p) => (
              <TableRow key={p.id}>
                <TableCell>{p.id}</TableCell>
                <TableCell>
                  <b>{p.stageName}</b>
                </TableCell>
                <TableCell>
                  {p.firstName} {p.middleName} {p.lastName}
                </TableCell>
                <TableCell sx={{ textTransform: 'capitalize' }} className={`review-status review-status-${p.status}`}>
                  {p.status}
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => openEditPerformerProfileDialog(p).then((r) => r && fetchList())}
                  >
                    <Edit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Empty show={!loading && !list.length} />
      </TableContainer>
    </Loadable>
  );
};

export default ManageProfiles;
