import { NavLink } from 'react-router-dom';
import { Badge } from '@mui/material';
import session from './modules/session';
import { observer } from 'mobx-react';
import { HasPermission } from './modules/utils';
import { EPermission } from './modules/rest';

const AppNav = observer(() => {
  return (
    <div className="nav">
      <HasPermission permission={EPermission.ReviewTracks}>
        <Badge badgeContent={session.badges?.reviewTracks} color="error" sx={{ marginRight: 2 }}>
          <NavLink to="/review-tracks/">Tracks</NavLink>
        </Badge>
      </HasPermission>

      <HasPermission permission={EPermission.Review}>
        <Badge badgeContent={session.badges?.review} color="error" sx={{ marginRight: 2 }}>
          <NavLink to="/review/">Review</NavLink>
        </Badge>
      </HasPermission>

      <HasPermission permission={EPermission.Tracks}>
        <Badge sx={{ marginRight: 2 }}>
          <NavLink to="/tracks/">Tracks</NavLink>
        </Badge>
      </HasPermission>
      {/*<Badge badgeContent={session.badges?.tracksHasNoVideo} color="error" sx={{ marginRight: 2 }}>*/}
      {/*  <NavLink to="/video/remaining/">Video</NavLink>*/}
      {/*</Badge>*/}
      <HasPermission permission={EPermission.Batches}>
        <Badge badgeContent={session.badges?.batches} color="error" sx={{ marginRight: 2 }}>
          <NavLink to="/batches/">Batches</NavLink>
        </Badge>
      </HasPermission>
      <HasPermission permission={EPermission.Upload}>
        <NavLink to="/upload/">Upload</NavLink>
      </HasPermission>

      <HasPermission permission={EPermission.Accounts}>
        <NavLink to="/accounts/">Accounts</NavLink>
      </HasPermission>
      <HasPermission permission={EPermission.Finances}>
        <Badge badgeContent={session.badges?.withdraw} color="error" sx={{ marginRight: 2 }}>
          <NavLink to="/withdraw/">Payments</NavLink>
        </Badge>
      </HasPermission>
      <HasPermission permission={EPermission.Library}>
        <NavLink to="/library/">Library</NavLink>
      </HasPermission>
      <HasPermission permission={EPermission.Docs}>
        <NavLink to="/docs/">Docs</NavLink>
      </HasPermission>
      <HasPermission permission={EPermission.Stats}>
        <NavLink to="/stats/">Stats</NavLink>
      </HasPermission>
      <HasPermission permission={EPermission.Reports}>
        <NavLink to="/reports/">Reports</NavLink>
      </HasPermission>
      <HasPermission permission={EPermission.Monitoring}>
        <NavLink to="/monitoring/">Monitoring</NavLink>
      </HasPermission>
      <HasPermission permission={EPermission.Media}>
        <Badge sx={{ marginRight: 2 }}>
          <NavLink to="/media/">Media</NavLink>
        </Badge>
      </HasPermission>
      <HasPermission permission={EPermission.Albums}>
        <Badge sx={{ marginRight: 2 }}>
          <NavLink to="/albums/">Albums</NavLink>
        </Badge>
      </HasPermission>
      <HasPermission permission={EPermission.Full}>
        <NavLink to="/settings/">Settings</NavLink>
      </HasPermission>
    </div>
  );
});

export default AppNav;
