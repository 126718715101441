import { Navigate, Route, Routes } from 'react-router-dom';
import ManageDocs from './manage';

const DocsRoutes = () => (
  <Routes>
    <Route path="/" element={<ManageDocs />} />
    <Route path="/:id/" element={<ManageDocs />} />
    <Route path="*" element={<Navigate to="/docs/" replace />} />
  </Routes>
);

export default DocsRoutes;
