import { ECurrency, IBankDetails } from '../modules/rest';
import { FC, FormEvent, useCallback, useState } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { openDialog } from './dialogs';
import InputCurrency from './input-currency';
import Checkbox from './checkbox';

type Props = {
  value: IBankDetails | null | undefined;
  onChange(value: IBankDetails): any;
};

const defaultValue: IBankDetails = {
  accountHolder: '',
  bankAddress: '',
  bankName: '',
  bic: '',
  cardHolder: null,
  cardNumber: null,
  corrBic: null,
  corrIban: null,
  corrName: null,
  currency: ECurrency.USD,
  iban: '',
  payoneerAccount: '',
};

const InputBankDetails: FC<Props> = ({ value, onChange }) => {
  const details: IBankDetails = value ?? defaultValue;
  return (
    <TextField
      sx={{ flexGrow: 1 }}
      label="Bank details"
      value={[
        details.bankName ?? null,
        details.bic ? `BIC/SWIFT: ${details.bic}` : null,
        details.iban ? `IBAN: ${details.iban} (${details.currency})` : null,
        details.accountHolder ? `Account holder: ${details.accountHolder}` : null,
        details.cardNumber ? `Card: ${details.cardNumber}` : null,
        details.corrName ? `Correspondent Bank: ${details.corrName}, ${details.corrBic}, ${details.corrIban}` : null,
        details.payoneerAccount ? `Payoneer: ${details.payoneerAccount}` : null,
      ]
        .filter((v) => !!v)
        .join(', ')}
      InputProps={{
        readOnly: true,
      }}
      onClick={() => openEditBankDetailsDialog(details).then((res) => res && onChange(res))}
    />
  );
};

const openEditBankDetailsDialog = (value: IBankDetails | null | undefined) =>
  openDialog<IBankDetails | null>(
    (resolve) => {
      const [details, setDetails] = useState<IBankDetails>(value ?? defaultValue);
      const submit = useCallback(
        (e: FormEvent) => {
          e.preventDefault();
          resolve(details);
        },
        [resolve, details]
      );
      return (
        <form onSubmit={submit}>
          <DialogTitle>Bank details</DialogTitle>
          <DialogContent>
            <Stack mt={1} spacing={2}>
              <Stack direction="row" spacing={2}>
                <TextField
                  required
                  label="Bank name"
                  placeholder="Transferwise"
                  value={details.bankName}
                  onChange={(e) => setDetails({ ...details, bankName: e.target.value })}
                  fullWidth
                />
                <TextField
                  required
                  label="Bank address"
                  placeholder="Avenue Louise 54, Room S52, Brussels, 1050, Belgium"
                  value={details.bankAddress}
                  onChange={(e) => setDetails({ ...details, bankAddress: e.target.value })}
                  fullWidth
                />
              </Stack>

              <Stack direction="row" spacing={2}>
                <TextField
                  required
                  label="BIC/SWIFT"
                  placeholder="TRWIGB21XXX"
                  value={details.bic}
                  onChange={(e) => setDetails({ ...details, bic: e.target.value })}
                  sx={{ width: 200 }}
                />
                <TextField
                  required
                  label="IBAN/Account number"
                  value={details.iban}
                  placeholder="GB98MIDL07009312345678"
                  onChange={(e) => setDetails({ ...details, iban: e.target.value })}
                  sx={{ flexGrow: 1 }}
                />
                <InputCurrency
                  value={details.currency}
                  onChange={(currency) => setDetails({ ...details, currency: currency as ECurrency })}
                  required
                  sx={{ width: 100 }}
                />
              </Stack>
              <TextField
                required
                label="Account holder"
                placeholder="John Doe"
                value={details.accountHolder}
                onChange={(e) => setDetails({ ...details, accountHolder: e.target.value })}
              />

              <Checkbox
                label="Correspondent bank"
                checked={details.corrName !== null}
                onChange={(checked) =>
                  setDetails(
                    checked
                      ? { ...details, corrName: '', corrIban: '', corrBic: '' }
                      : { ...details, corrName: null, corrIban: null, corrBic: null }
                  )
                }
              />

              {details.corrName !== null && (
                <Stack direction="row" spacing={2}>
                  <TextField
                    required
                    label="Bank name, address"
                    placeholder="Transferwise, Avenue Louise 54, Room S52, Brussels, 1050, Belgium"
                    value={details.corrName}
                    onChange={(e) => setDetails({ ...details, corrName: e.target.value })}
                    sx={{ flexGrow: 1 }}
                  />
                  <TextField
                    required
                    label="BIC/SWIFT"
                    value={details.corrBic}
                    placeholder="TRWIGB21XXX"
                    onChange={(e) => setDetails({ ...details, corrBic: e.target.value })}
                    sx={{ width: 200 }}
                  />
                  <TextField
                    required
                    label="IBAN/Account number"
                    placeholder="GB98MIDL07009312345678"
                    value={details.corrIban}
                    onChange={(e) => setDetails({ ...details, corrIban: e.target.value })}
                    sx={{ flexGrow: 1 }}
                  />
                </Stack>
              )}

              <Checkbox
                label="Bank card"
                checked={details.cardNumber !== null}
                onChange={(checked) =>
                  setDetails(
                    checked
                      ? { ...details, cardNumber: '', cardHolder: '' }
                      : { ...details, cardNumber: null, cardHolder: null }
                  )
                }
              />

              {details.cardNumber !== null && (
                <Stack direction="row" spacing={2}>
                  <TextField
                    required
                    label="Card number"
                    placeholder="5012 3456 7890 1234"
                    value={details.cardNumber}
                    onChange={(e) => setDetails({ ...details, cardNumber: e.target.value })}
                    sx={{ flexGrow: 1 }}
                  />
                  <TextField
                    required
                    label="Card holder"
                    value={details.cardHolder}
                    placeholder="JOHN DOE"
                    onChange={(e) => setDetails({ ...details, cardHolder: e.target.value })}
                    sx={{ flexGrow: 1 }}
                  />
                </Stack>
              )}
              <TextField
                label="Payoneer account"
                value={details.payoneerAccount}
                placeholder="john@doe.com"
                onChange={(e) => setDetails({ ...details, payoneerAccount: e.target.value })}
                sx={{ flexGrow: 1 }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button type="submit">Save</Button>
            <Button onClick={() => resolve(null)}>Cancel</Button>
          </DialogActions>
        </form>
      );
    },
    { fullWidth: true, maxWidth: 'md' }
  );

export { InputBankDetails, openEditBankDetailsDialog };
