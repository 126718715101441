import Layout from '../../components/layout';
import { observer, useLocalObservable } from 'mobx-react';
import { download, formatTime, PagerState } from '../../modules/utils';
import { EFieldGroup, ELibraryItemType, IAsset, IGetLibraryItemsRequest, ILibraryItem } from '../../modules/rest';
import cache from '../../modules/cache';
import React, { useCallback, useEffect, useState } from 'react';
import { runInAction } from 'mobx';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import { Card, CardMedia, IconButton, Stack, Tab, Tabs } from '@mui/material';
import InputFile from '../../components/input-file';
import Empty from '../../components/empty';
import Pager from '../../components/pager';
import { Close, Download } from '@mui/icons-material';
import { confirmDialog } from '../../components/dialogs';

const LibraryList = observer(() => {
  const state = useLocalObservable<PagerState<ILibraryItem, IGetLibraryItemsRequest>>(() => ({
    loading: true,
    request: cache.get('library.request') ?? { type: ELibraryItemType.School, page: 1, limit: 25 },
    pager: cache.get('library.pager'),
  }));

  const [asset, setAsset] = useState<IAsset | null>(null);

  const fetch = useCallback(() => {
    runInAction(() => (state.loading = true));
    API.Library.getItemsList(state.request, [EFieldGroup.AssetDuration, EFieldGroup.AssetResolution])
      .then((res) =>
        runInAction(() => {
          state.pager = res;
          state.request.page = res.page;
          state.request.limit = res.limit;
          cache.set('library.request', state.request);
          cache.set('library.pager', state.pager);
        })
      )
      .catch(toast.error)
      .finally(() => runInAction(() => (state.loading = false)));
  }, [state]);

  const deleteItem = useCallback(
    (item: ILibraryItem) => {
      confirmDialog('Are you sure to delete this video?', { confirmColor: 'error', confirmText: 'Delete' }).then(
        (agree) => {
          if (!agree) return;
          runInAction(() => (state.loading = true));
          API.Library.deleteItem(item.id)
            .then(fetch)
            .catch(toast.error)
            .finally(() => runInAction(() => (state.loading = false)));
        }
      );
    },
    [state, fetch]
  );

  const createItem = useCallback(
    (asset: IAsset | null) => {
      setAsset(asset);
      if (!asset) return;
      runInAction(() => (state.loading = true));
      API.Library.createItem({ type: state.request.type!, assetId: asset.id })
        .then(() => {
          toast.success('Upload successful');
          fetch();
        })
        .catch(toast.error)
        .finally(() =>
          runInAction(() => {
            setAsset(null);
            state.loading = false;
          })
        );
    },
    [setAsset, state, fetch]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Layout
      title="Library"
      loading={state.loading}
      breadcrumbs={
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Tabs
            value={state.request.type}
            onChange={(_, type) =>
              runInAction(() => {
                state.request.type = type;
                fetch();
              })
            }
          >
            <Tab value={ELibraryItemType.School} label="React School" />
            <Tab value={ELibraryItemType.Contest} label="Contest" />
            <Tab value={ELibraryItemType.Transition} label="Transitions" />
          </Tabs>

          <InputFile
            deletable={false}
            size="medium"
            value={asset}
            variant="contained"
            accept=".mp4"
            onChange={createItem}
            text="Upload video"
          />
        </Stack>
      }
      footer={<Pager pager={state.pager} request={state.request} onChange={fetch} />}
    >
      {state.pager && (
        <>
          {state.pager.count! > 0 && (
            <div style={{ display: 'flex', flexFlow: 'wrap' }}>
              {state.pager.data.map((item) => (
                <Card sx={{ mr: 2, mb: 2 }}>
                  <VideoPreview item={item} />
                  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} p={1}>
                    <small className="text-muted">{item.video.resolution!.join('x')}</small>
                    <small className="text-muted">{formatTime(item.video.duration!, false)}</small>
                    <div>
                      <IconButton size="small" sx={{ width: 24, height: 24 }} onClick={() => download(item.video)}>
                        <Download sx={{ width: 20, height: 20 }} />
                      </IconButton>
                      <IconButton
                        size="small"
                        color="error"
                        sx={{ width: 24, height: 24 }}
                        onClick={() => deleteItem(item)}
                      >
                        <Close sx={{ width: 18, height: 18 }} />
                      </IconButton>
                    </div>
                  </Stack>
                </Card>
              ))}
            </div>
          )}
          <Empty show={!state.pager.count} />
        </>
      )}
    </Layout>
  );
});

export const VideoPreview = ({ item, noClick = false }: { item: ILibraryItem; noClick?: boolean }) => {
  const [active, setActive] = useState(false);
  return (
    <CardMedia
      sx={{ height: 135, width: 240, cursor: active ? undefined : 'pointer' }}
      image={active ? '' : item.preview!.url}
      onClick={() => !noClick && setActive(true)}
    >
      {active && <video src={item.video.url} controls autoPlay width={240} height={135} />}
    </CardMedia>
  );
};

export default LibraryList;
