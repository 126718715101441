import { Navigate, Route, Routes } from 'react-router-dom';
import MonitoringChannels from './channels';
import MonitoringCategories from './categories';

const MonitoringRoutes = () => (
  <Routes>
    <Route path="/channels/*" element={<MonitoringChannels />} />
    <Route path="/categories/*" element={<MonitoringCategories />} />
    <Route path="*" element={<Navigate to="/monitoring/channels/" replace />} />
  </Routes>
);

export default MonitoringRoutes;
