import { MenuItem, TextField } from '@mui/material';
import { ICompany } from '../modules/rest';
import { useEffect, useState } from 'react';
import { API } from '../modules/api';

const SelectCompany = ({
  company,
  onChange,
  size,
  requried,
}: {
  requried?: boolean;
  size?: 'small' | 'medium';
  company?: ICompany | number | null;
  onChange(company: ICompany | null): void;
}) => {
  const [companies, setCompanies] = useState<ICompany[]>();

  useEffect(() => {
    API.Companies.getList().then(setCompanies);
  }, [setCompanies]);

  return (
    <TextField
      required={requried}
      size={size}
      label="Company"
      value={Number.isInteger(company) ? company : (company as ICompany)?.id || 0}
      select
    >
      <MenuItem value={0} onClick={() => onChange(null)}>
        Not selected
      </MenuItem>
      {companies?.map((g) => (
        <MenuItem key={g.id} value={g.id} onClick={() => onChange(g)}>
          {g.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
export default SelectCompany;
