import { Check, CopyAll } from '@mui/icons-material';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import './copy-to-clipboard.scss';
import { Stack } from '@mui/material';

const CopyToClipboard = ({ text, size = 18 }: { text?: string | null; size?: number }) => {
  const [copied, setCopied] = useState(false);
  const copy = useCallback(() => {
    navigator.clipboard
      .writeText(text ?? '')
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 500);
      })
      .catch(toast.error);
  }, [text, setCopied]);
  return copied ? (
    <Check className="copy copy-1" sx={{ width: size, height: size }} />
  ) : (
    <CopyAll onClick={copy} className="copy copy-0" sx={{ width: size, height: size }} />
  );
};

const CopyToClipboardText = ({ children }: { children?: string | null }) => (
  <Stack direction="row" spacing={1} display="inline-flex">
    <div>{children ?? 'N/A'}</div>
    <CopyToClipboard text={children} />
  </Stack>
);

const copyToClipboard = (text: string) => navigator.clipboard.writeText(text);

export { CopyToClipboard, CopyToClipboardText, copyToClipboard };
