import { IGetVideoRequest, IVideo, IVideoEmbed, IViewStat } from '../../modules/rest';
import { openDialog } from '../../components/dialogs';
import { observer, useLocalObservable } from 'mobx-react';
import { formatNumber, formatTime, PagerState, thumb } from '../../modules/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { runInAction } from 'mobx';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import Loadable from '../../components/loadable';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import Pager from '../../components/pager';
import Empty from '../../components/empty';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment/moment';

const VideoEmbedVideos = observer(({ ve }: { ve: IVideoEmbed }) => {
  const state = useLocalObservable<PagerState<IVideo, IGetVideoRequest>>(() => ({
    loading: true,
    request: { limit: 25 },
  }));

  const fetch = useCallback(() => {
    runInAction(() => (state.loading = true));
    API.VideoEmbeds.getYoutubeVideos(ve.id, state.request)
      .then((res) =>
        runInAction(() => {
          state.pager = res;
          state.request.page = res.page;
          state.request.limit = res.limit;
        })
      )
      .catch(toast.error)
      .finally(() => runInAction(() => (state.loading = false)));
  }, [state, ve.id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Loadable loading={state.loading}>
      <DialogTitle>Embed #{ve.id} / Videos</DialogTitle>
      <Box sx={{ height: 500, maxHeight: 500, overflowY: 'scroll' }} className="embed-videos">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Video</TableCell>
              <TableCell align="right">Views</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.pager?.data.map((v) => (
              <TableRow key={v.id}>
                <TableCell width={10}>
                  <small className="text-muted">{v.id}</small>
                </TableCell>
                <TableCell width={450}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    {v.cover && <img alt="" src={thumb(v.cover.id, 130)} width={65} height={49} />}
                    <Stack spacing={0.5}>
                      <Link href={`https://youtu.be/${v.extId}`} target="_blank" sx={{ fontSize: '90%' }}>
                        {v.title ?? v.extId}
                      </Link>
                      {v.duration > 0 && <small className="text-muted">{formatTime(v.duration)}</small>}
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell align="right">{formatNumber(v.views)}</TableCell>
                <TableCell sx={{ textTransform: 'capitalize' }}>{v.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Empty show={state.pager?.count === 0} />
      </Box>
      <DialogActions>
        <Pager pager={state.pager} request={state.request} onChange={fetch} scrollSelector=".embed-videos" />
      </DialogActions>
    </Loadable>
  );
});

const VideoEmbedStats = ({ ve }: { ve: IVideoEmbed }) => {
  const [stats, setStats] = useState<IViewStat[]>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    API.VideoEmbeds.getViews(ve.id, 90)
      .then(setStats)
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [ve.id, setStats]);
  return (
    <Loadable loading={loading}>
      <DialogTitle>Embed #{ve.id} / Stats</DialogTitle>
      <DialogContent>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={600}
            height={300}
            data={stats?.reverse().map((d) => ({
              ...d,
              date: moment(d.date).format('DD.MM'),
            }))}
            margin={{ left: 40 }}
          >
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Bar dataKey="views" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </DialogContent>
    </Loadable>
  );
};

const openVideoEmbedVideosDialog = (ve: IVideoEmbed) =>
  openDialog<void>(() => <VideoEmbedVideos ve={ve} />, { fullWidth: true, maxWidth: 'md' });

const openVideoEmbedStatsDialog = (ve: IVideoEmbed) =>
  openDialog<void>(() => <VideoEmbedStats ve={ve} />, { fullWidth: true, maxWidth: 'lg' });

export { openVideoEmbedVideosDialog, openVideoEmbedStatsDialog };
