import { Navigate, Route, Routes } from 'react-router-dom';
import BatchesList from './list';
import BatchItem from './item';

const BatchesRoutes = () => (
  <Routes>
    <Route path="/" element={<BatchesList />} />
    <Route path="/:id/" element={<BatchItem />} />
    <Route path="*" element={<Navigate to="/batches/" replace />} />
  </Routes>
);

export default BatchesRoutes;
