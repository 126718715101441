import { openDialog } from '../dialogs';
import { ETransactionType, ITransaction, IUser } from '../../modules/rest';
import { Box, Button, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material';
import React, { FormEvent, useCallback, useState } from 'react';
import { UserView } from '../user';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import Loadable from '../loadable';

const openChargeDialog = (user: IUser) =>
  openDialog<ITransaction | null>(
    (resolve) => {
      const [amount, setAmount] = useState<number>(0);
      const [comment, setComment] = useState<string>('');
      const [type, setType] = useState(ETransactionType.Other);
      const [loading, setLoading] = useState(false);
      const submit = useCallback(
        (e: FormEvent) => {
          e.preventDefault();
          setLoading(true);
          API.Billing.chargeUser(user.id, { amount: Number(amount), type, comment })
            .then((tr) => {
              toast.success(`Transaction created: #${tr.id}`);
              return resolve(tr);
            })
            .catch(toast.error)
            .finally(() => setLoading(false));
        },
        [setLoading, amount, type, comment, resolve]
      );
      return (
        <Loadable loading={loading}>
          <form onSubmit={submit}>
            <DialogTitle>New transaction</DialogTitle>
            <DialogContent>
              <Stack spacing={2} mt={1}>
                <UserView user={user} />
                <Stack direction="row" spacing={1}>
                  <TextField
                    required
                    autoFocus
                    label="Amount"
                    type="number"
                    placeholder="1.23"
                    fullWidth
                    value={amount || ''}
                    onChange={(e) => setAmount(Number(e.target.value))}
                  />
                  <TextField
                    required
                    label="Type"
                    select
                    value={type}
                    onChange={(e) => setType(e.target.value as ETransactionType)}
                    fullWidth
                  >
                    {Object.entries(ETransactionType).map(([name, type]) => (
                      <MenuItem key={type} value={type}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <TextField
                  required
                  label="Comment"
                  type="text"
                  placeholder="Manual charge details"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Box ml={2} className="text-muted">
                Balance after: <b>$ {(user.balance! + amount).toFixed(2)}</b>
              </Box>
              <Box flexGrow={1} />
              <Button
                type="submit"
                disabled={Math.abs(amount) < 0.01 || !comment || !type}
                color={amount >= 0 ? 'success' : 'error'}
                startIcon={amount >= 0 ? <AddCircle /> : <RemoveCircle />}
              >
                Create
              </Button>
              <Button type="button" onClick={() => resolve(null)}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Loadable>
      );
    },
    { fullWidth: true, maxWidth: 'sm' }
  );

export default openChargeDialog;
