import Layout from '../../components/layout';
import { observer, useLocalObservable } from 'mobx-react';
import { PagerState } from '../../modules/utils';
import { EFieldGroup, IAlbum, IGetAlbumsRequest, ITrack } from '../../modules/rest';
import Pager from '../../components/pager';
import React, { useCallback, useEffect } from 'react';
import { runInAction } from 'mobx';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import cache from '../../modules/cache';
import {
  Button,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@mui/material';
import Empty from '../../components/empty';
import Track from '../../components/track';
import { useNavigate } from 'react-router-dom';
import Editable from '../../components/editable';
import moment from 'moment';

const scope = [
  EFieldGroup.AlbumPerformer,
  EFieldGroup.AlbumLinks,
  EFieldGroup.AlbumBatch,
  EFieldGroup.BatchDistributor,
  EFieldGroup.AlbumStatus,
  EFieldGroup.AlbumUpc,
];

const UploadList = observer(() => {
  const state = useLocalObservable<PagerState<IAlbum, IGetAlbumsRequest>>(() => ({
    pager: cache.get('upload.pager'),
    request: cache.get('upload.request') ?? { limit: 20, preset: 'upload' },
    loading: true,
  }));

  const navigate = useNavigate();

  const fetch = useCallback(() => {
    runInAction(() => (state.loading = true));
    API.Albums.getAlbums(state.request, scope)
      .then((pager) =>
        runInAction(() => {
          state.pager = pager;
          state.request.limit = pager.limit;
          state.request.page = pager.page;
          cache.set('upload.pager', state.pager);
          cache.set('upload.request', state.request);
        })
      )
      .catch(toast.error)
      .finally(() => runInAction(() => (state.loading = false)));
  }, [state]);

  const process = useCallback((a: IAlbum) => navigate(`${a.id}/`), [navigate]);

  const setUpc = useCallback(
    (a: IAlbum, upc: string | null) => {
      const prev = a.upc;
      runInAction(() => {
        a.upc = upc;
        state.loading = true;
      });
      API.Albums.updateAlbum(a.id, { upc }, [EFieldGroup.AlbumUpc])
        .then((res) => {
          runInAction(() => (a.upc = res.upc));
        })
        .catch((e) => {
          toast.error(e.includes('Duplicate') ? 'UPC already used' : e);
          runInAction(() => (a.upc = prev));
        })
        .finally(() => runInAction(() => (state.loading = false)));
    },
    [state]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Layout
      title="Upload"
      loading={state.loading}
      footer={<Pager pager={state.pager} request={state.request} onChange={fetch} />}
      actions={
        <Tabs
          value={state.request.preset}
          onChange={(_, value) =>
            runInAction(() => {
              state.request.preset = value;
              state.request.page = 1;
              fetch();
            })
          }
        >
          <Tab label="Not uploaded" value="upload" />
          <Tab label="Not delivered" value="pending" />
        </Tabs>
      }
    >
      {state.pager && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Album/Single</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>UPC</TableCell>
                <TableCell>Batch</TableCell>
                <TableCell>Distributor</TableCell>
                <TableCell>Release date</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {state.pager.data.map((a) => (
                <TableRow key={a.id}>
                  <TableCell>
                    <Track track={{ title: a.title, album: a, performer: a.performer } as ITrack} />
                  </TableCell>
                  <TableCell>{a.isSingle ? 'Single' : 'Album'}</TableCell>
                  <TableCell>
                    <Editable
                      value={a.upc!}
                      onChange={(upc) => setUpc(a, upc)}
                      textFieldProps={{ placeholder: '123456789012' }}
                    />
                  </TableCell>
                  <TableCell>{a.batch?.tag}</TableCell>
                  <TableCell>{a.batch?.distributor}</TableCell>
                  <TableCell>{a.releasedAt ? moment(a.releasedAt).format('DD.MM.YYYY') : 'N/A'}</TableCell>
                  <TableCell align="right">
                    <Button onClick={() => process(a)}>Process</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Empty show={!state.loading && state.pager.count === 0} />
        </TableContainer>
      )}
    </Layout>
  );
});

export default UploadList;
