import { IAlbum, IAlbumLinks } from '../../modules/rest';
import { openDialog } from '../../components/dialogs';
import { Button, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { FormEvent, useCallback, useState } from 'react';
import Loadable from '../../components/loadable';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';

const albumLinksNames: Record<keyof IAlbumLinks, string> = {
  fanLink: 'Fan link',
  appleMusic: 'Apple Music',
  deezer: 'Deezer',
  soundCloud: 'SoundCloud',
  spotify: 'Spotify',
  tidal: 'TIDAL',
  yandexMusic: 'Yandex.Music',
  zvuk: 'Zvuk.com',
  amazon: 'Amazon',
  array: '',
};

const editAlbumLinksDialog = (album: IAlbum) =>
  openDialog<IAlbumLinks | null>((resolve) => {
    const [links, setLinks] = useState<IAlbumLinks>(album.links!);
    const [loading, setLoading] = useState(false);

    const submit = useCallback(
      (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        API.Albums.updateAlbumLinks(album.id, { links })
          .then(() => {
            toast.success('Links updated');
            resolve(links);
          })
          .catch(toast.error)
          .finally(() => setLoading(false));
      },
      [links, resolve]
    );

    return (
      <Loadable loading={loading}>
        <form onSubmit={submit}>
          <DialogTitle>
            {album.title}
            {album.isSingle ? ' (single)' : null}
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2} mt={1} sx={{ width: 500 }}>
              {Object.entries(links).map(([key, value]) =>
                key === 'array' ? null : (
                  <TextField
                    key={key}
                    fullWidth
                    label={albumLinksNames[key as keyof IAlbumLinks]}
                    placeholder="https://"
                    value={value || ''}
                    onChange={(e) => setLinks({ ...links, [key]: e.target.value || null })}
                  />
                )
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button type="submit">Save</Button>
            <Button onClick={() => resolve(null)}>Cancel</Button>
          </DialogActions>
        </form>
      </Loadable>
    );
  });

export { editAlbumLinksDialog, albumLinksNames };
