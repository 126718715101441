import { Alert } from '@mui/material';

const ErrorFallback = ({ error }: { error: Error; resetErrorBoundary(): void }) => {
  return (
    <Alert title="Error">
      <p>{error.message}</p>
      <details className="cursor-pointer mb-3">
        <pre>{error.stack}</pre>
      </details>
    </Alert>
  );
};

export default ErrorFallback;
