import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { API } from '../../modules/api';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { formatNumber } from '../../modules/utils';
import Layout from '../../components/layout';

const VideoEmbedsStats = () => {
  const [data, setData] = useState<
    { date: string; views: number; views_inc: number; users_count: number; videos_count: number }[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.AdminStats.getTracksViewsHistory().then((data) => {
      setData(data);
      setLoading(false);
    });
  }, [setData, setLoading]);

  if (loading) return <CircularProgress />;

  return (
    <Layout title="Статистика по просмотрам">
      <Stack direction="row" spacing={2}>
        <TableContainer component={Paper} style={{ height: 'calc(100vh - 100px)', width: 600, overflow: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell align="right">Просмотры</TableCell>
                <TableCell align="right">Накоп. итог</TableCell>
                <TableCell align="right">Видео</TableCell>
                <TableCell align="right">Аккаунты</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.reverse().map((row) => (
                <TableRow key={row.date}>
                  <TableCell>{moment(row.date).format('DD.MM.YYYY')}</TableCell>
                  <TableCell align="right">{formatNumber(row.views)}</TableCell>
                  <TableCell align="right">{formatNumber(row.views_inc)}</TableCell>
                  <TableCell align="right">{formatNumber(row.videos_count)}</TableCell>
                  <TableCell align="right">{formatNumber(row.users_count)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack spacing={2} flexGrow={1}>
          <Card>
            <CardHeader title="Просмотры по дням" />
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  width={600}
                  height={300}
                  data={data.reverse().map((d) => ({
                    ...d,
                    date: moment(d.date).format('DD.MM'),
                  }))}
                  margin={{ left: 40 }}
                >
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                  <Bar dataKey="views" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>

          <Card>
            <CardHeader title="Накопительный итог" />
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  width={600}
                  height={300}
                  data={data.map((d) => ({
                    ...d,
                    date: moment(d.date).format('DD.MM'),
                  }))}
                  margin={{ left: 40 }}
                >
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                  <Bar dataKey="views_inc" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default VideoEmbedsStats;
