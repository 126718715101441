import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useEffect } from 'react';

const LoginAs = () => {
  const params = useParams<{ token: string }>();

  useEffect(() => {
    localStorage.setItem('fallback_token', localStorage.getItem('token') ?? '');
    localStorage.setItem('token', params.token!);
    window.location.replace('/');
  }, [params.token]);

  return (
    <Box padding={2}>
      <Stack direction="row" spacing={1} alignItems="center">
        <CircularProgress size={20} />
        <span className="text-muted">Creating session...</span>
      </Stack>
    </Box>
  );
};

export default LoginAs;
