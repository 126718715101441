import { Navigate, Route, Routes } from 'react-router-dom';
import AccountsList from './list';
import AccountItem from './item';

const AccountsRoutes = () => (
  <Routes>
    <Route path="/" element={<AccountsList />} />
    <Route path="/:id/" element={<AccountItem />} />
    <Route path="*" element={<Navigate to="/accounts/" replace />} />
  </Routes>
);

export default AccountsRoutes;
