import { Slider, Stack } from '@mui/material';
import { formatTime } from '../modules/utils';

export type TRange = [number, number];

const SelectRange = ({
  value,
  duration,
  onChange,
  marks,
}: {
  value: TRange;
  duration: number;
  onChange(value: TRange): void;
  marks?: { value: number; label: string }[];
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        step={0.01}
        onChange={(_, value) => onChange(value as TRange)}
        valueLabelDisplay="auto"
        max={duration}
        marks={marks ?? false}
        valueLabelFormat={(value) => formatTime(value, false)}
      />
      <small className="text-muted">{formatTime(value[1] - value[0], false)}</small>
    </Stack>
  );
};

export default SelectRange;
