import Layout from '../../../components/layout';
import NavTabs from './tabs';
import React, { useCallback, useEffect, useState } from 'react';
import { EFieldGroup, IAlbum, ITrack } from '../../../modules/rest';
import { API } from '../../../modules/api';
import { toast } from 'react-toastify';
import { Box, Button, Card, CardContent, Chip, CircularProgress, IconButton, Stack, TextField } from '@mui/material';
import Empty from '../../../components/empty';
import Track from '../../../components/track';
import { Edit, Search } from '@mui/icons-material';
import session from '../../../modules/session';
import { openEditAlbumDialog } from '../../tracks/edit-album';
import { confirmDialog, openDialog } from '../../../components/dialogs';
import Loadable from '../../../components/loadable';
import { runInAction } from 'mobx';

const scope = [EFieldGroup.TrackAlbum, EFieldGroup.AlbumPerformer, EFieldGroup.AlbumFlags];

const Covers = () => {
  const [albums, setAlbums] = useState<IAlbum[]>();
  const [query, setQuery] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(() => {
    setLoading(true);
    API.Albums.getAlbums({ limit: 1000, query, preset: 'covers' }, scope)
      .then((res) => setAlbums(res.data))
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [setAlbums, setLoading, query]);

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Layout
      loading={loading}
      title="Albums"
      actions={
        <Stack direction="row" spacing={4}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              name="search"
              placeholder="Search by title, performer, UPC..."
              value={query || ''}
              onChange={(e) => setQuery(e.target.value || null)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') fetch();
              }}
              size="small"
              sx={{ flexGrow: 1 }}
            />
            <IconButton
              onClick={() =>
                runInAction(() => {
                  return fetch();
                })
              }
            >
              <Search />
            </IconButton>
          </Stack>
          <NavTabs value={4} />
        </Stack>
      }
    >
      {albums ? (
        <Stack spacing={2}>
          {albums.map((album) => (
            <AlbumItem key={album.id} album={album} fetch={fetch} />
          ))}
          <Empty show={!albums.length} />
        </Stack>
      ) : (
        <CircularProgress />
      )}
    </Layout>
  );
};

const AlbumItem = ({ album, fetch }: { album: IAlbum; fetch(): void }) => {
  const zoom = useCallback((album: IAlbum) => openDialog(() => <img alt="" src={album.cover?.url} />), []);
  const [loading, setLoading] = useState(false);
  const approve = useCallback(() => {
    confirmDialog('Are you sure to approve album cover?', { confirmText: 'Approve' }).then((res) => {
      if (!res) return;
      setLoading(true);

      API.Albums.updateAlbum(album.id, { flagReplaceCover: false })
        .catch(toast.error)
        .finally(() => {
          setLoading(false);
          fetch();
          session.fetch().then();
        });
    });
  }, [setLoading, fetch, album.id]);

  return (
    <Loadable loading={loading} minHeight={0}>
      <Card>
        <CardContent>
          <Stack direction="row" spacing={2} alignItems="center">
            <small className="text-muted">#{album.id}</small>
            <Track
              onClick={() => zoom(album)}
              track={{ id: album.id, title: album.title, album, performer: album.performer } as ITrack}
            />
            {album.isSingle ? (
              <Chip size="small" variant="outlined" label="Single" />
            ) : (
              <Chip size="small" variant="outlined" label={`${album.tracksCount} tracks`} />
            )}
            <Box flexGrow={1} />
            <IconButton
              onClick={() => openEditAlbumDialog(album, { hideTitle: true, hideFlagReplaceCover: true }).then(fetch)}
              size="small"
            >
              <Edit />
            </IconButton>
            <Button onClick={approve} disabled={!album.cover}>
              Approve
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Loadable>
  );
};

export default Covers;
