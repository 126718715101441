import { openDialog } from '../../components/dialogs';
import { EDistributor, EReportType, IAsset, IReport } from '../../modules/rest';
import Loadable from '../../components/loadable';
import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material';
import { FormEvent, useCallback, useState } from 'react';
import moment from 'moment';
import InputFile from '../../components/input-file';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';

type TResult = IReport | null;

const AddReport = ({ resolve }: { resolve(result: TResult): void }) => {
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(moment().year());
  const [type, setType] = useState<EReportType | null>(null);
  const [file, setFile] = useState<IAsset | null>(null);
  const [distributor, setDistributor] = useState<EDistributor>(EDistributor.Orchard);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Reports.addReport({ distributor, file: file!.id, type: type!, year })
        .then(resolve)
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [setLoading, distributor, file, type, year]
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit}>
        <DialogTitle>Add report</DialogTitle>
        <DialogContent>
          <Stack mt={1} spacing={2}>
            <TextField
              fullWidth
              required
              label="Distributor"
              select
              value={distributor}
              onChange={(e) => setDistributor(e.target.value as EDistributor)}
            >
              {Object.entries(EDistributor).map(([name, id]) => (
                <MenuItem value={id} key={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
            <Stack direction="row" spacing={1}>
              <TextField
                fullWidth
                required
                label="Type"
                select
                value={type}
                onChange={(e) => setType(e.target.value as EReportType)}
              >
                {Object.entries(EReportType).map(([name, id]) => (
                  <MenuItem value={id} key={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                label="Year"
                value={year}
                onChange={(e) => setYear(Number(e.target.value))}
                inputProps={{ type: 'number', min: 2023, step: 1 }}
                required
              />
            </Stack>
            <Stack alignItems="start">
              <small className="text-muted">Report file*</small>
              <InputFile
                value={file}
                onChange={setFile}
                accept=".csv, .xls, .xslx, .ods"
                size="medium"
                variant="outlined"
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" disabled={!file || !distributor || !year || !type}>
            Add report
          </Button>
          <Button onClick={() => resolve(null)}>Close</Button>
        </DialogActions>
      </form>
    </Loadable>
  );
};

export const addReport = () =>
  openDialog<TResult>((resolve) => <AddReport resolve={resolve} />, { fullWidth: true, maxWidth: 'sm' });
