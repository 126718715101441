import session from './modules/session';
import { Box, CircularProgress, Container, IconButton, Stack } from '@mui/material';
import { observer } from 'mobx-react';
import LoginForm from './login-form';
import { Logout } from '@mui/icons-material';
import AppRoutes from './routes';
import AppNav from './nav';
import { openEditProfileDialog } from './routes/accounts/profile';
import User from './components/user';
import React from 'react';

const App = observer(() => {
  if (!session.ready)
    return (
      <Container>
        <Box padding={4} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </Container>
    );
  if (!session.user) return <LoginForm />;

  return (
    <div className="app">
      <div className="app__left">
        <AppNav />
      </div>
      <div className="app__right">
        <div className="app__right__top-bar">
          <Stack direction="row" spacing={2}>
            <User user={session.user} onClick={() => openEditProfileDialog().then(session.fetch)} />
            <IconButton onClick={session.logout}>
              <Logout />
            </IconButton>
          </Stack>
        </div>
        <div className="app__right__content">
          <AppRoutes />
        </div>
      </div>
    </div>
  );
});

export default App;
