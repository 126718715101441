import { Checkbox as CheckboxMui, FormControlLabel } from '@mui/material';
import { FC } from 'react';

type Props = {
  label: string;
  checked: boolean;
  onChange(checked: boolean): any;
};

const Checkbox: FC<Props> = ({ checked, onChange, label }) => (
  <FormControlLabel
    checked={checked}
    onChange={(e, checked) => onChange(checked)}
    control={<CheckboxMui />}
    label={label}
  />
);

export default Checkbox;
