import { ETaskStatus, IAbstractTask } from '../../modules/rest';
import { openDialog } from '../dialogs';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import { Check, LockClock, Warning } from '@mui/icons-material';

const waitForTask = <T,>(task: T, title?: string): Promise<T | null> =>
  openDialog<T>(
    (resolve) => {
      const [status, setStatus] = useState<IAbstractTask>(task as IAbstractTask);
      useEffect(() => {
        const interval = setInterval(() => {
          if ([ETaskStatus.Finished, ETaskStatus.Failed].includes(status.status)) return;
          API.Tasks.getTask<IAbstractTask>(status.id)
            .then((res) => {
              setStatus(res);
              if (res.status === ETaskStatus.Finished) {
                clearInterval(interval);
                setTimeout(() => resolve(res as T), 500);
              }
            })
            .catch(toast.error);
        }, 1000);
        return () => {
          clearInterval(interval);
        };
      });
      return (
        <div>
          {title && <DialogTitle>{title}</DialogTitle>}
          <DialogContent>
            <Stack direction="row" mb={2} spacing={1} alignItems="center">
              {status.status === ETaskStatus.Queued && <LockClock color="warning" />}
              {status.status === ETaskStatus.Processing && <CircularProgress size={20} />}
              {status.status === ETaskStatus.Finished && <Check color="success" />}
              {status.status === ETaskStatus.Failed && <Warning color="error" />}
              <b style={{ textTransform: 'capitalize' }}>{status.status}</b>
            </Stack>
            {status.title && (
              <div style={{ marginBottom: 10 }} className={status.status === ETaskStatus.Failed ? 'color-error' : ''}>
                {status.title}
              </div>
            )}
            {status.description && (
              <div
                style={{ marginBottom: 10, fontSize: 12 }}
                className={status.status === ETaskStatus.Failed ? 'color-error' : 'text-muted'}
              >
                {status.description}
              </div>
            )}
            {status.status !== ETaskStatus.Failed && (
              <LinearProgress
                variant={status.progress === null ? 'indeterminate' : 'determinate'}
                value={status.progress ?? undefined}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button disabled={status.status !== ETaskStatus.Failed} onClick={() => resolve(null as T)}>
              Close
            </Button>
          </DialogActions>
        </div>
      );
    },
    { fullWidth: true, maxWidth: 'sm' }
  );

export { waitForTask };
