import Layout from '../../components/layout';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { EContentIdStatus, EFieldGroup, IAlbum, ITrack } from '../../modules/rest';
import { API } from '../../modules/api';
import { toast } from 'react-toastify';
import {
  Avatar,
  Button,
  IconButton,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Edit, LockOpen, MusicNote, TextFields, Warning } from '@mui/icons-material';
import Editable from '../../components/editable';
import { copyToClipboard, CopyToClipboardText } from '../../components/copy-to-clipboard';
import { download, downloadAlbumCover, downloadTrackWav, thumb } from '../../modules/utils';
import countries from '../../assets/json/countries.en.json';
import langs from '../../assets/json/langs.en.json';
import { openEditTrackDialog } from '../tracks/edit-track';
import { openEditPerformerProfileDialog } from '../accounts/performers/edit-profile';
import { editAlbumLinksDialog } from '../tracks/album-links';
import { openEditAlbumDialog } from '../tracks/edit-album';
import moment from 'moment';

const UploadProcess = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [album, setAlbum] = useState<IAlbum>();

  const fetch = useCallback(() => {
    setLoading(true);
    API.Albums.getAlbum(params.id!, [
      EFieldGroup.AlbumTracks,
      EFieldGroup.AlbumPerformer,
      EFieldGroup.PerformerEdit,
      EFieldGroup.TrackStatus,
      EFieldGroup.TrackIsrc,
      EFieldGroup.TrackEdit,
      EFieldGroup.TrackComment,
      EFieldGroup.AlbumUpc,
      EFieldGroup.AlbumStatus,
      EFieldGroup.AlbumBatch,
      EFieldGroup.BatchCompany,
      EFieldGroup.AlbumLinks,
      EFieldGroup.AlbumPerformer,
    ])
      .then(setAlbum)
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [params.id, setAlbum, setLoading]);

  const lock = useCallback(
    () =>
      API.Albums.lockAlbum(params.id!).catch((e) => {
        toast.error(e);
        navigate('/upload/');
      }),
    [navigate, params]
  );

  const unlock = useCallback(() => {
    setLoading(true);
    API.Albums.unlockAlbum(album!.id)
      .catch(toast.error)
      .finally(() => navigate('/upload/'));
  }, [album, navigate]);

  const setIsrc = useCallback(
    (track: ITrack, isrc: string | null) => {
      setLoading(true);
      API.Tracks.updateTrack(track.id, { isrc })
        .then(fetch)
        .catch((e) => {
          toast.error(e.includes('Duplicate') ? 'ISRC already used' : e);
        })
        .finally(() => setLoading(false));
    },
    [fetch, setLoading]
  );

  const setUpc = useCallback(
    (upc: string | null) => {
      API.Albums.updateAlbum(album!.id, { upc })
        .then(fetch)
        .catch((e) => {
          toast.error(e.includes('Duplicate') ? 'UPC already used' : e);
        })
        .finally(() => setLoading(false));
    },
    [album, fetch]
  );

  const setStatus = useCallback(
    (track: ITrack, status: EContentIdStatus) => {
      setLoading(true);
      API.Tracks.updateTrackMonetization(track.id, { audioStatus: status })
        .then(fetch)
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [fetch, setLoading]
  );

  const setComment = useCallback(
    (track: ITrack, comment: string | null) => {
      setLoading(true);
      API.Tracks.updateTrack(track.id, { comment })
        .then(fetch)
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [fetch]
  );

  useEffect(() => {
    lock().then(fetch);
    const interval = setInterval(lock, 60000);
    return () => clearInterval(interval);
  }, [lock, fetch, params.id]);

  return (
    <Layout
      title="Processing"
      loading={loading}
      actions={
        <Button disabled={!album} startIcon={<LockOpen />} onClick={unlock}>
          Unlock album
        </Button>
      }
    >
      {album ? (
        <>
          <Stack spacing={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <h3>Performer</h3>
              <Button
                size="small"
                onClick={() => openEditPerformerProfileDialog(album!.performer!).then(fetch)}
                startIcon={<Edit />}
              >
                Edit
              </Button>
            </Stack>
            <Paper>
              <Stack padding={2} spacing={2}>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                  <div>
                    <small className="text-muted">Stage name</small>
                    <br />
                    <CopyToClipboardText>{album?.performer?.stageName}</CopyToClipboardText>
                  </div>
                  <div>
                    <small className="text-muted">Name</small>
                    <br />
                    <CopyToClipboardText>
                      {`${album?.performer?.firstName} ${album?.performer?.middleName} ${album?.performer?.lastName}`.trim()}
                    </CopyToClipboardText>
                  </div>
                  <div>
                    <small className="text-muted">Country</small>
                    <br />
                    <CopyToClipboardText>
                      {
                        // @ts-ignore
                        `${countries[album?.performer?.nationality]} (${album?.performer?.nationality?.toUpperCase()})`
                      }
                    </CopyToClipboardText>
                  </div>
                </Stack>
                {album.performer?.photos && (
                  <div>
                    <small className="text-muted">Photos</small>
                    <Stack direction="row" spacing={1}>
                      {album.performer?.photos?.map((photo) => (
                        <Avatar
                          key={photo.id}
                          src={thumb(photo.id, 100)}
                          variant="rounded"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => download(photo)}
                          title="Download"
                        />
                      ))}
                    </Stack>
                  </div>
                )}
                <div>
                  <small className="text-muted">Bio</small>
                  <br />
                  <CopyToClipboardText>{album?.performer?.bio}</CopyToClipboardText>
                </div>
              </Stack>
            </Paper>

            <Stack direction="row" spacing={1} alignItems="center">
              <h3>{album.isSingle ? 'Single' : 'Album'}</h3>
              {album.isSingle ? (
                <Button
                  size="small"
                  onClick={() => openEditTrackDialog(album!.tracks![0]).then(fetch)}
                  startIcon={<Edit />}
                >
                  Edit
                </Button>
              ) : (
                <Button size="small" onClick={() => openEditAlbumDialog(album!).then(fetch)} startIcon={<Edit />}>
                  Edit
                </Button>
              )}
              <Button size="small" onClick={() => editAlbumLinksDialog(album).then(fetch)} startIcon={<Edit />}>
                Links ({album.links?.array.length})
              </Button>
              {album.links?.array.length === 0 && <Warning color="error" />}
            </Stack>

            <Paper>
              <Stack padding={2} spacing={2}>
                <Stack spacing={2} direction={'row'} justifyContent="space-between" alignItems="start">
                  <Stack>
                    <small className="text-muted">Title</small>
                    <CopyToClipboardText>{album.title}</CopyToClipboardText>
                  </Stack>
                  <Stack>
                    <small className="text-muted">Label</small>
                    <CopyToClipboardText>{album.batch?.company?.tradingAs}</CopyToClipboardText>
                  </Stack>
                  <Stack>
                    <small className="text-muted">Release date</small>
                    {album.releasedAt ? moment(album.releasedAt).format('DD.MM.YYYY') : 'N/A'}
                  </Stack>
                  <Stack>
                    <Stack alignItems="center" spacing={1} direction="row">
                      <small className="text-muted">UPC</small>
                      {!album.upc && <Warning color="error" />}
                    </Stack>
                    <Editable value={album.upc!} onChange={setUpc} textFieldProps={{ placeholder: '123456789012' }} />
                  </Stack>
                </Stack>

                {album.cover && (
                  <Stack>
                    <small className="text-muted">Cover</small>
                    <Avatar
                      src={thumb(album.cover.id, 200)}
                      variant="rounded"
                      sx={{ cursor: 'pointer', width: 100, height: 100 }}
                      onClick={() => downloadAlbumCover(album)}
                      title="Download"
                    />
                  </Stack>
                )}
              </Stack>
            </Paper>
            <h3>{album.isSingle ? 'Track' : 'Tracks'}</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Genre</TableCell>
                    <TableCell>ISRC</TableCell>
                    <TableCell>Language</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Comment</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {album.tracks?.map((track) => (
                    <TableRow key={track.id}>
                      <TableCell>
                        <CopyToClipboardText>{track.title}</CopyToClipboardText>
                      </TableCell>
                      <TableCell>
                        <div>{track.mainGenre?.name}</div>
                        <small className="text-muted">{track.subGenre?.name}</small>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Editable value={track.isrc || null} onChange={(isrc) => setIsrc(track, isrc)} />
                          {!track.isrc && <Warning color="error" />}
                        </Stack>
                      </TableCell>
                      <TableCell>
                        {
                          // @ts-ignore
                          langs[track.language] ?? <small className="text-muted">N/A</small>
                        }
                      </TableCell>
                      <TableCell>
                        <TextField
                          select
                          value={track.audioStatus}
                          size="small"
                          onChange={(e) => setStatus(track, e.target.value as EContentIdStatus)}
                        >
                          {Object.entries(EContentIdStatus).map(([name, id]) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </TableCell>
                      <TableCell>
                        <Editable value={track.comment || null} onChange={(comment) => setComment(track, comment)} />
                      </TableCell>
                      <TableCell align="right">
                        <IconButton size="small" title="Download .WAV" onClick={() => downloadTrackWav(track)}>
                          <MusicNote />
                        </IconButton>
                        <IconButton
                          size="small"
                          title="Copy lyrics"
                          disabled={!track.lyrics}
                          onClick={() => copyToClipboard(track.lyrics!).then(() => toast.success('Copied!'))}
                        >
                          <TextFields />
                        </IconButton>
                        <IconButton
                          size="small"
                          title="Edit track"
                          onClick={() => openEditTrackDialog(track).then(fetch)}
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </>
      ) : (
        <>
          <Stack spacing={2}>
            <h3>Performer</h3>
            <Skeleton variant="rounded" height={200} />
            <h3>Album</h3>
            <Skeleton variant="rounded" height={200} />
            <h3>Tracks</h3>
            <Skeleton variant="rounded" height={200} />
          </Stack>
        </>
      )}
    </Layout>
  );
};

export default UploadProcess;
