import { Navigate, Route, Routes } from 'react-router-dom';
import TracksList from './list';
import TracksItem from './item';

const TracksRoutes = () => (
  <Routes>
    <Route path="/" element={<TracksList />} />
    {/*<Route path="/requests/:id/" element={<TrackRequest />} />*/}
    <Route path="/:id/" element={<TracksItem />} />
    <Route path="*" element={<Navigate to="/tracks/" replace />} />
  </Routes>
);

export default TracksRoutes;
