import { Box, MenuItem, Pagination, Stack, TextField, Typography } from '@mui/material';
import { IPagedData } from '../modules/rest';
import { runInAction } from 'mobx';
import React from 'react';
import { observer } from 'mobx-react';

const Pager = observer(
  ({
    pager,
    request,
    onChange,
    scrollSelector = '.layout__body',
    limits = [10, 25, 50, 100, 500, 1000],
  }: {
    scrollSelector?: string;
    pager?: IPagedData<any>;
    request: { page?: number; limit?: number };
    onChange(): void;
    limits?: number[];
  }) => {
    if (!pager) return null;
    return (
      <Stack direction="row" alignItems="center">
        <Pagination
          page={pager.page!}
          count={pager.pages!}
          onChange={(event, page) =>
            runInAction(() => {
              request.page = page;
              onChange();
              setTimeout(() => {
                document.querySelector(scrollSelector)?.scroll({ behavior: 'smooth', top: 0 });
              }, 300);
            })
          }
        />
        <Box flexGrow={1} />
        <Box mx={1} mr={3}>
          <Typography variant="body2">Records: {pager.count}</Typography>
        </Box>
        <Box mx={1}>
          <Typography variant="body2">Rows per page:</Typography>
        </Box>
        <TextField
          select
          size="small"
          value={pager.limit}
          onChange={(e) =>
            runInAction(() => {
              runInAction(() => {
                request.page = 1;
                request.limit = Number(e.target.value);
                onChange();
              });
            })
          }
        >
          {!limits.includes(pager.limit) && <MenuItem value={pager.limit}>{pager.limit}</MenuItem>}
          {limits.map((limit) => (
            <MenuItem key={limit} value={limit}>
              {limit}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    );
  }
);

export default Pager;
