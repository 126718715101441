import { FormEvent, ReactNode, useCallback, useState } from 'react';
import ReactDOM from 'react-dom/client';
import {
  Breakpoint,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';

type Options = {
  fullWidth?: boolean;
  maxWidth?: Breakpoint | false;
  disableEnforceFocus?: boolean;
};
type Props<T> = {
  builder: (resolve: (result: T) => void) => ReactNode;
  close(result: T | null): void;
  options?: Options;
};

const Container = <T,>({ builder, close, options }: Props<T>) => {
  const [open, setOpen] = useState(true);

  const startClose = useCallback(
    (result: T | null) => {
      setOpen(false);
      close(result);
    },
    [setOpen, close]
  );

  return (
    <Dialog
      disableEnforceFocus={options?.disableEnforceFocus}
      open={open}
      onClose={() => startClose(null)}
      maxWidth={options?.maxWidth}
      fullWidth={options?.fullWidth}
    >
      <>{builder(startClose)}</>
    </Dialog>
  );
};

function openDialog<T>(builder: (resolve: (result: T) => void) => ReactNode, options?: Options): Promise<T | null> {
  return new Promise((resolve) => {
    const el = document.createElement('div');
    document.body.appendChild(el);
    const root = ReactDOM.createRoot(el);
    const close = (result: T | null) => {
      resolve(result as T);
      document.body.removeChild(el);
    };
    root.render(<Container close={close} builder={builder} options={options} />);
  });
}

const confirmDialog = (
  content?: string,
  options?: { title?: string; confirmText?: string; confirmColor?: ButtonProps['color'] }
) =>
  openDialog<boolean>(
    (resolve) => (
      <>
        <DialogTitle>{options?.title ?? 'Confirmation'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content ?? 'Are you sure?'}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => resolve(true)} color={options?.confirmColor ?? 'primary'}>
            {options?.confirmText ?? 'Confirm'}
          </Button>
          <Button onClick={() => resolve(false)}>Cancel</Button>
        </DialogActions>
      </>
    ),
    { fullWidth: true, maxWidth: 'sm' }
  );

const rejectDialog = (content: string, options?: { title?: string; rejectText?: string; labelText?: string }) =>
  openDialog<string | false>(
    (resolve) => {
      const [reason, setReason] = useState('');

      const submit = useCallback(
        (e: FormEvent) => {
          e.preventDefault();
          resolve(reason);
        },
        [reason, resolve]
      );

      return (
        <form onSubmit={submit}>
          <DialogTitle>{options?.title ?? 'Rejection'}</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: 2 }}>{content}</DialogContentText>
            <TextField
              required
              autoFocus
              label={options?.labelText ?? 'Rejection reason'}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="error">
              {options?.rejectText ?? 'Reject'}
            </Button>
            <Button type="button" onClick={() => resolve(false)}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      );
    },
    { fullWidth: true, maxWidth: 'sm' }
  );

export { openDialog, confirmDialog, rejectDialog };
