import { IAlbum, IUpdateAlbumRequest } from '../../modules/rest';
import { openDialog } from '../../components/dialogs';
import { API } from '../../modules/api';
import Loadable from '../../components/loadable';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import React, { FormEvent, useCallback } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { runInAction } from 'mobx';
import { toast } from 'react-toastify';
import InputFile from '../../components/input-file';
import { thumb } from '../../modules/utils';
import Checkbox from '../../components/checkbox';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

type State = {
  loading: boolean;
  request: IUpdateAlbumRequest;
};

const EditAlbumDialog = observer(
  ({
    album,
    options,
    resolve,
  }: {
    album: IAlbum;
    options?: TEditAlbumOptions;
    resolve(album: IAlbum | null): void;
  }) => {
    const state = useLocalObservable<State>(() => ({
      loading: false,
      request: {
        title: album.title,
        coverId: album.cover?.id,
        flagReplaceCover: album.flagReplaceCover,
        releasedAt: album.releasedAt,
      },
    }));

    const submit = useCallback(
      (e: FormEvent) => {
        e.preventDefault();
        runInAction(() => (state.loading = true));
        API.Albums.updateAlbum(album.id, state.request)
          .then((res) => {
            toast.success('Album updated');
            resolve(res);
          })
          .catch(toast.error)
          .finally(() => runInAction(() => (state.loading = false)));
      },
      [state, resolve, album]
    );

    return (
      <form onSubmit={submit}>
        <Loadable loading={state.loading}>
          <DialogTitle>
            {album.title}
            {album.isSingle && ' (Single)'}
          </DialogTitle>
          <DialogContent>
            <Stack mt={1} spacing={2} direction="row">
              <Box
                sx={{
                  width: 150,
                  height: 150,
                  backgroundColor: 'silver',
                }}
              >
                {state.request.coverId && (
                  <img src={thumb(state.request.coverId, 300)} alt={state.request.title} height={150} width={150} />
                )}
              </Box>

              <Stack spacing={2}>
                {!options?.hideTitle && (
                  <TextField
                    required
                    label="Title"
                    value={state.request.title}
                    onChange={(e) => runInAction(() => (state.request.title = e.target.value))}
                    sx={{ width: 350 }}
                  />
                )}

                <Stack spacing={1} alignItems="start">
                  <small className="text-muted">Cover (JPEG 3000x3000)*</small>
                  <InputFile
                    accept="image/jpeg"
                    value={state.request.coverId || null}
                    onChange={(cover) => runInAction(() => (state.request.coverId = cover?.id))}
                  />
                </Stack>

                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD.MM.YYYY"
                    label="Release date"
                    value={state.request.releasedAt ? moment(state.request.releasedAt) : null}
                    onChange={(value) =>
                      runInAction(() => (state.request.releasedAt = value?.format('YYYY-MM-DD') || null))
                    }
                  />
                </LocalizationProvider>

                {!options?.hideFlagReplaceCover && (
                  <Checkbox
                    label="Change cover"
                    checked={album.flagReplaceCover!}
                    onChange={(checked) => runInAction(() => (state.request.flagReplaceCover = checked))}
                  />
                )}
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button type="submit">Update album</Button>
            <Button onClick={() => resolve(null)}>Cancel</Button>
          </DialogActions>
        </Loadable>
      </form>
    );
  }
);

type TEditAlbumOptions = {
  hideTitle?: boolean;
  hideFlagReplaceCover?: boolean;
};

const openEditAlbumDialog = (album: IAlbum, options?: TEditAlbumOptions) =>
  API.Albums.getAlbum(album.id).then((album) =>
    openDialog<IAlbum | null>((resolve) => <EditAlbumDialog album={album} resolve={resolve} options={options} />, {
      fullWidth: true,
      maxWidth: 'sm',
    })
  );

export { openEditAlbumDialog };
