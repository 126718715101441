import React, { FC } from 'react';
import './loadable.scss';
import { CircularProgress } from '@mui/material';

interface Props {
  loading: boolean;
  className?: string;
  children: any;
  minHeight?: number;
}

const Loadable: FC<Props> = ({ loading, className, children, minHeight = 100 }) => {
  return (
    <div className={`loadable ${loading ? 'loading' : ''} ${className || ''}`} style={{ minHeight }}>
      {children}
      {loading && (
        <div className="spinner">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default Loadable;
